import { multiplication25 } from './grade3/multiplication25';
import { multiplication69 } from './grade3/multiplication69';
import { doublesHalves } from './grade3/doublesHalves';
import { fractions } from './grade3/fractions';
import { dataMeasurements } from './grade3/dataMeasurements';

const grade3Content = {
    ...doublesHalves,
    ...fractions,
    ...multiplication25,
    ...multiplication69,
    ...dataMeasurements
};

export default grade3Content;
