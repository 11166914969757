import { useState, useEffect } from 'react';
import { Typography, TextField, Grid } from '@mui/material';
import '../../../styles/practice.css';

export default function CountingPractice({ level, onAnswer, currentProgress, inputs, resetKey }) {
  const [currentBox, setCurrentBox] = useState(0);
  const [answers, setAnswers] = useState(Array(9).fill(''));
  
  const startNumber = inputs.start;
  const countBy = inputs.increment;
  const isCountingBackwards = countBy < 0;

  // Focus first box on mount
  useEffect(() => {
    const firstBox = document.querySelector('input[name="answer-0"]');
    if (firstBox) {
      firstBox.focus();
    }
  }, []);

  // Reset answers when resetKey changes
  useEffect(() => {
    if (resetKey) {
      setAnswers(Array(9).fill(''));
      setCurrentBox(0);
      setTimeout(() => {
        const firstBox = document.querySelector('input[name="answer-0"]');
        if (firstBox) {
          firstBox.focus();
        }
      }, 0);
    }
  }, [resetKey]);

  const validateAnswer = (index, value) => {
    if (value === '') return false;
    const expectedNumber = startNumber + (countBy * (index + 1));
    const parsedValue = parseToDecimal(value);
    if (parsedValue === null) return false;
    
    // Use small epsilon for floating point comparison
    return Math.abs(parsedValue - expectedNumber) < 0.0001;
  };

  // Convert mixed number or fraction to decimal for comparison only
  const parseToDecimal = (value) => {
    // Try as regular decimal first
    if (!isNaN(value)) {
      return Number(value);
    }

    // Handle mixed numbers (e.g. "1 1/2")
    if (value.includes(' ')) {
      const [whole, fraction] = value.split(' ');
      if (!fraction.includes('/')) return null;
      
      const wholeNum = Number(whole);
      if (isNaN(wholeNum)) return null;
      
      const [numerator, denominator] = fraction.split('/').map(Number);
      if (!denominator || isNaN(numerator) || isNaN(denominator)) return null;
      
      return wholeNum + (numerator / denominator);
    }

    // Handle simple fractions (e.g. "1/2")
    if (value.includes('/')) {
      const [numerator, denominator] = value.split('/').map(Number);
      if (!denominator || isNaN(numerator) || isNaN(denominator)) return null;
      return numerator / denominator;
    }

    return null;
  };

  // Validate numeric input including decimals, fractions, and mixed numbers
  const isValidInput = (value) => {
    if (value === '' || value === '-') return true;
    
    // Check decimal format
    if (/^-?\d*\.?\d*$/.test(value)) return true;
    
    // Check fraction format
    if (/^-?\d*\/?\d*$/.test(value)) return true;
    
    // Check mixed number format (e.g. "1 1/2")
    if (/^-?\d+\s+\d*\/?\d*$/.test(value)) return true;
    
    return false;
  };

  const handleInputChange = (index, value) => {
    if (!isValidInput(value)) return;
    if (index !== currentBox) return;

    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const value = answers[index];
      if (!value) return;

      // If this was the last box and all previous boxes are correct
      if (index === 8) {
        const allCorrect = answers.every((answer, idx) => validateAnswer(idx, answer));
        if (allCorrect) {
          onAnswer('correct', 'correct');
        } else {
          const expectedNumber = startNumber + (countBy * (index + 1));
          onAnswer('incorrect', expectedNumber);
        }
        return;
      }

      // Check if current answer is correct
      const isCorrect = validateAnswer(index, value);
      if (isCorrect) {
        onAnswer('correct', startNumber + (countBy * (index + 1)));
        setCurrentBox(index + 1);
        setTimeout(() => {
          const nextInput = document.querySelector(`input[name="answer-${index + 1}"]`);
          if (nextInput) {
            nextInput.focus();
          }
        }, 0);
      } else {
        const expectedNumber = startNumber + (countBy * (index + 1));
        onAnswer('incorrect', expectedNumber);
      }
    }
  };

  const inputProps = {
    style: {
      color: 'white',
      WebkitTextFillColor: 'white',
      opacity: 1
    }
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        {isCountingBackwards 
          ? `Count backwards by ${Math.abs(countBy)} starting at ${startNumber}`
          : `Count by ${countBy} starting at ${startNumber}`}
      </Typography>
      <br/>

      <Grid container className="grid-container">
        <Grid item>
          <TextField
            disabled
            value={startNumber}
            variant="outlined"
            size="small"
            className="number-input"
            autoComplete="off"
            inputProps={inputProps}
          />
        </Grid>
        {answers.slice(0, 4).map((answer, index) => (
          <Grid item key={index}>
            <TextField
              name={`answer-${index}`}
              value={answer}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={index !== currentBox}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container className="grid-container">
        {answers.slice(4, 9).map((answer, index) => (
          <Grid item key={index + 4}>
            <TextField
              name={`answer-${index + 4}`}
              value={answer}
              onChange={(e) => handleInputChange(index + 4, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index + 4)}
              disabled={index + 4 !== currentBox}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
