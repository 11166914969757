import { generateLcmPair } from '../../../../components/math/practices/GcfLcmPractice';

export const multiples = {
  "Multiples": {
    levels: [
      { id: "xj309m4n", description: "Count Up by 3 from 30",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 33, increment: 3 } },
      { id: "xd609m4n", description: "Count Down by 3 from 60",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 60, increment: -3 } },
      { id: "xj609m4n", description: "Count Up by 3 from 60",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 63, increment: 3 } },
      { id: "xd909m4n", description: "Count Down by 3 from 90",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 90, increment: -3 } },
      { id: "xj119m4n", description: "Count Up by 11 from 11",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 11, increment: 11 } },
      { id: "xj129m4n", description: "Count Up by 12 from 12",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 12, increment: 12 } },
      { id: "xj159m4n", description: "Count Up by 15 from 15",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 15, increment: 15 } },
      { id: "xj139m4n", description: "Count Up by 13 from 13",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 13, increment: 13 } },
      { id: "xj149m4n", description: "Count Up by 14 from 14",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 14, increment: 14 } },
      { id: "xj169m4n", description: "Count Up by 16 from 16",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 16, increment: 16 } },
      { id: "xj199m4n", description: "Count Up by 19 from 19",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 19, increment: 19 } },
      { id: "xj189m4n", description: "Count Up by 18 from 18",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 18, increment: 18 } },
      { id: "xj179m4n", description: "Count Up by 17 from 17",  exerciseType: "CountingPractice", timeLimit: 90, inputs: { start: 17, increment: 17 } },
      {
        id: "lcm_20",
        description: "LCM up to 20",
        exerciseType: "GcfLcmPractice",
        timeLimit: 120,
        inputs: () => ({
          mode: 'lcm',
          numbers: generateLcmPair(4, 20)
        })
      },
      {
        id: "lcm_50",
        description: "LCM up to 50",
        exerciseType: "GcfLcmPractice",
        timeLimit: 180,
        inputs: () => ({
          mode: 'lcm',
          numbers: generateLcmPair(6, 50)
        })
      }
    ]
  }
};
