import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField } from '@mui/material';

const FractionOperationsPractice = React.memo(({ level, currentProgress, inputs: inputsFn, onAnswer, onKeyDown }) => {
  const [numeratorValue, setNumeratorValue] = useState('');
  const [denominatorValue, setDenominatorValue] = useState('');
  const [problem, setProblem] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetInputs();
    }
  }, [level, currentProgress, inputsFn]);

  const resetInputs = () => {
    setNumeratorValue('');
    setDenominatorValue('');
    inputRef.current?.focus();
  };

  const handleNumberChange = (e, setter) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setter(value);
  };

  const validateAnswer = () => {
    const numerator = parseInt(numeratorValue || '0', 10);
    const denominator = parseInt(denominatorValue || '0', 10);
    return numerator === problem.answer.numerator && 
           denominator === problem.answer.denominator;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Always submit an answer, treating empty inputs as 0
    const numerator = parseInt(numeratorValue || '0', 10);
    const denominator = parseInt(denominatorValue || '0', 10);
    const isCorrect = validateAnswer();
    onAnswer(isCorrect ? 'correct' : `${numerator}/${denominator}`, 
            `${problem.answer.numerator}/${problem.answer.denominator}`);

    // Generate new problem regardless of whether answer was correct
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetInputs();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
    onKeyDown?.(e);
  };

  if (!problem) return null;

  return (
    <div className="content-box">
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        padding: '2rem'
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '7rem', // Height of a typical fraction display
          fontSize: '2rem',
          color: 'var(--text-primary)'
        }}>
          {problem.fraction1.denominator === 1 ? (
            <span>{problem.fraction1.numerator}</span>
          ) : (
            <>
              <span>{problem.fraction1.numerator}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--text-primary)',
                margin: '0.25rem 0'
              }} />
              <span>{problem.fraction1.denominator}</span>
            </>
          )}
        </Box>
        <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>
          {problem.operation}
        </Typography>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '7rem', // Height of a typical fraction display
          fontSize: '2rem',
          color: 'var(--text-primary)'
        }}>
          {problem.fraction2.numerator === '?' ? (
            <form onSubmit={handleSubmit} style={{ 
              display: 'flex', 
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <TextField
                className="number-input"
                value={numeratorValue}
                onChange={(e) => handleNumberChange(e, setNumeratorValue)}
                onKeyDown={handleKeyDown}
                inputRef={inputRef}
                placeholder=""
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
              {problem.fraction2.denominator !== 1 && (
                <>
                  <Box sx={{ 
                    width: '2rem',
                    height: '2px',
                    backgroundColor: 'var(--text-primary)',
                    margin: '0.25rem 0'
                  }} />
                  <TextField
                    className="number-input"
                    value={denominatorValue}
                    onChange={(e) => handleNumberChange(e, setDenominatorValue)}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    inputProps={{
                      style: { textAlign: 'center' }
                    }}
                  />
                </>
              )}
            </form>
          ) : (
            problem.fraction2.denominator === 1 ? (
              <span>{problem.fraction2.numerator}</span>
            ) : (
              <>
                <span>{problem.fraction2.numerator}</span>
                <Box sx={{ 
                  width: '2rem',
                  height: '2px',
                  backgroundColor: 'var(--text-primary)',
                  margin: '0.25rem 0'
                }} />
                <span>{problem.fraction2.denominator}</span>
              </>
            )
          )}
        </Box>
        <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>
          {problem.fraction2.numerator === '?' ? '= 1' : '='}
        </Typography>
        {problem.fraction2.numerator !== '?' && (
          <form onSubmit={handleSubmit} style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.5rem'
          }}>
            <TextField
              className="number-input"
              value={numeratorValue}
              onChange={(e) => handleNumberChange(e, setNumeratorValue)}
              onKeyDown={handleKeyDown}
              inputRef={inputRef}
              placeholder=""
              inputProps={{
                style: { textAlign: 'center' }
              }}
            />
            <Box sx={{ 
              width: '100%',
              height: '2px',
              backgroundColor: 'var(--text-primary)'
            }} />
            <TextField
              className="number-input"
              value={denominatorValue}
              onChange={(e) => handleNumberChange(e, setDenominatorValue)}
              onKeyDown={handleKeyDown}
              placeholder=""
              inputProps={{
                style: { textAlign: 'center' }
              }}
            />
          </form>
        )}
      </Box>
    </div>
  );
});

export default FractionOperationsPractice;
