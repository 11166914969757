import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { exerciseComponents, customUIComponents, functionInputComponents } from '../math/config/practiceComponents';
import { areProblemsEqual, generateNewProblem } from '../math/utils/practiceUtils';
import PracticeHeader from '../math/components/PracticeHeader';
import PracticeExercise from '../math/components/PracticeExercise';
import gradeContent from './gradeContent';
import '../../styles/practice.css';

const Quiz = () => {
  const { grade, topic } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [quizComplete, setQuizComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [currentProblem, setCurrentProblem] = useState(null);
  const lastProblemRef = useRef(null);

  useEffect(() => {
    const generateQuestions = () => {
      const content = gradeContent[grade];
      // Find topic by matching simplified names
      const topicKey = Object.keys(content).find(key => 
        key.toLowerCase().replace(/[^a-z0-9]/g, '') === topic.toLowerCase()
      );
      
      if (!content || !topicKey) {
        console.error('No content found for grade/topic:', grade, topic);
        return;
      }

      // Get all levels from this section
      const levels = content[topicKey].levels;
      setQuestions(levels);
      setLoading(false);
    };

    generateQuestions();
  }, [grade, topic]);

  // Generate new problem when question changes
  useEffect(() => {
    if (!questions.length || currentQuestionIndex >= questions.length) return;

    const currentQuestion = questions[currentQuestionIndex];
    if (currentQuestion && !customUIComponents.includes(currentQuestion.exerciseType)) {
      const ExerciseComponent = exerciseComponents[currentQuestion.exerciseType];
      if (ExerciseComponent) {
        let newProblem;
        let attempts = 0;
        const maxAttempts = 10;

        do {
          newProblem = generateNewProblem(ExerciseComponent, currentQuestion, functionInputComponents);
          attempts++;
        } while (
          areProblemsEqual(newProblem, lastProblemRef.current) && 
          attempts < maxAttempts
        );

        setCurrentProblem(newProblem);
        lastProblemRef.current = newProblem;
      }
    }
  }, [currentQuestionIndex, questions]);

  const handleAnswer = async (selectedAnswer, correctAnswer) => {
    if (!questions.length || currentQuestionIndex >= questions.length) return;

    const isCorrect = selectedAnswer === 'correct' || selectedAnswer === correctAnswer;
    if (isCorrect) {
      setScore(prev => prev + 1);
    }

    setInputValue('');
    lastProblemRef.current = null;

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        
        // Find topic by matching simplified names
        const topicKey = Object.keys(gradeContent[grade]).find(key => 
          key.toLowerCase().replace(/[^a-z0-9]/g, '') === topic.toLowerCase()
        );
        const quizScores = userData.quizScores?.[grade]?.[topicKey] || [];
        const newScore = {
          score: score + (isCorrect ? 1 : 0),
          total: questions.length,
          date: new Date().toISOString()
        };

        await updateDoc(userRef, {
          [`quizScores.${grade}.${topicKey}`]: [...quizScores, newScore]
        });

        setQuizComplete(true);
      } catch (error) {
        console.error('Error saving quiz score:', error);
      }
    }
  };

  const handleFinish = () => {
    navigate(-1);
  };

  if (loading) {
    return <div className="practice-page">Loading quiz questions...</div>;
  }

  if (questions.length === 0) {
    return <div className="practice-page">No questions available for this section</div>;
  }

  if (quizComplete) {
    return (
      <div className="practice-page">
        <div className="test-complete">
          <h2>Quiz Complete!</h2>
          <p>Your score: {score} out of {questions.length}</p>
          <p>Percentage: {Math.round((score / questions.length) * 100)}%</p>
          <button onClick={handleFinish}>Return to Grade</button>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="practice-page">
      <PracticeHeader
        grade={grade}
        topic={topic}
        level="Quiz"
        progress={{ completed: currentQuestionIndex + 1 }}
        ANSWERS_TO_LEVEL_UP={questions.length}
        levelComplete={quizComplete}
      />

      <PracticeExercise
        levelData={currentQuestion}
        currentProblem={currentProblem}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleAnswer={handleAnswer}
        level="quiz"
        progress={{ completed: currentQuestionIndex + 1 }}
        lastProblemRef={lastProblemRef}
        isTest={true}
      />
    </div>
  );
};

export default Quiz;
