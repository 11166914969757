import { doublesHalves } from './grade4/doublesHalves';
import { decimals } from './grade4/decimals';
import { division } from './grade4/division';
import { equivalent } from './grade4/equivalent'
import { impropermixed } from './grade4/impropermixed';
import { multiples } from './grade4/multiples';
import { factors } from './grade4/factors';
import { multiplication } from './grade4/multiplication';
import { placeValue } from './grade4/placeValue';

const grade4Content = {
    ...placeValue,
    ...multiplication,
    ...division,
    ...doublesHalves,
    ...multiples,
    ...factors,
    ...equivalent,
    ...impropermixed,
    ...decimals,
};

export default grade4Content;
