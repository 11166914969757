import React from 'react';
import { Typography, TextField } from '@mui/material';

const InputWithHint = ({ value, onChange, onKeyDown, inputRef, hint, autoFocus }) => (
  <div className="input-container">
    <TextField
      className="number-input"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      inputRef={inputRef}
      autoFocus={autoFocus}
      variant="standard"
    />
    <Typography variant="caption" className="hint-text">
      {hint}
    </Typography>
  </div>
);

const OperationText = ({ children }) => (
  <Typography variant="h5" component="span" className="expression-text-large">
    {children}
  </Typography>
);

const AddSubtractRow = ({ operation, answers, onInputChange, onKeyDown, firstInputRef, hints, isThreeDigit }) => (
  <>
    {isThreeDigit ? (
      <>
        {operation === '-' && <OperationText>{'('}</OperationText>}
        <InputWithHint value={answers.step1First} onChange={(v) => onInputChange(v, 'step1First')} onKeyDown={onKeyDown} inputRef={firstInputRef} hint={hints[0]} autoFocus />
        <OperationText>{` ${operation} `}</OperationText>
        <InputWithHint value={answers.step1Second} onChange={(v) => onInputChange(v, 'step1Second')} onKeyDown={onKeyDown} hint={hints[0]} />
        <OperationText>{operation === '-' ? ') + (' : ' + '}</OperationText>
        <InputWithHint value={answers.step1Third} onChange={(v) => onInputChange(v, 'step1Third')} onKeyDown={onKeyDown} hint={hints[1]} />
        <OperationText>{` ${operation} `}</OperationText>
        <InputWithHint value={answers.step1Fourth} onChange={(v) => onInputChange(v, 'step1Fourth')} onKeyDown={onKeyDown} hint={hints[1]} />
        <OperationText>{operation === '-' ? ') + (' : ' + '}</OperationText>
        <InputWithHint value={answers.step1Fifth} onChange={(v) => onInputChange(v, 'step1Fifth')} onKeyDown={onKeyDown} hint={hints[2]} />
        <OperationText>{` ${operation} `}</OperationText>
        <InputWithHint value={answers.step1Sixth} onChange={(v) => onInputChange(v, 'step1Sixth')} onKeyDown={onKeyDown} hint={hints[2]} />
        {operation === '-' && <OperationText>{')'}</OperationText>}
      </>
    ) : (
      <>
        {operation === '-' && <OperationText>{'('}</OperationText>}
        <InputWithHint value={answers.step1First} onChange={(v) => onInputChange(v, 'step1First')} onKeyDown={onKeyDown} inputRef={firstInputRef} hint={hints[0]} autoFocus />
        <OperationText>{` ${operation} `}</OperationText>
        <InputWithHint value={answers.step1Second} onChange={(v) => onInputChange(v, 'step1Second')} onKeyDown={onKeyDown} hint={hints[0]} />
        <OperationText>{operation === '-' ? ') + (' : ' + '}</OperationText>
        <InputWithHint value={answers.step1Third} onChange={(v) => onInputChange(v, 'step1Third')} onKeyDown={onKeyDown} hint={hints[1]} />
        <OperationText>{` ${operation} `}</OperationText>
        <InputWithHint value={answers.step1Fourth} onChange={(v) => onInputChange(v, 'step1Fourth')} onKeyDown={onKeyDown} hint={hints[1]} />
        {operation === '-' && <OperationText>{')'}</OperationText>}
      </>
    )}
  </>
);

const MultiplyRow = ({ secondNumber, answers, onInputChange, onKeyDown, firstInputRef, hints, isThreeDigit, steps }) => {
  if (steps?.format === 'expanded') {
    return (
      <>
        <InputWithHint 
          value={answers.step1First} 
          onChange={(v) => onInputChange(v, 'step1First')} 
          onKeyDown={onKeyDown} 
          inputRef={firstInputRef} 
          hint={hints[0]} 
          autoFocus 
        />
        <OperationText>{` × ${steps.split.secondNumberParts.tens} + `}</OperationText>
        <InputWithHint 
          value={answers.step1Second} 
          onChange={(v) => onInputChange(v, 'step1Second')} 
          onKeyDown={onKeyDown} 
          hint={hints[0]} 
        />
        <OperationText>{` × ${steps.split.secondNumberParts.ones} + `}</OperationText>
        <InputWithHint 
          value={answers.step1Third} 
          onChange={(v) => onInputChange(v, 'step1Third')} 
          onKeyDown={onKeyDown} 
          hint={hints[1]} 
        />
        <OperationText>{` × ${steps.split.secondNumberParts.tens} + `}</OperationText>
        <InputWithHint 
          value={answers.step1Fourth} 
          onChange={(v) => onInputChange(v, 'step1Fourth')} 
          onKeyDown={onKeyDown} 
          hint={hints[1]} 
        />
        <OperationText>{` × ${steps.split.secondNumberParts.ones}`}</OperationText>
      </>
    );
  }

  return (
    <>
      <InputWithHint value={answers.step1First} onChange={(v) => onInputChange(v, 'step1First')} onKeyDown={onKeyDown} inputRef={firstInputRef} hint={hints[0]} autoFocus />
      <OperationText>{` × ${secondNumber} + `}</OperationText>
      <InputWithHint value={answers.step1Second} onChange={(v) => onInputChange(v, 'step1Second')} onKeyDown={onKeyDown} hint={hints[1]} />
      {isThreeDigit && (
        <>
          <OperationText>{` × ${secondNumber} + `}</OperationText>
          <InputWithHint value={answers.step1Third} onChange={(v) => onInputChange(v, 'step1Third')} onKeyDown={onKeyDown} hint={hints[2]} />
        </>
      )}
      <OperationText>{` × ${secondNumber}`}</OperationText>
    </>
  );
};

const ResultRow = ({ number, operation, secondNumber, answers, onInputChange, onKeyDown, isThreeDigit, steps }) => {
  if (steps?.format === 'expanded') {
    return (
      <div className="expanded-result">
        <div className="partial-products">
          <OperationText>{`${number} ${operation} ${secondNumber} = `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2First}
            onChange={(e) => onInputChange(e.target.value, 'step2First')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
          <OperationText>{` + `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Second}
            onChange={(e) => onInputChange(e.target.value, 'step2Second')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
          <OperationText>{` + `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Third}
            onChange={(e) => onInputChange(e.target.value, 'step2Third')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
          <OperationText>{` + `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Fourth}
            onChange={(e) => onInputChange(e.target.value, 'step2Fourth')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
        </div>
        <div className="intermediate-sums">
          <OperationText>{`= `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Sum1}
            onChange={(e) => onInputChange(e.target.value, 'step2Sum1')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
          <OperationText>{` + `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Sum2}
            onChange={(e) => onInputChange(e.target.value, 'step2Sum2')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <OperationText>{`${number} ${operation} ${secondNumber} = `}</OperationText>
      <TextField
        className="number-input"
        value={answers.step2First}
        onChange={(e) => onInputChange(e.target.value, 'step2First')}
        onKeyDown={onKeyDown}
        variant="standard"
      />
      <OperationText>{` + `}</OperationText>
      <TextField
        className="number-input"
        value={answers.step2Second}
        onChange={(e) => onInputChange(e.target.value, 'step2Second')}
        onKeyDown={onKeyDown}
        variant="standard"
      />
      {isThreeDigit && (
        <>
          <OperationText>{` + `}</OperationText>
          <TextField
            className="number-input"
            value={answers.step2Third}
            onChange={(e) => onInputChange(e.target.value, 'step2Third')}
            onKeyDown={onKeyDown}
            variant="standard"
          />
        </>
      )}
    </>
  );
};

const FinalRow = ({ number, operation, secondNumber, answers, onInputChange, onKeyDown }) => (
  <>
    <OperationText>{`${number} ${operation} ${secondNumber} = `}</OperationText>
    <TextField
      className="number-input"
      value={answers.step3}
      onChange={(e) => onInputChange(e.target.value, 'step3')}
      onKeyDown={onKeyDown}
      variant="standard"
    />
  </>
);

export { AddSubtractRow, MultiplyRow, ResultRow, FinalRow };
