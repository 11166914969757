import { decimals } from './grade5/decimals';
import { decimalsaddsub } from './grade5/decimaladdsub';
import { decimalsmultdiv } from './grade5/decimalmultdiv';
import { fractionsaddsub } from './grade5/fractionaddsub';
import { fractionsmultidiv } from './grade5/fractionmultidiv';

const grade5Content = {
  ...decimals,
  ...decimalsaddsub,
  ...decimalsmultdiv,
  ...fractionsaddsub,
  ...fractionsmultidiv,
};

export default grade5Content;
