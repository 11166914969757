import { roll } from './utils';

// Helper function to find Greatest Common Divisor
const gcd = (a, b) => {
  a = Math.abs(a);
  b = Math.abs(b);
  while (b) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return a;
};

export const equivalent = {
  "Equivalent Fractions": {
    levels: [
      { id: "f9eqv1t1", description: "Find Equivalent Fractions (Unit Fractions to Missing Numerator)", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
        const den1 = roll(2, 5);
        const num1 = 1
        
        const factor = roll(2, 5);
        const num2 = num1 * factor;
        const den2 = den1 * factor;
        
        const blank = 'numerator'
        
        const given = blank === 'numerator' ? den2 : num2;
        
        return {
          num1,
          den1,
          given,
          blank
        };
      }},
      { id: "f9eqv1dd", description: "Find Equivalent Fractions (Unit Fractions to Missing Denominator)", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
        const den1 = roll(2, 5);
        const num1 = 1
        
        const factor = roll(2, 5);
        const num2 = num1 * factor;
        const den2 = den1 * factor;
        
        const blank = 'denominator'
        
        const given = blank === 'numerator' ? den2 : num2;
        
        return {
          num1,
          den1,
          given,
          blank
        };
      }},
      { id: "f9eqv1ah", description: "Find Equivalent Fractions", exerciseType: "EquivalentFractionsPractice", timeLimit: 90, inputs: () => {
        const den1 = roll(3, 6);
        const num1 = roll(2, den1 -1)
        
        const factor = roll(2, 5);
        const num2 = num1 * factor;
        const den2 = den1 * factor;
        
        const blank = ['numerator','denominator'][roll(0,1)]
        
        const given = blank === 'numerator' ? den2 : num2;
        
        return {
          num1,
          den1,
          given,
          blank
        };
      }},
      { id: "f9eqvbth", description: "Reduce Fractions", exerciseType: "EquivalentFractionsPractice", timeLimit: 120, inputs: () => {
        let num2, den2, divisor;
        
        // Keep generating numbers until we get a fraction that needs significant reduction (GCD > 2)
        do {
          den2 = roll(4, 20);
          num2 = roll(1, den2 - 1);
          divisor = gcd(num2, den2);
        } while (divisor <= 1);
        
        
        // Both numerator and denominator will be blank
        return {
          num1: num2,
          den1: den2,
          blank: 'both'
        };
      }},
      { id: "f9eqvlcd", description: "Common Denominators", exerciseType: "EquivalentFractionsPractice", timeLimit: 300, inputs: () => {
        // Generate two fractions with different denominators
        let den1, den2;
        do {
          den1 = roll(2, 8);
          den2 = roll(2, 8);
        } while (den1 === den2);

        const num1 = roll(1, den1 - 1);
        const num2 = roll(1, den2 - 1);

        // Calculate LCD
        let lcd = den1 * den2;
        for (let i = Math.max(den1, den2); i <= den1 * den2; i++) {
          if (i % den1 === 0 && i % den2 === 0) {
            lcd = i;
            break;
          }
        }

        // Return the original fractions and expect both numerator and denominator
        return {
          num1,
          den1,
          num2,
          den2,
          lcd,
          blank: 'both',
          requireLCD: true
        };
      }},
      { id: "f9c0zzt1", description: "Compare Proper Fractions", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 180, inputs: () => {
        const den1 = roll(3, 8);
        let den2;
        do {
          den2 = roll(3, 8);
        } while (den2 === den1);

        const num1 = roll(2, den1 - 1);
        const num2 = roll(2, den2 - 1);
        
        const val1 = num1 / den1;
        const val2 = num2 / den2;
        
        let correctAnswer;
        if (val1 < val2) correctAnswer = '<';
        else if (val1 > val2) correctAnswer = '>';
        else correctAnswer = '=';

        return {
          fraction1: { numerator: num1, denominator: den1 },
          fraction2: { numerator: num2, denominator: den2 },
          correctAnswer
        };
      }}
    ]
  }
};
