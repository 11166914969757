// Shared input validation and formatting utilities
export const validateNumericInput = (input) => {
  return input.match(/^-?\d*\.?\d+$/);
};

export const validateDivisionWithRemainder = (input) => {
  const parts = input.split(',');
  if (parts.length !== 2) return false;
  const [quotient, remainder] = parts;
  return quotient.match(/^\d+$/) && remainder.match(/^\d+$/);
};

export const formatNumericInput = (input) => {
  // If input contains a slash or space, treat it as a fraction input
  if (input.includes('/') || input.includes(' ')) {
    return input.replace(/[^\d\s/]/g, '');  // Allow numbers, spaces, and slashes
  }
  // Default case - only allow numbers, decimal points, and minus signs
  return input.replace(/[^\d.-]/g, '');
};

export const handleMultipleChoiceKeyPress = (e, choices, setInputValue) => {
  const num = parseInt(e.key, 10);
  if (num >= 1 && num <= choices.length) {
    const choice = choices[num - 1];
    setInputValue(choice);
  }
};

export const areProblemsEqual = (problem1, problem2) => {
  if (!problem1 || !problem2) return false;
  return JSON.stringify(problem1.problem) === JSON.stringify(problem2.problem);
};

// Shared keyboard event handling
export const handleEnterKey = (e, showAnswerDialog, handleCloseDialog, handleSubmit, value) => {
  if (e.key === 'Enter') {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (showAnswerDialog) {
      handleCloseDialog();
    } else {
      handleSubmit(null, value?.trim());
    }
  }
};

// Shared answer validation
export const validateAnswer = (input, correctAnswer) => {
  // Handle string comparison first
  if (input === correctAnswer || input === 'correct') {
    return true;
  }

  if (Array.isArray(correctAnswer)) {
    return correctAnswer.includes(input);
  }
  
  // Convert both input and answer to numbers by removing commas and parsing
  const numericInput = Number(input.toString().replace(/,/g, ''));
  const numericAnswer = Number(correctAnswer.toString().replace(/,/g, ''));
  return !isNaN(numericInput) && numericInput === numericAnswer;
};
