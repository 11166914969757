import grade1Content from '../grades/content/grade1Content';
import grade2Content from '../grades/content/grade2Content';
import grade3Content from '../grades/content/grade3Content';
import grade4Content from '../grades/content/grade4Content';
import grade5Content from '../grades/content/grade5Content';
import { getPractices } from '../../utils/skillUtils';

const gradeContents = {
  1: grade1Content,
  2: grade2Content,
  3: grade3Content,
  4: grade4Content,
  5: grade5Content
};

// Generate skill content structure
const generateSkillContent = () => {
  const skills = {
    'PlaceValue': 'Place Value',
    'AddSubtract': 'Addition & Subtraction',
    'MultiplyDivide': 'Multiplication & Division',
    'Factors': 'Factors & Multiples',
    'Fractions': 'Fractions'
  };

  const skillContent = {};

  Object.entries(skills).forEach(([skillType, skillName]) => {
    const skillPractices = {};
    
    // Gather practices from all grades for this skill
    Object.entries(gradeContents).forEach(([grade, content]) => {
      const practices = getPractices(content, skillType);
      
      // Group practices by section name
      practices.forEach(practice => {
        const sectionKey = `Grade ${grade} - ${practice.sectionName}`;
        if (!skillPractices[sectionKey]) {
          skillPractices[sectionKey] = {
            levels: []
          };
        }
        skillPractices[sectionKey].levels.push(practice);
      });
    });

    if (Object.keys(skillPractices).length > 0) {
      skillContent[skillName] = skillPractices;
    }
  });

  return skillContent;
};

const skillContent = generateSkillContent();

export default skillContent;
