import { TextField, Typography } from '@mui/material';
import { formatNumericInput, handleEnterKey } from '../../utils/inputUtils';

export default function InlineInput({
  question,
  value,
  onChange,
  onSubmit,
  showAnswerDialog,
  handleCloseDialog,
  autoFocus = true
}) {
  const handleKeyDown = (e) => {
    handleEnterKey(e, showAnswerDialog, handleCloseDialog, onSubmit, value);
  };

  const handleChange = (e) => {
    const formattedValue = formatNumericInput(e.target.value);
    onChange(formattedValue);
  };

  const parts = question.split('_');

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(null, value?.trim());
      }} 
      className="content-box"
    >
      <Typography 
        className="practice-title" 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px', 
          justifyContent: 'center' 
        }}
      >
        {parts[0]}
        <TextField
          variant="outlined"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          className="number-input"
          style={{ width: '80px', margin: '0 4px' }}
          size="small"
        />
        {parts[1]}
      </Typography>
    </form>
  );
}
