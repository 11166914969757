import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

export default function LevelCompleteDialog({ open, level, onClose, onPracticeAgain }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="level-complete-dialog"
      PaperProps={{ className: 'level-complete-dialog' }}
    >
      <DialogTitle className="level-complete-title">
        Level Complete!
      </DialogTitle>
      <DialogContent>
        <Typography className="level-complete-content">
          Congratulations! You've completed this level!
        </Typography>
      </DialogContent>
      <DialogActions className="level-complete-actions">
        <Button 
          onClick={onPracticeAgain}
          variant="outlined"
          className="practice-again-button"
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          Practice Again
        </Button>
        <Button 
          onClick={onClose}
          variant="contained"
          className="continue-button"
          autoFocus
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
