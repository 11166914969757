const generateProblem = (inputs) => {
  const newInputs = typeof inputs === 'function' ? inputs() : inputs;
  const isThreeDigit = newInputs.number.toString().length === 3 || newInputs.secondNumber.toString().length === 3;
  
  // Add split property for number parts
  const num1Str = newInputs.number.toString().padStart(isThreeDigit ? 3 : 2, '0');
  const num2Str = newInputs.secondNumber.toString().padStart(isThreeDigit ? 3 : 2, '0');
  
  // Pass through format and split from inputs if they exist
  const result = {
    ...newInputs,
    isThreeDigit
  };

  // If format is expanded, pass through split property directly
  if (newInputs.format === 'expanded') {
    result.format = newInputs.format;
    result.split = newInputs.split;
  } else {
    // Otherwise generate default split property
    result.split = {
      firstNumberParts: isThreeDigit ? {
        hundreds: parseInt(num1Str[0] + '00'),
        tens: parseInt(num1Str[1] + '0'),
        ones: parseInt(num1Str[2])
      } : {
        tens: parseInt(num1Str[0] + '0'),
        ones: parseInt(num1Str[1])
      },
      secondNumberParts: isThreeDigit ? {
        hundreds: parseInt(num2Str[0] + '00'),
        tens: parseInt(num2Str[1] + '0'),
        ones: parseInt(num2Str[2])
      } : {
        tens: parseInt(num2Str[0] + '0'),
        ones: parseInt(num2Str[1])
      }
    };
  }

  return result;
};

const getEmptyAnswers = () => ({
  step1First: '',
  step1Second: '',
  step1Third: '',
  step1Fourth: '',
  step1Fifth: '',
  step1Sixth: '',
  step2First: '',
  step2Second: '',
  step2Third: '',
  step2Fourth: '',  // Added for expanded format's fourth partial product
  step2Sum1: '',    // Sum of first two partial products
  step2Sum2: '',    // Sum of last two partial products
  step3: ''
});

export { generateProblem, getEmptyAnswers };
