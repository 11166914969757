import { roll } from './utils';

export const decimalsmultdiv = {
  "Multiply/Divide Decimals": {
    levels: [
      {
        id: "d5mult2",
        description: "Multiply Tenths by Whole Number",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1 = roll(1, 9)/10;
          let num2 = roll(2, 5);
          return { num1, num2, operation: '*' };
        }
      },
      {
        id: "d5mult3",
        description: "Multiply Tenths by Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1 = roll(2, 9)/10;
          let num2 = roll(2, 9)/10;
          return { num1, num2, operation: '*' };
        }
      },
      {
        id: "d5mult1",
        description: "Multiply Decimal by Whole Number",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1 = roll(2, 5) + roll(1, 9)/10;
          let num2 = roll(2, 5);
          return { num1, num2, operation: '*' };
        }
      },
    ]
  }
};
