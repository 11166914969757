import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton({ tooltip }) {
  const navigate = useNavigate();

  return (
    <Box className="back-button">
      <Tooltip title={tooltip || "Back"}>
        <IconButton 
          onClick={() => navigate(-1)}
          className="back-button-icon"
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
