export const calculateCarries = (num1, num2, operation) => {
  const num1Str = num1.toString();
  const num2Str = num2.toString();
  const maxLength = Math.max(num1Str.length, num2Str.length);
  const carries = Array(maxLength).fill('');
  const strikeDigits = Array(maxLength).fill(false);

  if (operation === '*' && num2Str.length === 1) {
    // Single digit multiplication
    let carry = 0;
    for (let i = maxLength - 1; i >= 0; i--) {
      const d1 = parseInt(num1Str[num1Str.length - (maxLength - i)] || '0');
      const d2 = parseInt(num2Str);
      const product = d1 * d2 + carry;
      if (product >= 10) {
        carry = Math.floor(product / 10);
        if (i > 0) { // Don't show carry for leftmost position
          carries[i - 1] = carry.toString();
        }
      } else {
        carry = 0;
      }
    }
  } else if (operation === '+') {
    let carry = 0;
    for (let i = maxLength - 1; i >= 0; i--) {
      const d1 = parseInt(num1Str[num1Str.length - (maxLength - i)] || '0');
      const d2 = parseInt(num2Str[num2Str.length - (maxLength - i)] || '0');
      const sum = d1 + d2 + carry;
      if (sum >= 10) {
        carry = 1;
        if (i > 0) { // Don't show carry for leftmost position
          carries[maxLength - i - 1] = '1'; // Place carry over the tens column, matching multiplication's order
        }
      } else {
        carry = 0;
      }
    }
  } else if (operation === '-') {
    // Keep track of modified digits as we borrow
    const modifiedDigits = [...num1Str].map(d => parseInt(d));
    
    for (let i = maxLength - 1; i >= 0; i--) {
      const pos = num1Str.length - (maxLength - i);
      const d1 = modifiedDigits[pos];
      const d2 = parseInt(num2Str[num2Str.length - (maxLength - i)] || '0');
      
      if (d1 < d2) {
        // Need to borrow
        carries[i] = (d1 + 10).toString(); // Show original digit + 10
        strikeDigits[i] = true; // Strike through ones digit
        
        if (i > 0) {
          // Decrease the tens digit by 1 and mark it as struck through
          const tensPos = pos - 1;
          modifiedDigits[tensPos] = modifiedDigits[tensPos] - 1;
          carries[i - 1] = modifiedDigits[tensPos].toString();
          strikeDigits[i - 1] = true;
        }
      }
    }
  }

  return { carries, strikeDigits };
};

export const calculateIntermediates = (num1, num2) => {
  const num2Str = num2.toString();
  if (num2Str.length <= 1) return [];

  const resultLength = num1.toString().length + num2Str.length;
  const digits = num2Str.split('').reverse();
  
  // Calculate each row
  const rows = digits.map((digit, i) => {
    const product = (num1 * parseInt(digit)).toString();
    if (i === 1) {
      // For tens digit, shift left by adding a zero
      return product.padStart(resultLength - 1, ' ').split('');
    }
    return product.padStart(resultLength - i, ' ').split('');
  });

  return rows;
};

export const getResultLength = (num1, num2, operation) => {
  const num1Str = num1.toString();
  const num2Str = num2.toString();
  const maxLength = Math.max(num1Str.length, num2Str.length);

  switch (operation) {
    case '+':
      return maxLength + 1;
    case '-':
      return num1Str.length; // length of minuend
    case '*':
      return num1Str.length + num2Str.length; // sum of lengths
    default:
      return maxLength;
  }
};
