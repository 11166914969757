import { addSubtract20 } from './grade2/addSubtract20';
import { placeValue } from './grade2/placeValue';
import { twoDigitOps } from './grade2/twoDigitOps';
import { threeDigitOps } from './grade2/threedigitOps';

const grade2Content = {
  ...addSubtract20,
  ...placeValue,
  ...twoDigitOps,
  ...threeDigitOps,
};

export default grade2Content;
