import { roll } from '../grade2/utils';
import { generateGcfPair } from '../../../../components/math/practices/GcfLcmPractice';

const isPrime = (num) => {
  if (num < 2) return false;
  for (let i = 2; i <= Math.sqrt(num); i++) {
    if (num % i === 0) return false;
  }
  return true;
};

export const factors = {
  "Factors": {
    levels: [
      { id: "div2", description: "Divisible by 2", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        return {
          question: `Is ${number} divisible by 2?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 2 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div5", description: "Divisible by 5", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(1,9)*10 + [0,1,2,3,4,5,6,7,8,9,0,0,5,5][roll(0,13)];
        return {
          question: `Is ${number} divisible by 5?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 5 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div3", description: "Divisible by 3", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        const divisor = 3
        return {
          question: `Is ${number} divisible by ${divisor}?`,
          choices: ["Yes", "No"],
          correctAnswer: number % divisor === 0 ? "Yes" : "No"
        };
      }},
      { id: "div235", description: "Divisible by 2, 3, or 5", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => {
        const number = roll(10, 99);
        const divisor = [2,3,5][roll(0,2)]
        return {
          question: `Is ${number} divisible by ${divisor}?`,
          choices: ["Yes", "No"],
          correctAnswer: number % divisor === 0 ? "Yes" : "No"
        };
      }},
      { id: "div4", description: "Divisible by 4", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(10, 49)*2;
        return {
          question: `Is ${number} divisible by 4?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 4 === 0 ? "Yes" : "No"
        };
      }},
      { id: "div6", description: "Divisible by 6", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(10, 49)*2;
        return {
          question: `Is ${number} divisible by 6?`,
          choices: ["Yes", "No"],
          correctAnswer: number % 6 === 0 ? "Yes" : "No"
        };
      }},
      { id: "factors20", description: "Factors up to 20", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(2,20) }) },
      { id: "factors40", description: "Factors up to 40", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(21,40) }) },
      { id: "factors60", description: "Factors up to 60", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(41,60) }) },
      { id: "factors80", description: "Factors up to 80", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(61,80) }) },
      { id: "factors100", description: "Factors up to 100", exerciseType: "FactorPractice", timeLimit: 240, inputs: () => ({ number: roll(81,100) }) },
      { id: "prime", description: "Prime or Composite", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(2, 99);
        return {
          question: `Is ${number} prime or composite?`,
          choices: ["Prime", "Composite"],
          correctAnswer: isPrime(number) ? "Prime" : "Composite"
        };
      }},
      {
        id: "1asdfas",
        description: "GCF up to 20",
        exerciseType: "GcfLcmPractice",
        timeLimit: 120,
        inputs: () => ({
          mode: 'gcf',
          numbers: generateGcfPair(4, 20)
        })
      },
      {
        id: "1asdfga",
        description: "GCF up to 50",
        exerciseType: "GcfLcmPractice",
        timeLimit: 180,
        inputs: () => ({
          mode: 'gcf',
          numbers: generateGcfPair(6, 50)
        })
      }
    ]
  }
};
