import React from 'react';
import { Box, Typography } from '@mui/material';
import { getResultLength } from './Generator';
import './styles.css';

const DigitInput = ({ value, onChange, onKeyDown, inputRef, autoFocus, onArrowKey }) => (
  <input
    type="text"
    value={value}
    onChange={(e) => {
      const val = e.target.value.replace(/\D/g, '');
      if (val.length <= 2) onChange(val);
    }}
    onKeyDown={(e) => {
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
        onArrowKey(e.key);
      } else if (e.key === 'Enter' && onKeyDown) {
        onKeyDown(e);
      }
    }}
    ref={inputRef}
    autoFocus={autoFocus}
    style={{
      width: '2ch',
      height: '2ch',
      textAlign: 'center',
      fontSize: '1.2rem',
      fontFamily: 'monospace',
      fontWeight: 'bold',
      color: 'var(--text-primary)',
      backgroundColor: 'var(--bg-primary)',
      border: '1px solid var(--border-color)',
      borderRadius: '4px',
      padding: '0',
      margin: '0 2px'
    }}
  />
);

export const createRefs = (num1, num2, operation) => {
  const resultLength = getResultLength(num1, num2, operation);
  const maxLength = Math.max(num1.toString().length, num2.toString().length);

  // For multiplication, we need multiple intermediate rows
  const intermediateRows = operation === '*' && num2.toString().length > 1 
    ? num2.toString().length 
    : 1;

  // Add extra carry slot for subtraction's ones place
  const carryLength = maxLength - 1 + (operation === '-' ? 1 : 0);

  return {
    carries: Array(carryLength).fill(null).map(() => React.createRef()),
    intermediates: Array(intermediateRows).fill(null).map(() => 
      Array(resultLength).fill(null).map(() => React.createRef())
    ),
    total: Array(resultLength).fill(null).map(() => React.createRef())
  };
};

export const handleArrowKey = (key, type, rowIndex, digitIndex, refs) => {
  if (!refs) return null;

  const maxRows = refs.intermediates.length;
  const resultLength = refs.total.length;

  const getCarryBoxForPosition = (fromRight) => {
    const targetCarryIndex = refs.carries.length - 1 - (fromRight - 1);
    if (targetCarryIndex >= 0 && targetCarryIndex < refs.carries.length) {
      return refs.carries[targetCarryIndex];
    } else if (fromRight > 1) {
      return refs.carries[0];
    }
    return null;
  };

  const getInputBoxForCarry = (carryIndex) => {
    const carryFromRight = refs.carries.length - 1 - carryIndex;
    const targetIndex = resultLength - 1 - carryFromRight;
    
    if (maxRows > 1) {
      return refs.intermediates[0]?.[targetIndex];
    }
    return refs.total[targetIndex];
  };

  let nextRef = null;

  if (type === 'carry') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.carries[digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < refs.carries.length - 1) {
      nextRef = refs.carries[digitIndex + 1];
    } else if (key === 'ArrowDown') {
      nextRef = getInputBoxForCarry(digitIndex);
    }
  } else if (type === 'intermediate') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.intermediates[rowIndex][digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < resultLength - 1) {
      nextRef = refs.intermediates[rowIndex][digitIndex + 1];
    } else if (key === 'ArrowUp') {
      if (rowIndex === 0) {
        const inputFromRight = resultLength - 1 - digitIndex;
        nextRef = getCarryBoxForPosition(inputFromRight);
      } else {
        nextRef = refs.intermediates[rowIndex - 1][digitIndex];
      }
    } else if (key === 'ArrowDown') {
      if (rowIndex === maxRows - 1) {
        nextRef = refs.total[digitIndex];
      } else {
        nextRef = refs.intermediates[rowIndex + 1][digitIndex];
      }
    }
  } else if (type === 'total') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.total[digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < resultLength - 1) {
      nextRef = refs.total[digitIndex + 1];
    } else if (key === 'ArrowUp') {
      if (maxRows > 1) {
        nextRef = refs.intermediates[maxRows - 1][digitIndex];
      } else {
        const inputFromRight = resultLength - 1 - digitIndex;
        nextRef = getCarryBoxForPosition(inputFromRight);
      }
    }
  }

  return nextRef;
};

const InputRows = ({ num1, num2, operation, answers, inputRefs, handleDigitChange, handleKeyDown, handleArrowKey }) => {
  const resultLength = getResultLength(num1, num2, operation);
  const num1Str = num1.toString();
  const num2Str = num2.toString();
  const maxLength = Math.max(num1Str.length, num2Str.length);

  return (
    <form onSubmit={handleKeyDown} className="vertical-operation">
      <Box className="operation-numbers">
        {/* Carry row */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.25rem', marginBottom: '2px' }}>
          {/* Multiplication carry boxes */}
          {operation === '*' && (
            <>
              {Array(maxLength - 1).fill(0).map((_, i) => (
                <DigitInput
                  key={`carry-${maxLength - 2 - i}`}
                  value={answers.carries[maxLength - 2 - i] || ''}
                  onChange={(value) => handleDigitChange(value, 'carry', null, maxLength - 2 - i)}
                  onKeyDown={handleKeyDown}
                  inputRef={inputRefs.current.carries[maxLength - 2 - i]}
                  autoFocus={i === maxLength - 2}
                  onArrowKey={(key) => handleArrowKey(key, 'carry', null, maxLength - 2 - i)}
                />
              ))}
              <Box sx={{ width: '32px', height: '32px', margin: '0 2px' }} />
            </>
          )}
          {/* Addition carry boxes */}
          {operation === '+' && (
            <>
              {Array(maxLength - 1).fill(0).map((_, i) => (
                <DigitInput
                  key={`carry-${maxLength - 2 - i}`}
                  value={answers.carries[maxLength - 2 - i] || ''}
                  onChange={(value) => handleDigitChange(value, 'carry', null, maxLength - 2 - i)}
                  onKeyDown={handleKeyDown}
                  inputRef={inputRefs.current.carries[maxLength - 2 - i]}
                  autoFocus={i === maxLength - 2}
                  onArrowKey={(key) => handleArrowKey(key, 'carry', null, maxLength - 2 - i)}
                />
              ))}
              <Box sx={{ width: '32px', height: '32px', margin: '0 2px' }} />
            </>
          )}
          {/* Subtraction carry boxes */}
          {operation === '-' && Array(maxLength).fill(0).map((_, i) => (
            <DigitInput
              key={`carry-${i}`}
              value={answers.carries[i] || ''}
              onChange={(value) => handleDigitChange(value, 'carry', null, i)}
              onKeyDown={handleKeyDown}
              inputRef={inputRefs.current.carries[i]}
              autoFocus={i === 0}
              onArrowKey={(key) => handleArrowKey(key, 'carry', null, i)}
            />
          ))}
        </Box>

        {/* First number */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
          {num1Str.padStart(resultLength, ' ').split('').map((digit, i) => (
            <Box key={`num1-${i}`} className="number">
              {digit === ' ' ? '' : digit}
            </Box>
          ))}
        </Box>

        {/* Operation and second number */}
        <Box className="operation-line-container">
          <Typography className="operation-symbol">
            {operation === '*' ? '×' : operation}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {num2Str.padStart(resultLength, ' ').split('').map((digit, i) => (
              <Box key={`num2-${i}`} className="number">
                {digit === ' ' ? '' : digit}
              </Box>
            ))}
          </Box>
        </Box>

        <div className="operation-line" />

        {/* Intermediate steps for multiplication */}
        {operation === '*' && num2Str.length > 1 && (
          <>
            {num2Str.split('').reverse().map((_, rowIndex) => (
              <Box key={`intermediate-${rowIndex}`} sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
                {Array(resultLength).fill(0).map((_, digitIndex) => (
                  <DigitInput
                    key={`intermediate-${rowIndex}-${digitIndex}`}
                    value={answers.intermediates[rowIndex]?.[digitIndex] || ''}
                    onChange={(value) => handleDigitChange(value, 'intermediate', rowIndex, digitIndex)}
                    onKeyDown={handleKeyDown}
                    inputRef={inputRefs.current.intermediates[rowIndex]?.[digitIndex]}
                    onArrowKey={(key) => handleArrowKey(key, 'intermediate', rowIndex, digitIndex)}
                  />
                ))}
              </Box>
            ))}
            <div className="operation-line" />
          </>
        )}

        {/* Answer row */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {Array(resultLength).fill(0).map((_, i) => (
            <DigitInput
              key={`total-${i}`}
              value={answers.total[i] || ''}
              onChange={(value) => handleDigitChange(value, 'total', null, i)}
              onKeyDown={handleKeyDown}
              inputRef={inputRefs.current.total[i]}
              onArrowKey={(key) => handleArrowKey(key, 'total', null, i)}
            />
          ))}
        </Box>
      </Box>
    </form>
  );
};

export default InputRows;
