import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSkillProgress from '../../hooks/useSkillProgress';
import grade1Content from '../grades/content/grade1Content';
import grade2Content from '../grades/content/grade2Content';
import grade3Content from '../grades/content/grade3Content';
import grade4Content from '../grades/content/grade4Content';
import grade5Content from '../grades/content/grade5Content';
import '../../styles/grades/gradelanding.css';

const formatTime = (seconds) => {
  if (seconds === null) return '--';
  if (seconds === 0) return 'Complete'; // For legacy data
  
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  
  if (mins > 0) {
    return `${mins}m ${secs}s`;
  }
  return `${secs}s`;
};

const gradeContents = {
  1: grade1Content,
  2: grade2Content,
  3: grade3Content,
  4: grade4Content,
  5: grade5Content
};

// Helper function to extract practices from grade content
const getPractices = (gradeContent, skillType) => {
  const practices = [];
  Object.entries(gradeContent).forEach(([sectionName, section]) => {
    section.levels?.forEach(level => {
      const sectionNameLower = sectionName.toLowerCase();
      
      // Check content area names based on skill type
      if (skillType === 'PlaceValue' && (sectionNameLower.includes('place value'))) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'AddSubtract' && (
        sectionNameLower.includes('addition') || 
        sectionNameLower.includes('subtract') ||
        sectionNameLower.includes('add')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'MultiplyDivide' && (
        sectionNameLower.includes('multiply') || 
        sectionNameLower.includes('divide')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'Factors' && (
        sectionNameLower.includes('even') ||
        sectionNameLower.includes('doubles') ||
        sectionNameLower.includes('halves') ||
        sectionNameLower.includes('multiples') ||
        sectionNameLower.includes('factors')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'Fractions' && (
        sectionNameLower.includes('fraction')
      )) {
        practices.push({ ...level, sectionName });
      }
      // For other skills, check exercise types
      else if (skillType !== 'PlaceValue' && skillType !== 'AddSubtract' && 
               skillType !== 'MultiplyDivide' && skillType !== 'Factors' && 
               skillType !== 'Fractions') {
        const exerciseTypes = Array.isArray(skillType) ? skillType : [skillType];
        const matchesType = exerciseTypes.some(type => level.exerciseType === `${type}Practice`);
        if (matchesType) {
          practices.push({ ...level, sectionName });
        }
      }
    });
  });
  return practices;
};

export default function SkillLanding() {
  const navigate = useNavigate();
  const { skillType: urlSkillType } = useParams();
  const getSkillType = (url) => {
    switch(url) {
      case 'placevalue':
        return 'PlaceValue';
      case 'addsubtract':
        return 'AddSubtract';
      case 'multiplydivide':
        return 'MultiplyDivide';
      case 'factors':
        return 'Factors';
      case 'fractions':
        return 'Fractions';  
      default:
        return url.charAt(0).toUpperCase() + url.slice(1);
    }
  };
  const skillType = getSkillType(urlSkillType);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const { getLevelBestTime, loading, completionPercentage } = useSkillProgress(skillType);

  if (loading) {
    return <div className="loading">Loading progress...</div>;
  }

  const handleLevelClick = (practice) => {
    navigate(`/practice/${practice.id}`, { state: { from: 'skill' } });
  };

  const renderPractices = (grade) => {
    const practices = getPractices(gradeContents[grade], skillType);
    const practicesBySection = {};
    
    practices.forEach(practice => {
      if (!practicesBySection[practice.sectionName]) {
        practicesBySection[practice.sectionName] = [];
      }
      practicesBySection[practice.sectionName].push(practice);
    });

    return Object.entries(practicesBySection).map(([sectionName, sectionPractices]) => (
      <div key={sectionName} className="topic-card">
        <h2>{sectionName}</h2>
        <div className="levels-list">
          {sectionPractices.map((practice, index) => (
            <div 
              key={practice.id || index}
              className={`level-square ${getLevelBestTime(practice.id) !== null ? 'completed' : ''}`}
              data-tooltip={practice.description}
              onClick={() => handleLevelClick(practice)}
            >
              {getLevelBestTime(practice.id) !== null && (
                <span className="completion-time">{formatTime(getLevelBestTime(practice.id))}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  if (!selectedGrade) {
    return (
      <div className="grade-landing">
        <div className="grade-header">
          <h1>
            <span 
              onClick={() => navigate(-1)}
              style={{ cursor: 'pointer' }}
            >
              ← Back to Skills
            </span>
          </h1>
          <div className="completion-status">
            <div className="completion-text">
              Completion: {completionPercentage}%
            </div>
            <div className="completion-bar-container">
              <div className="completion-bar">
                <div 
                  className="completion-progress" 
                  style={{ width: `${completionPercentage}%` }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="topics">
          {Object.keys(gradeContents).map(grade => {
            const practices = getPractices(gradeContents[grade], skillType);
            if (practices.length === 0) return null;
            
            const practicesBySection = {};
            practices.forEach(practice => {
              if (!practicesBySection[practice.sectionName]) {
                practicesBySection[practice.sectionName] = [];
              }
              practicesBySection[practice.sectionName].push(practice);
            });

            return Object.entries(practicesBySection).map(([sectionName, sectionPractices]) => (
              <div key={`${grade}-${sectionName}`} className="topic-card">
                <h2>Grade {grade} - {sectionName}</h2>
                <div className="levels-list">
                  {sectionPractices.map((practice, index) => (
                    <div 
                      key={practice.id || index}
                      className={`level-square ${getLevelBestTime(practice.id) !== null ? 'completed' : ''}`}
                      data-tooltip={practice.description}
                      onClick={() => handleLevelClick(practice)}
                    >
                      {getLevelBestTime(practice.id) !== null && (
                        <span className="completion-time">{formatTime(getLevelBestTime(practice.id))}</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ));
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="grade-landing">
      <div className="grade-header">
        <h1>
          <span 
            onClick={() => setSelectedGrade(null)}
            style={{ cursor: 'pointer' }}
          >
            ← Back to Grades
          </span>
        </h1>
        <div className="completion-status">
          <div className="completion-text">
            Completion: {completionPercentage}%
          </div>
          <div className="completion-bar-container">
            <div className="completion-bar">
              <div 
                className="completion-progress" 
                style={{ width: `${completionPercentage}%` }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="topics">
        {renderPractices(selectedGrade)}
      </div>
    </div>
  );
}
