export const exponents = {
  "Exponents": {
    levels: [
      {
        id: "e5sqas1",
        description: "Exponents of 10",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: 10,
          num2: Math.floor(Math.random() * 5) + 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5sqba1",
        description: "Exponents of 2",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: 2,
          num2: Math.floor(Math.random() * 5) + 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5sadt1",
        description: "Square Numbers (2² to 9²)",
        exerciseType: "OperationsPractice",
        timeLimit: 90,
        inputs: () => ({
          num1: Math.floor(Math.random() * 7) + 3, // 2-9
          num2: 2,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5cb1t1",
        description:"Cube Numbers (2³ to 9³)",
        exerciseType: "OperationsPractice",
        timeLimit: 120,
        inputs: () => ({
          num1: Math.floor(Math.random() * 8) + 2,
          num2: 3,
          operation: "^",
          missingPosition: "result"
        })
      },
      {
        id: "e5sq2t1",
        description: "Find Missing Base in Square Numbers",
        exerciseType: "OperationsPractice",
        timeLimit: 45,
        inputs: () => ({
          num1: Math.floor(Math.random() * 8) + 2, // 2-9
          num2: 2,
          operation: "^",
          missingPosition: "num1"
        })
      },
      {
        id: "e5ex1t1",
        description: "Find Missing Exponent (2-4 Base)",
        exerciseType: "OperationsPractice",
        timeLimit: 45,
        inputs: () => {
          const base = Math.floor(Math.random() * 3) + 2; // 2-4
          const exponent = Math.floor(Math.random() * 3) + 2; // 2-4
          return {
            num1: base,
            num2: exponent,
            operation: "^",
            missingPosition: "num2"
          };
        }
      },
      {
        id: "e5hp1t1",
        description: "Higher Powers (2-4 Base, 2-5 Exponent)",
        exerciseType: "OperationsPractice",
        timeLimit: 45,
        inputs: () => {
          const base = Math.floor(Math.random() * 3) + 2; // 2-4
          const exponent = Math.floor(Math.random() * 4) + 2; // 2-5
          return {
            num1: base,
            num2: exponent,
            operation: "^",
            missingPosition: "result"
          };
        }
      }
    ]
  }
};
