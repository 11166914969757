import { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import AnalogClock from './AnalogClock';
import '../../../styles/practice.css';

export default function TimePractice({ level, onAnswer, currentProgress, inputs: inputsFn, onKeyDown }) {
  const [answer, setAnswer] = useState('');
  const [problem, setProblem] = useState(null);

  useEffect(() => {
    if (typeof inputsFn === 'function') {
      const newInputs = inputsFn();
      setProblem({ hour: newInputs.hour, minute: newInputs.minute });
      setAnswer('');
    }
  }, [level, currentProgress, inputsFn]);

  const validateTimeFormat = (input) => {
    // Remove all whitespace
    const formatted = input.replace(/\s/g, '');
    // Check if matches hh:mm format
    return /^\d{1,2}:\d{2}$/.test(formatted);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedAnswer = answer.replace(/\s/g, '');
    const expectedHour = problem.hour.toString();
    const expectedMinute = problem.minute.toString().padStart(2, '0');
    const expectedAnswer = `${expectedHour}:${expectedMinute}`;
    
    // Allow any input for timed practice
    const isCorrect = validateTimeFormat(formattedAnswer) && 
      (formattedAnswer === expectedAnswer || 
       formattedAnswer === expectedAnswer.padStart(5, '0')); // Accept with or without leading zero
    
    onAnswer(isCorrect ? 'correct' : formattedAnswer || 'skipped', expectedAnswer);

    // Generate new problem
    if (typeof inputsFn === 'function') {
      const newInputs = inputsFn();
      setProblem({ hour: newInputs.hour, minute: newInputs.minute });
      setAnswer('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  if (!problem) return null;

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Box className="clock-container">
        <AnalogClock hour={problem.hour} minute={problem.minute} />
        <TextField
          variant="outlined"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="hh:mm"
          autoComplete="off"
          autoFocus
          className="number-input"
          size="small"
        />
      </Box>
    </form>
  );
}
