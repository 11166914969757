import { roll } from './utils';

export const placeValue = {
  "Place Value (Thousands/Millions)": {
    levels: [
      { 
        id: "q9xzdadg", 
        description: "Place Value of 4 Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 60, 
        inputs: () => {
          return {
            number: roll(100, 9999),
            places: [10**roll(2,3)]
          }
        }
      },
      { id: "d8l2szz4", description: "Count Up by 1000 from 1000", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: 1000, increment: 1000 } },
      { id: "d8l2asdp9", description: "Count Down by 1000 from 10000", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: 10000, increment: -1000 } },
      { id: "k9mgn7p2", description: "Count Up by 1000", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: roll(111, 999), increment: 1000}},
      { id: "kcm4n7r5", description: "Count Down by 1000", exerciseType: "CountingPractice", timeLimit: 45, inputs: { start: roll(9001, 9999), increment: -1000}},
      { id: "rfzt7y9u2", description: "Convert Up to Thousands", exerciseType: "ConversionPractice", timeLimit: 90, inputs: () => {
        const units = ['one', 'ten', 'hundred', 'thousand'];
        const fromUnitIndex = roll(0, 3);
        const fromUnit = units[fromUnitIndex];
        
        // Ensure toUnit is different from fromUnit
        let toUnitIndex;
        do {
          toUnitIndex = roll(0, 3);
        } while (toUnitIndex === fromUnitIndex);
        const toUnit = units[toUnitIndex];
        
        // Calculate powers needed for clean conversion
        const powerNeeded = toUnitIndex > fromUnitIndex ? toUnitIndex - fromUnitIndex : 0;
        
        // Calculate max additional power allowed based on fromUnit
        const maxExtraPower = Math.max(0, Math.min(3 - fromUnitIndex - powerNeeded, 1));
        
        // Generate value that's both:
        // 1. Large enough to convert without decimals
        // 2. Not too large for the fromUnit
        const value = roll(1, 9) * Math.pow(10, powerNeeded + roll(0, maxExtraPower));
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }},
      {
        id: "r4nd3n7r",
        description: "Round to the Nearest Thousand",
        exerciseType: "RoundingPractice",
        timeLimit: 60,
        inputs: () => ({
          number: roll(1, 9499),
          roundTo: 1000
        })
      },
      {
        id: "r4nd4p6s",
        description: "Rounding 4 Digit Numbers",
        exerciseType: "RoundingPractice",
        timeLimit: 90,
        inputs: () => {
          const number = roll(101, 9499)
          const roundTo = 10**roll(1, 3);
          return { number, roundTo };
        }
      },
      { 
        id: "q9xzd8m2", 
        description: "Place Value of 6 Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 60, 
        inputs: () => {
          return {
            number: roll(10000, 999999),
            places: [10**roll(3,5)]
          }
        }
      },
      { 
        id: "m7n9p2m6", 
        description: "Place Value of 7 Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 60, 
        inputs: () => {
          return {
            number: roll(1000000, 9999999),
            places: [10**roll(3,6)]
          }
        }
      },
    ]
  }
};
