import { roll } from './utils';

export const decimalsaddsub = {
  "Add/Subtract Decimals": {
    levels: [
      {
        id: "eeasdh1",
        description: "Add Ones",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(2, 5)+roll(2,5)/10;
            num2 = roll(2,5);
          } while (num1 % 1 === 0);
          return { num1, num2, operation: '+' };
        }
      },
      {
        id: "d4asdh1",
        description: "Add Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(2, 5)+roll(2,5)/10;
            num2 = roll(2,4)/10;
          } while (num1 % 1 === 0);
          return { num1, num2, operation: '+' };
        }
      },
      {
        id: "d5add1h1",
        description: "Add Ones or Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(2, 5)+roll(2,5)/10;
            num2 = roll(2,4)/[1,10][roll(0, 1)];
          } while (num1 % 1 === 0);
          return { num1, num2, operation: '+' };
        }
      },
      {
        id: "d5aagdh1",
        description: "Add Tenths to Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(3,9)/10;
            num2 = roll(3,9)/10;
          } while (num1 % 1 === 0);
          return { num1, num2, operation: '+' };
        }
      },
      {
        id: "d5zzgdh1",
        description: "Add Ones and Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(2,6) + roll(3,9)/10;
            num2 = roll(2,6) + roll(3,9)/10;
          } while (num1 % 1 === 0);
          return { num1, num2, operation: '+' };
        }
      },
      {
        id: "d5sub1h1", 
        description: "Subtract Ones",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(5, 9)+roll(5,9)/10;
            num2 = roll(2, 5);
          } while (num2 >= num1);
          return { num1, num2, operation: '-' };
        }
      },
      {
        id: "d5s441", 
        description: "Subtract Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(5, 9)+roll(5,9)/10;
            num2 = roll(2, 5)/10;
          } while (num2 >= num1);
          return { num1, num2, operation: '-' };
        }
      },      {
        id: "d5sgas1", 
        description: "Subtract Ones or Tenths",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          let num1, num2;
          do {
            num1 = roll(5, 9)+roll(5,9)/10;
            num2 = roll(1, 5)/[1,10][roll(0, 1)];
          } while (num2 >= num1);
          return { num1, num2, operation: '-' };
        }
      }
    ]
  }
};
