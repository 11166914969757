import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TextField } from '@mui/material';

// Helper function to find GCD
const gcd = (a, b) => {
  while (b) {
    const t = b;
    b = a % b;
    a = t;
  }
  return a;
};

// Display-only component for feedback dialog
export const MixedNumberOperationsDisplay = ({ fraction1, fraction2, operation, answer }) => {
  const getDisplayParts = (improperNum, denom) => {
    const whole = Math.floor(improperNum / denom);
    const properNum = improperNum % denom;
    return { whole, properNum };
  };

  const f1 = getDisplayParts(fraction1.numerator, fraction1.denominator);
  const f2 = getDisplayParts(fraction2.numerator, fraction2.denominator);
  const ans = getDisplayParts(answer.numerator, answer.denominator);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '2rem'
    }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '7rem',
        gap: '0.5rem',
        fontSize: '2rem',
        color: 'var(--text-primary)'
      }}>
        {f1.properNum === 0 ? (
          <span>{f1.whole}</span>
        ) : (
          <>
            <span>{f1.whole}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{f1.properNum}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--text-primary)',
                margin: '0.25rem 0'
              }} />
              <span>{fraction1.denominator}</span>
            </Box>
          </>
        )}
      </Box>
      <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>{operation}</Typography>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '7rem',
        gap: '0.5rem',
        fontSize: '2rem',
        color: 'var(--text-primary)'
      }}>
        {f2.properNum === 0 ? (
          <span>{f2.whole}</span>
        ) : (
          <>
            <span>{f2.whole}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{f2.properNum}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--text-primary)',
                margin: '0.25rem 0'
              }} />
              <span>{fraction2.denominator}</span>
            </Box>
          </>
        )}
      </Box>
      <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '7rem',
        gap: '0.5rem',
        fontSize: '2rem',
        color: 'var(--success-color)'
      }}>
        {ans.properNum === 0 ? (
          <span>{ans.whole}</span>
        ) : (
          <>
            <span>{ans.whole}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{ans.properNum}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--success-color)',
                margin: '0.25rem 0'
              }} />
              <span>{answer.denominator}</span>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const MixedNumberOperationsPractice = ({ level, onAnswer, inputs: inputsFn, onKeyDown }) => {
  const [wholeNumber, setWholeNumber] = useState('');
  const [numerator, setNumerator] = useState('');
  const [denominator, setDenominator] = useState('');
  const [problem, setProblem] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const firstInputRef = useRef(null);

  // Initial setup and reset when level changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const newProblem = inputsFn();
    setProblem(newProblem);
    resetInputs();
    setShouldFocus(true);
  }, [level, inputsFn]);

  // Focus first input after reset
  useEffect(() => {
    if (shouldFocus && firstInputRef.current) {
      firstInputRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const resetInputs = () => {
    setWholeNumber('');
    setNumerator('');
    setDenominator('');
  };

  const handleNumberChange = (e, setter) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setter(value);
  };

  const validateAnswer = () => {
    const userWhole = parseInt(wholeNumber || '0', 10);
    const userNumerator = parseInt(numerator || '0', 10);
    const userDenominator = parseInt(denominator || '0', 10);

    // If answer is a whole number (numerator = 0 or divides evenly)
    if (problem.answer.numerator % problem.answer.denominator === 0) {
      const expectedWhole = problem.answer.numerator / problem.answer.denominator;
      // Allow either empty fraction inputs or 0/1
      if (numerator === '' && denominator === '') {
        return userWhole === expectedWhole;
      }
      return userWhole === expectedWhole && userNumerator === 0 && userDenominator === 1;
    }

    // For mixed numbers, ensure proper fraction (numerator < denominator)
    if (userNumerator >= userDenominator || userDenominator === 0) {
      return false;
    }

    // Check if fraction is reduced
    if (userNumerator > 0 && userDenominator > 0) {
      if (gcd(userNumerator, userDenominator) !== 1) {
        return false;
      }
    }

    // Convert user's mixed number to improper fraction
    const userImproperNumerator = userWhole * userDenominator + userNumerator;

    return userImproperNumerator === problem.answer.numerator && 
           userDenominator === problem.answer.denominator;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isCorrect = validateAnswer();

    // Get the expected mixed number parts for feedback
    const expectedWhole = Math.floor(problem.answer.numerator / problem.answer.denominator);
    const expectedRemainder = problem.answer.numerator % problem.answer.denominator;

    // Reduce the fraction part
    let reducedNumerator = expectedRemainder;
    let reducedDenominator = problem.answer.denominator;
    if (reducedNumerator > 0) {
      const divisor = gcd(reducedNumerator, reducedDenominator);
      reducedNumerator = reducedNumerator / divisor;
      reducedDenominator = reducedDenominator / divisor;
    }

    // Format expected answer
    let expectedAnswer;
    if (reducedNumerator === 0) {
      expectedAnswer = expectedWhole === 0 ? '0' : `${expectedWhole}`;
    } else {
      expectedAnswer = expectedWhole === 0 ? 
        `${reducedNumerator}/${reducedDenominator}` : 
        `${expectedWhole} ${reducedNumerator}/${reducedDenominator}`;
    }

    // Format user answer
    let userAnswer;
    const userWhole = parseInt(wholeNumber || '0', 10);
    if (numerator === '' && denominator === '') {
      userAnswer = userWhole === 0 ? '0' : `${userWhole}`;
    } else {
      userAnswer = userWhole === 0 ? 
        `${numerator}/${denominator}` : 
        `${userWhole} ${numerator}/${denominator}`;
    }

    onAnswer(isCorrect ? 'correct' : userAnswer, expectedAnswer, {
      fraction1: problem.fraction1,
      fraction2: problem.fraction2,
      operation: problem.operation,
      answer: problem.answer
    });

    // Reset for next problem
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetInputs();
      setShouldFocus(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
    onKeyDown?.(e);
  };

  if (!problem) return null;

  const getDisplayParts = (improperNum, denom) => {
    const whole = Math.floor(improperNum / denom);
    const properNum = improperNum % denom;
    return { whole, properNum };
  };

  const f1 = getDisplayParts(problem.fraction1.numerator, problem.fraction1.denominator);
  const f2 = getDisplayParts(problem.fraction2.numerator, problem.fraction2.denominator);

  return (
    <div className="content-box">
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        padding: '2rem',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '7rem',
          gap: '0.5rem',
          fontSize: '2rem',
          color: 'var(--text-primary)'
        }}>
          {f1.properNum === 0 ? (
            <span>{f1.whole}</span>
          ) : (
            <>
              <span>{f1.whole}</span>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.25rem'
              }}>
                <span>{f1.properNum}</span>
                <Box sx={{ 
                  width: '2rem',
                  height: '2px',
                  backgroundColor: 'var(--text-primary)',
                  margin: '0.25rem 0'
                }} />
                <span>{problem.fraction1.denominator}</span>
              </Box>
            </>
          )}
        </Box>
        <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>{problem.operation}</Typography>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '7rem',
          gap: '0.5rem',
          fontSize: '2rem',
          color: 'var(--text-primary)'
        }}>
          {f2.properNum === 0 ? (
            <span>{f2.whole}</span>
          ) : (
            <>
              <span>{f2.whole}</span>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.25rem'
              }}>
                <span>{f2.properNum}</span>
                <Box sx={{ 
                  width: '2rem',
                  height: '2px',
                  backgroundColor: 'var(--text-primary)',
                  margin: '0.25rem 0'
                }} />
                <span>{problem.fraction2.denominator}</span>
              </Box>
            </>
          )}
        </Box>
        <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
        <form onSubmit={handleSubmit} style={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem'
        }}>
          <TextField
            className="number-input"
            value={wholeNumber}
            onChange={(e) => handleNumberChange(e, setWholeNumber)}
            onKeyDown={handleKeyDown}
            inputRef={firstInputRef}
            placeholder=""
            inputProps={{
              style: { textAlign: 'center' }
            }}
          />
          <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.5rem'
            }}>
              <TextField
                className="number-input"
                value={numerator}
                onChange={(e) => handleNumberChange(e, setNumerator)}
                onKeyDown={handleKeyDown}
                placeholder=""
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
              <Box sx={{ 
                width: '100%',
                height: '2px',
                backgroundColor: 'var(--text-primary)'
              }} />
              <TextField
                className="number-input"
                value={denominator}
                onChange={(e) => handleNumberChange(e, setDenominator)}
                onKeyDown={handleKeyDown}
                placeholder=""
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
            </Box>
        </form>
      </Box>
    </div>
  );
};

export default MixedNumberOperationsPractice;
