import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Profile from './components/auth/Profile';
import Dashboard from './components/dashboard/Dashboard';
import Practice from './components/math/Practice';
import Classes from './components/dashboard/student/Classes';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Footer from './components/global/Footer';
import Header from './components/global/Header';
import Home from './components/Home/Home';
import GradeLanding from './components/grades/GradeLanding';
import GradeTest from './components/grades/GradeTest';
import Quiz from './components/grades/Quiz';
import Privacy from './components/legal/Privacy';
import Terms from './components/legal/Terms';
import SkillLanding from './components/skills/SkillLanding';

function App() {
  return (
      <div className="app-container">
        <Router>
          <AuthProvider>
            <Header />
            <main className="main-content">
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/grade/:grade" element={<GradeLanding />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route 
                  path="/skills/:skillType" 
                  element={
                    <ProtectedRoute>
                      <SkillLanding />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/practice/:levelId" 
                  element={
                    <ProtectedRoute>
                      <Practice />
                    </ProtectedRoute>
                  } 
                />

                <Route 
                  path="/dashboard" 
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/classes" 
                  element={
                    <ProtectedRoute>
                      <Classes />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/profile" 
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/grade/:grade/:topic/:level" 
                  element={
                    <ProtectedRoute>
                      <Practice />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/grade/:grade/test" 
                  element={
                    <ProtectedRoute>
                      <GradeTest />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/quiz/:grade/:topic" 
                  element={
                    <ProtectedRoute>
                      <Quiz />
                    </ProtectedRoute>
                  } 
                />
              </Routes>
            </main>
            <Footer />
          </AuthProvider>
        </Router>
      </div>
  );
}

export default App;
