import { useState, useRef, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';

export default function StudentTable({ 
  students, 
  viewType,
  selectedGrade, 
  selectedTopic,
  selectedSkill,
  selectedSection,
  gradeContent, 
  skillContent,
  timeWindow 
}) {
  const [orderBy, setOrderBy] = useState('username');
  const [order, setOrder] = useState('asc');
  const tableContainerRef = useRef(null);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (orderBy !== property) {
      setOrder(property === 'username' ? 'asc' : 'desc');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const calculateGradeCompletion = (student, grade) => {
    if (!student || !gradeContent[grade]) return 0;
    
    let totalLevels = 0;
    let completedLevelsCount = 0;
    const levelBestTimes = student.levelBestTimes || {};

    Object.values(gradeContent[grade]).forEach((topicData) => {
      if (topicData.levels) {
        topicData.levels.forEach(level => {
          totalLevels++;
          if (levelBestTimes[level.id] !== undefined) {
            completedLevelsCount++;
          }
        });
      }
    });

    return totalLevels > 0 ? Math.round((completedLevelsCount / totalLevels) * 100) : 0;
  };

  const calculateSkillCompletion = (student, skill) => {
    if (!student || !skillContent[skill]) return 0;
    
    let totalLevels = 0;
    let completedLevelsCount = 0;
    const levelBestTimes = student.levelBestTimes || {};

    Object.values(skillContent[skill]).forEach((sectionData) => {
      if (sectionData.levels) {
        sectionData.levels.forEach(level => {
          totalLevels++;
          if (levelBestTimes[level.id] !== undefined) {
            completedLevelsCount++;
          }
        });
      }
    });

    return totalLevels > 0 ? Math.round((completedLevelsCount / totalLevels) * 100) : 0;
  };

  const calculateSectionCompletion = (student, section) => {
    if (!student || !skillContent[selectedSkill]) return 0;
    
    const sectionData = skillContent[selectedSkill][section];
    if (!sectionData?.levels) return 0;

    let completedLevelsCount = 0;
    const levelBestTimes = student.levelBestTimes || {};

    sectionData.levels.forEach(level => {
      if (levelBestTimes[level.id] !== undefined) {
        completedLevelsCount++;
      }
    });

    return Math.round((completedLevelsCount / sectionData.levels.length) * 100);
  };

  const calculateTopicCompletion = (student, topic) => {
    if (!student || !gradeContent[selectedGrade]) return 0;
    
    const topicData = gradeContent[selectedGrade][topic];
    if (!topicData?.levels) return 0;

    let completedLevelsCount = 0;
    const levelBestTimes = student.levelBestTimes || {};

    topicData.levels.forEach(level => {
      if (levelBestTimes[level.id] !== undefined) {
        completedLevelsCount++;
      }
    });

    return Math.round((completedLevelsCount / topicData.levels.length) * 100);
  };

  const calculateHourActivity = (student) => {
    if (!student?.activityIntervals || !student.activityIntervals.length) return 0;
    
    const now = Math.floor(Date.now() / 1000);
    const hourAgo = now - (60 * 60);
    
    let totalTime = 0;
    student.activityIntervals.forEach(interval => {
      // Only consider intervals that start within the last hour
      if (interval.start >= hourAgo) {
        const duration = interval.end - interval.start;
        totalTime += duration;
      }
    });
    
    return totalTime;
  };

  const getActivityTime = (student) => {
    if (!student) return 0;
    
    // Map timeWindow values to the pre-calculated stats
    switch (timeWindow?.value) {
      case 60 * 60 * 1000: // 1 hour
        return calculateHourActivity(student);
      case 24 * 60 * 60 * 1000: // 24 hours
        return student.last24h * 60;
      case 7 * 24 * 60 * 60 * 1000: // 7 days
        return student.last7d * 60;
      case 30 * 24 * 60 * 60 * 1000: // 30 days
        return student.lastMonth * 60;
      case 365 * 24 * 60 * 60 * 1000: // 1 year - use lifetime
        return student.lifetime * 60;
      default: // All time or any other value
        return student.lifetime * 60;
    }
  };

  const formatTime = (seconds) => {
    if (seconds === undefined) return '-';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatMinutes = (seconds) => {
    if (!seconds) return '0';
    return Math.round(seconds / 60);
  };

  const sortedStudents = useMemo(() => {
    const uniqueStudents = [...new Map(students.map(s => [s.id, s])).values()];
    return [...uniqueStudents].sort((a, b) => {
      if (orderBy === 'username') {
        return order === 'asc' 
          ? a.username.localeCompare(b.username)
          : b.username.localeCompare(a.username);
      }

      if (orderBy === 'activity_time') {
        const aTime = getActivityTime(a);
        const bTime = getActivityTime(b);
        return order === 'asc' ? aTime - bTime : bTime - aTime;
      }

      if (viewType === 'grades') {
        if (selectedTopic) {
          // Topic view: sort by level completion time
          const aTime = a.levelBestTimes?.[orderBy];
          const bTime = b.levelBestTimes?.[orderBy];
          
          // Always put undefined values at the bottom
          if (aTime === undefined && bTime === undefined) return 0;
          if (aTime === undefined) return 1;
          if (bTime === undefined) return -1;
          
          return order === 'asc' ? aTime - bTime : bTime - aTime;
        } else if (selectedGrade) {
          // Grade view: sort by topic completion percentage
          const aValue = calculateTopicCompletion(a, orderBy);
          const bValue = calculateTopicCompletion(b, orderBy);
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
          // All grades view: sort by grade completion percentage
          const aValue = calculateGradeCompletion(a, orderBy);
          const bValue = calculateGradeCompletion(b, orderBy);
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
      } else {
        if (selectedSection) {
          // Section view: sort by level completion time
          const aTime = a.levelBestTimes?.[orderBy];
          const bTime = b.levelBestTimes?.[orderBy];
          
          if (aTime === undefined && bTime === undefined) return 0;
          if (aTime === undefined) return 1;
          if (bTime === undefined) return -1;
          
          return order === 'asc' ? aTime - bTime : bTime - aTime;
        } else if (selectedSkill) {
          // Skill view: sort by section completion percentage
          const aValue = calculateSectionCompletion(a, orderBy);
          const bValue = calculateSectionCompletion(b, orderBy);
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
          // All skills view: sort by skill completion percentage
          const aValue = calculateSkillCompletion(a, orderBy);
          const bValue = calculateSkillCompletion(b, orderBy);
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
      }
    });
    //eslint-disable-next-line
  }, [students, orderBy, order, selectedGrade, selectedTopic, gradeContent, timeWindow]);

  if (students.length === 0) {
    return (
      <Typography className="no-students-message">
      </Typography>
    );
  }

  const renderGradeTableHeader = () => {
    if (selectedTopic) {
      // Topic view: show level columns with exercise descriptions
      const topicData = gradeContent[selectedGrade][selectedTopic];
      return topicData?.levels.map((level) => (
        <TableCell key={level.id} className="header-cell">
          <TableSortLabel
            active={orderBy === level.id}
            direction={orderBy === level.id ? order : 'desc'}
            onClick={() => handleSort(level.id)}
          >
            {level.description || `Exercise ${level.id}`}
          </TableSortLabel>
        </TableCell>
      ));
    } else if (selectedGrade) {
      // Grade view: show topic columns
      return Object.keys(gradeContent[selectedGrade] || {}).map(topic => (
        <TableCell key={topic} className="header-cell">
          <TableSortLabel
            active={orderBy === topic}
            direction={orderBy === topic ? order : 'desc'}
            onClick={() => handleSort(topic)}
          >
            {topic}
          </TableSortLabel>
        </TableCell>
      ));
    } else {
      // All grades view: show grade columns
      return Object.keys(gradeContent).map(grade => (
        <TableCell key={grade} className="header-cell">
          <TableSortLabel
            active={orderBy === grade}
            direction={orderBy === grade ? order : 'desc'}
            onClick={() => handleSort(grade)}
          >
            Grade {grade}
          </TableSortLabel>
        </TableCell>
      ));
    }
  };

  const renderSkillTableHeader = () => {
    if (selectedSection) {
      // Section view: show level columns with exercise descriptions
      const sectionData = skillContent[selectedSkill][selectedSection];
      return sectionData?.levels.map((level) => (
        <TableCell key={level.id} className="header-cell">
          <TableSortLabel
            active={orderBy === level.id}
            direction={orderBy === level.id ? order : 'desc'}
            onClick={() => handleSort(level.id)}
          >
            {level.description || `Exercise ${level.id}`}
          </TableSortLabel>
        </TableCell>
      ));
    } else if (selectedSkill) {
      // Skill view: show section columns
      return Object.keys(skillContent[selectedSkill] || {}).map(section => (
        <TableCell key={section} className="header-cell">
          <TableSortLabel
            active={orderBy === section}
            direction={orderBy === section ? order : 'desc'}
            onClick={() => handleSort(section)}
          >
            {section}
          </TableSortLabel>
        </TableCell>
      ));
    } else {
      // All skills view: show skill columns
      return Object.keys(skillContent).map(skill => (
        <TableCell key={skill} className="header-cell">
          <TableSortLabel
            active={orderBy === skill}
            direction={orderBy === skill ? order : 'desc'}
            onClick={() => handleSort(skill)}
          >
            {skill}
          </TableSortLabel>
        </TableCell>
      ));
    }
  };

  const renderTableHeader = () => {
    return viewType === 'grades' ? renderGradeTableHeader() : renderSkillTableHeader();
  };

  const renderGradeTableRow = (student) => {
    if (selectedTopic) {
      // Topic view: show level completion times
      return gradeContent[selectedGrade][selectedTopic]?.levels.map(level => (
        <TableCell key={level.id} className="table-cell">
          {formatTime(student.levelBestTimes?.[level.id])}
        </TableCell>
      ));
    } else if (selectedGrade) {
      // Grade view: show topic completion percentages
      return Object.keys(gradeContent[selectedGrade] || {}).map(topic => (
        <TableCell key={topic} className="table-cell">
          {calculateTopicCompletion(student, topic)}%
        </TableCell>
      ));
    } else {
      // All grades view: show grade completion percentages
      return Object.keys(gradeContent).map(grade => (
        <TableCell key={grade} className="table-cell">
          {calculateGradeCompletion(student, grade)}%
        </TableCell>
      ));
    }
  };

  const renderSkillTableRow = (student) => {
    if (selectedSection) {
      // Section view: show level completion times
      return skillContent[selectedSkill][selectedSection]?.levels.map(level => (
        <TableCell key={level.id} className="table-cell">
          {formatTime(student.levelBestTimes?.[level.id])}
        </TableCell>
      ));
    } else if (selectedSkill) {
      // Skill view: show section completion percentages
      return Object.keys(skillContent[selectedSkill] || {}).map(section => (
        <TableCell key={section} className="table-cell">
          {calculateSectionCompletion(student, section)}%
        </TableCell>
      ));
    } else {
      // All skills view: show skill completion percentages
      return Object.keys(skillContent).map(skill => (
        <TableCell key={skill} className="table-cell">
          {calculateSkillCompletion(student, skill)}%
        </TableCell>
      ));
    }
  };

  const renderTableRow = (student) => {
    return viewType === 'grades' ? renderGradeTableRow(student) : renderSkillTableRow(student);
  };

  return (
    <Box className="student-table-container">
      <TableContainer className="table-container" ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="header-cell frozen-column student-name">
                <TableSortLabel
                  active={orderBy === 'username'}
                  direction={orderBy === 'username' ? order : 'asc'}
                  onClick={() => handleSort('username')}
                >
                  Student
                </TableSortLabel>
              </TableCell>
              {!selectedTopic && (
                <TableCell className="header-cell frozen-column activity-time">
                  <TableSortLabel
                    active={orderBy === 'activity_time'}
                    direction={orderBy === 'activity_time' ? order : 'desc'}
                    onClick={() => handleSort('activity_time')}
                  >
                    Activity Time (min)
                  </TableSortLabel>
                </TableCell>
              )}
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStudents.map((student) => (
              <TableRow key={student.id} className="table-row">
                <TableCell className="table-cell frozen-column student-name">
                  {student.username}
                </TableCell>
                {!selectedTopic && (
                  <TableCell className="table-cell frozen-column activity-time">
                    {formatMinutes(getActivityTime(student))}
                  </TableCell>
                )}
                {renderTableRow(student)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
