import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import grade1Content from '../components/grades/content/grade1Content';
import grade2Content from '../components/grades/content/grade2Content';
import grade3Content from '../components/grades/content/grade3Content';
import grade4Content from '../components/grades/content/grade4Content';
import grade5Content from '../components/grades/content/grade5Content';
import { getPractices } from '../utils/skillUtils';

const gradeContents = {
  1: grade1Content,
  2: grade2Content,
  3: grade3Content,
  4: grade4Content,
  5: grade5Content
};

const useSkillProgress = (skillType) => {
  const [levelBestTimes, setLevelBestTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadProgress = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const times = userData.levelBestTimes || {};
          setLevelBestTimes(times);

          // Calculate completion percentage
          let totalLevels = 0;
          let completedCount = 0;

          // Get all practices for this skill type across all grades
          Object.values(gradeContents).forEach(gradeContent => {
            const practices = getPractices(gradeContent, skillType);
            practices.forEach(practice => {
              totalLevels++;
              if (times[practice.id] !== undefined) {
                completedCount++;
              }
            });
          });

          const percentage = totalLevels > 0 ? Math.round((completedCount / totalLevels) * 100) : 0;
          setCompletionPercentage(percentage);
        } else {
          setLevelBestTimes({});
          setCompletionPercentage(0);
        }
      } catch (error) {
        console.error('Error loading skill progress:', error);
        setLevelBestTimes({});
        setCompletionPercentage(0);
      }
      
      setLoading(false);
    };

    loadProgress();
  }, [currentUser, skillType]);

  const getLevelBestTime = (levelId) => {
    return levelBestTimes[levelId] !== undefined ? levelBestTimes[levelId] : null;
  };

  return {
    getLevelBestTime,
    loading,
    completionPercentage
  };
};

export default useSkillProgress;
