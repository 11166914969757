const calculateGCF = (a, b) => {
  while (b !== 0) {
    let t = b;
    b = a % b;
    a = t;
  }
  return a;
};

const calculateLCM = (a, b) => {
  return Math.abs(a * b) / calculateGCF(a, b);
};

const generateGcfPair = (min, max) => {
  let num1, num2;
  do {
    // Generate a GCF between min/4 and max/4
    const gcf = min + Math.floor(Math.random() * (max - min + 1));
    // Generate factors between 2-4
    const factor1 = 2 + Math.floor(Math.random() * 3);
    const factor2 = 2 + Math.floor(Math.random() * 3);
    num1 = gcf * factor1;
    num2 = gcf * factor2;
  } while (num1 === num2 || num1 < min || num2 < min || num1 > max || num2 > max);
  return [num1, num2];
};

const generateLcmPair = (min, max) => {
  let num1, num2;
  do {
    num1 = min + Math.floor(Math.random() * (max - min + 1));
    num2 = min + Math.floor(Math.random() * (max - min + 1));
  } while (
    num1 === num2 || 
    num1 < min || 
    num2 < min || 
    calculateLCM(num1, num2) > max
  );
  return [num1, num2];
};

export default function GcfLcmPractice({ inputs }) {
  const { numbers, mode } = inputs;
  
  return {
    problem: {
      question: `Find the ${mode === 'gcf' ? 'Greatest Common Factor' : 'Least Common Multiple'} of ${numbers[0]} and ${numbers[1]}`,
      answer: (mode === 'gcf' 
        ? calculateGCF(numbers[0], numbers[1])
        : calculateLCM(numbers[0], numbers[1])).toString()
    }
  };
}

// Export helper functions for use in content files
export { generateGcfPair, generateLcmPair };
