import { roll } from './utils';

export const threeDigitOps = {
  "Add/Subtract Three Digits": {
    levels: [
      { id: "s5d8f2h7", description: "Add 100", exerciseType: "VerticalOperationsPractice", timeLimit: 90, inputs: () => ({ 
        num1: roll(100, 899), 
        num2: 100, 
        operation: '+' 
      })},
      { id: "j4k7l2p9", description: "Subtract 100", exerciseType: "VerticalOperationsPractice", timeLimit: 90, inputs: () => ({ 
        num1: roll(200, 999), 
        num2: 100, 
        operation: '-' 
      })},
      { id: "w2e5r8t3", description: "Add Hundreds", exerciseType: "VerticalOperationsPractice", timeLimit: 90, inputs: () => ({ 
        num1: roll(100, 500), 
        num2: roll(2, 4) * 100, 
        operation: '+' 
      })},
      { id: "y6u9i3o7", description: "Subtract Hundreds", exerciseType: "VerticalOperationsPractice", timeLimit: 90, inputs: () => ({ 
        num1: roll(500, 999), 
        num2: roll(2, 4) * 100, 
        operation: '-' 
      })},
      { 
        id: "b9r4c7h2", 
        description: "Three Digits Plus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num2ones = roll(0, 9);
          const num1ones = roll(0, 9 - num2ones);
          const num2tens = roll(1, 9);
          const num1tens = roll(0, 9 - num2tens);
          const num1hundreds = roll(1, 8);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "f2d7l4p9", 
        description: "Three Digits Minus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num2ones = roll(0, 8);
          const num1ones = roll(num2ones + 1, 9);
          const num2tens = roll(1, 8);
          const num1tens = roll(num2tens + 1, 9);
          const num1hundreds = roll(1, 9);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      {
        id: "ee3p1n1",
        description: "Break Down Three-Digit Addition",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 600,
        inputs: () => {
          // First number (e.g., 358)
          const num1hundreds = roll(2, 7);
          const num1tens = roll(2, 7);
          const num1ones = roll(3, 9);
          const number = num1hundreds * 100 + num1tens * 10 + num1ones;
          
          // Second number (e.g., 129)
          const num2hundreds = roll(1, 8 - num1hundreds);
          const num2tens = roll(3, 9);
          const num2ones = roll(5, 9);
          const secondNumber = num2hundreds * 100 + num2tens * 10 + num2ones;
          
          return {
            number,
            secondNumber,
            operation: '+',
            isThreeDigit: true,
            split: {
              firstPart: num2hundreds * 100,  // hundreds part
              secondPart: num2tens * 10,      // tens part
              thirdPart: num2ones            // ones part
            },
            hints: ['hundreds', 'tens', 'ones']
          };
        }
      },
      { 
        id: "w4y7t2m5", 
        description: "Three Digits Plus Two Digits (With Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1ones = roll(0, 9);
          const num2ones = roll(9 - num1ones + 1, 9); // Forces ones regrouping
          const num1tens = roll(0, 8);
          const num2tens = roll(9 - num1tens + 1, 9); // Forces tens regrouping
          const num1hundreds = roll(1, 7);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "w4y7dzdm5", 
        description: "Three Digits Plus Three Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1ones = roll(0, 9);
          const num2ones = roll(0,9)
          const num1tens = roll(0, 8);
          const num2tens = roll(0,9);
          const num1hundreds = roll(1, 7);
          const num2hundreds = roll(1, 8 - num1hundreds)
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2hundreds * 100 + num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      {
        id: "ee3p1n2",
        description: "Break Down Three-Digit Subtraction",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 600,
        inputs: () => {
          
          const num2ones = roll(4, 9);
          const num1ones = roll(1, 7);
          
          const num2tens = roll(4, 9);
          const num1tens = roll(1, 7);
          
          const num2hundreds = roll(1, 7);
          const num1hundreds = roll(num2hundreds + 1, 9);
          
          const number = num1hundreds * 100 + num1tens * 10 + num1ones;
          const secondNumber = num2hundreds * 100 + num2tens * 10 + num2ones;
          
          return {
            number,
            secondNumber,
            operation: '-',
            isThreeDigit: true,
            split: {
              firstPart: num2hundreds * 100,  // hundreds part
              secondPart: num2tens * 10,      // tens part
              thirdPart: num2ones            // ones part
            },
            hints: ['hundreds', 'tens', 'ones']
          };
        }
      },
      { 
        id: "j6l2p8f4", 
        description: "Three Digits Minus Two Digits (With Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1ones = roll(0, 8);
          const num2ones = roll(num1ones + 1, 9); // Forces ones borrowing
          const num1tens = roll(0, 8);
          const num2tens = roll(num1tens + 1, 9); // Forces tens borrowing
          const num1hundreds = roll(2, 9);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      { 
        id: "j6lgasf4", 
        description: "Three Digits Minus Three Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 300,
        inputs: () => {
          const num1ones = roll(0, 9);
          const num2ones = roll(0, 9);
          const num1tens = roll(0, 8);
          const num2tens = roll(0,8);
          const num1hundreds = roll(2, 7);
          const num2hundreds = roll(1, num1hundreds - 1);

          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2hundreds*100 + num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      }
    ]
  }
};
