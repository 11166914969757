import { roll } from './utils';

export const decimals = {
  "Place Value (Tenths/Hundredths)": {
    levels: [
      { 
        id: "d4n1m2p3", 
        description: "Identify Tenths Place", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 99);
          const tenths = roll(0, 9);
          return {
            number: whole + tenths/10,
            places: [0.1]
          };
        }
      },
      { id: "d4c1m2p3", description: "Count Up by Tenths from 0.1", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: 0.1, 
        increment: 0.1,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d44gm2p3", description: "Count Down by Tenths from 1", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: 1, 
        increment: -0.1,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4cgadp3", description: "Count Up by Tenths", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: roll(0,9) + roll(2,9)/10, 
        increment: 0.1,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4cggg3", description: "Count Down by Tenths", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: roll(1,9) + roll(2,9)/10, 
        increment: -0.1,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4n1m2p7", description: "Convert Between Ones and Tenths", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'tenth'];
        const fromIndex = roll(0, 1);
        let toIndex;
        do {
          toIndex = roll(0, 1);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'one') {
          value = roll(2, 9);
        } else {
          value = roll(2, 9) * 10;
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'decimal'
        };
      }},
      { 
        id: "d4n1m2p4", 
        description: "Identify Tenths or Hundredths Place", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 99);
          const hundredths = roll(0, 99);
          return {
            number: whole + hundredths/100,
            places: [0.1, 0.01][roll(0,1)]
          };
        }
      },
      { 
        id: "d4n1m2p4", 
        description: "Identify Places From Hundreds to Hundredths", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(112, 998);
          const hundredths = roll(0, 99);
          const place = 10**roll(-2, 2)
          return {
            number: whole + hundredths/100,
            places: place
          };
        }
      },
      { id: "d4c3m2p3", description: "Count Up by Hundredths from 0.01", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: 0.01, 
        increment: 0.01,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4cgap3", description: "Count Down by Hundredths from 0.1", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: 0.1, 
        increment: -0.01,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4c3gzp3", description: "Count Up by Hundredths", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: (roll(1,9)*100+90+roll(3,6))/100,
        increment: 0.01,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4cfazp3", description: "Count Down by Hundredths", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: (roll(1,9)*100+roll(3,6))/100,
        increment: -0.01,
        allowedFormats: ['decimal', 'fraction']
      }},
      { id: "d4n1zap7", description: "Convert Between Ones and Hundredth", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'hundredth'];
        const fromIndex = roll(0, 1);
        let toIndex;
        do {
          toIndex = roll(0, 1);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'one') {
          value = roll(1, 9); // Converting to tenths will give whole number (1 -> 10 tenths)
        } else {
          value = roll(1, 9) * 100; // Ensure tenths convert to whole ones (20 tenths -> 2)
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'decimal'
        };
      }},
      { id: "d4n1m2p8", description: "Convert Between Tenths and Hundredths", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['tenth', 'hundredth'];
        const fromIndex = roll(0, 1);
        let toIndex;
        do {
          toIndex = roll(0, 1);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'tenth') {
          value = roll(1, 9); // Converting to hundredths will give whole number (1 tenth -> 10 hundredths)
        } else {
          value = roll(1, 9) * 10; // Ensure hundredths convert to whole tenths (20 hundredths -> 2 tenths)
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'decimal'
        };
      }},
      { id: "d4n1m2p9", description: "Convert Between Ones, Tenths, and Hundredths", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'tenth', 'hundredth'];
        const fromUnitIndex = roll(0, 2);
        const fromUnit = units[fromUnitIndex];
        
        // Ensure toUnit is different from fromUnit
        let toUnitIndex;
        do {
          toUnitIndex = roll(0, 2);
        } while (toUnitIndex === fromUnitIndex);
        const toUnit = units[toUnitIndex];
        
        let value;
        if (fromUnit === 'one') {
          // For ones, just generate 1-9
          value = roll(1, 9);
        } else if (fromUnit === 'tenth') {
          if (toUnit === 'one') {
            // Converting to ones needs at least 10 tenths
            // Generate 10-90 tenths
            value = roll(1, 9) * 10;
          } else {
            // Converting to hundredths
            // Generate 1-9 * 10^roll(0,1) tenths
            value = roll(1, 9) * Math.pow(10, roll(0, 1));
          }
        } else { // hundredth
          if (toUnit === 'one') {
            // Converting to ones needs at least 100 hundredths
            value = roll(1, 9) * 100;
          } else if (toUnit === 'tenth') {
            // Converting to tenths needs at least 10 hundredths
            // Generate 10-90 hundredths
            value = roll(1, 9) * 10;
          } else {
            // This case shouldn't happen since we ensure different units
            value = roll(1, 9);
          }
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'decimal'
        };
      }},
      { id: "d4cmp1t1", description: "Compare Decimals (Tenths)", exerciseType: "ComparingDecimalsPractice", timeLimit: 90, inputs: () => {
        const whole1 = roll(0, 9);
        const whole2 = roll(0, 9);
        const tenth1 = roll(0, 9);
        let tenth2;
        do {
          tenth2 = roll(0, 9);
        } while (tenth2 === tenth1 && whole1 === whole2);
        
        return {
          decimal1: whole1 + tenth1/10,
          decimal2: whole2 + tenth2/10
        };
      }},
      { id: "d4cmp1t2", description: "Compare Decimals (Hundredths)", exerciseType: "ComparingDecimalsPractice", timeLimit: 90, inputs: () => {
        const whole1 = roll(0, 9);
        const whole2 = roll(0, 9);
        const hundredth1 = roll(0, 99);
        let hundredth2;
        do {
          hundredth2 = roll(0, 99);
        } while (hundredth2 === hundredth1 && whole1 === whole2);
        
        return {
          decimal1: whole1 + hundredth1/100,
          decimal2: whole2 + hundredth2/100
        };
      }},
      {
        id: "r4nd5q5t",
        description: "Round Decimals to the Nearest Tenth",
        exerciseType: "RoundingPractice",
        timeLimit: 60,
        inputs: () => ({
          number: Number((roll(1, 99) / 100).toFixed(2)), // Generate number with 2 decimal places
          roundTo: 0.1
        })
      },
      {
        id: "r4nd6aaa",
        description: "Rounding Hundreths Place Decimals",
        exerciseType: "RoundingPractice",
        timeLimit: 60,
        inputs: () => ({
          number: roll(1,9)*10 + roll(1,9) + roll(1,9)*10**-1 + roll(1,9)*10**-2,
          roundTo: 10**roll(-1,0)
        })
      },
    ]
  }
};
