import React from 'react';
import { Box, Typography } from '@mui/material';
import { calculateCarries, calculateIntermediates, getResultLength } from './Generator';
import './styles.css';

const Display = ({ num1, num2, operation, answer, carries: passedCarries }) => {
  const { carries, strikeDigits } = passedCarries || calculateCarries(num1, num2, operation);
  const intermediates = operation === '*' ? calculateIntermediates(num1, num2) : [];
  const num1Str = num1.toString();
  const num2Str = num2.toString();
  const resultLength = getResultLength(num1, num2, operation);

  return (
    <Box className="vertical-operation feedback-vertical-operation">
      <Box className="operation-numbers">
        {/* Carry row */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.25rem', marginBottom: '2px' }}>
          {/* Empty space for leftmost position (only for subtraction) */}
          {operation === '-' && <Box className="number carry-number" />}
          {/* Carries */}
          {carries.map((carry, i) => (
            <Box key={`carry-${i}`} className="number carry-number">
              {carry}
            </Box>
          ))}
        </Box>

        {/* First number */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
          {num1Str.padStart(resultLength, ' ').split('').map((digit, i) => (
            <Box 
              key={`num1-${i}`} 
              className={`number ${strikeDigits[i] ? 'strike' : ''}`}
            >
              {digit === ' ' ? '' : digit}
            </Box>
          ))}
        </Box>

        {/* Operation and second number */}
        <Box className="operation-line-container">
          <Typography className="operation-symbol">
            {operation === '*' ? '×' : operation}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {num2Str.padStart(resultLength, ' ').split('').map((digit, i) => (
              <Box key={`num2-${i}`} className="number">
                {digit === ' ' ? '' : digit}
              </Box>
            ))}
          </Box>
        </Box>

        <div className="operation-line" />

        {/* Intermediate steps for multiplication */}
        {operation === '*' && num2Str.length > 1 && intermediates.length > 0 && (
          <>
            {/* First row */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
              {intermediates[0].map((digit, digitIndex) => (
                <Box key={`intermediate-0-${digitIndex}`} className="number intermediate-number">
                  {digit === ' ' ? '' : digit}
                </Box>
              ))}
            </Box>
            {/* Second row - shifted left */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
              {intermediates[1].map((digit, digitIndex) => (
                <Box key={`intermediate-1-${digitIndex}`} className="number intermediate-number">
                  {digit === ' ' ? '' : digit}
                </Box>
              ))}
              <Box className="number intermediate-number" />
            </Box>
            <div className="operation-line" />
          </>
        )}

        {/* Answer */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {answer.split(',')[0].padStart(resultLength, ' ').split('').map((digit, i) => (
            <Box key={`answer-${i}`} className="number answer-number">
              {digit === ' ' ? '' : digit}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Display;
