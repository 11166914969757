import React, { useState, useMemo } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

const formatDecimal = (num) => {
    // First round to 5 decimal places to avoid floating point issues
    const rounded = Math.round(num * 100000) / 100000;
    // Convert to string and remove trailing zeros
    return rounded.toString().replace(/\.?0+$/, '');
};

const ComparingDecimalsPractice = React.memo(({ inputs: inputsFn, onAnswer, onKeyDown }) => {
    const [inputValue, setInputValue] = useState('');
    //eslint-disable-next-line
    const [problem, setProblem] = useState(() => {
        const { decimal1, decimal2 } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
        
        let correctAnswer;
        if (decimal1 < decimal2) correctAnswer = '<';
        else if (decimal1 > decimal2) correctAnswer = '>';
        else correctAnswer = '=';

        return {
            decimal1,
            decimal2,
            correctAnswer
        };
    });
    
    const choices = useMemo(() => ['<', '>', '='], []);

    const handleKeyDown = (e) => {
        // Handle number keys (1-3)
        const num = parseInt(e.key);
        if (num >= 1 && num <= choices.length) {
            setInputValue(choices[num - 1]);
        }
        
        // Handle Enter key
        if (e.key === 'Enter' && inputValue && onKeyDown) {
            e.preventDefault();
            // Create a synthetic event with the current input value
            const syntheticEvent = {
                ...e,
                target: { value: inputValue }
            };
            onKeyDown(syntheticEvent);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!inputValue) return;
        
        if (onKeyDown) {
            const syntheticEvent = {
                ...e,
                target: { value: inputValue }
            };
            onKeyDown(syntheticEvent);
        }
    };

    const decimalStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '120px' // Wider to accommodate decimal numbers
    };

    const operatorStyle = {
        fontSize: '2rem',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    };

    return (
        <div className="content-box">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
                <div style={decimalStyle}>
                    {formatDecimal(problem.decimal1)}
                </div>
                
                <div style={operatorStyle}>
                    {inputValue || ' '}
                </div>

                <div style={decimalStyle}>
                    {formatDecimal(problem.decimal2)}
                </div>
            </div>

            <form onSubmit={handleSubmit} style={{ marginTop: '2rem' }}>
                <RadioGroup
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="radio-group"
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '2rem' }}
                >
                    {choices.map((choice, index) => (
                        <FormControlLabel
                            key={index}
                            value={choice}
                            control={<Radio />}
                            label={`${index + 1}. ${choice}`}
                            className="radio-option"
                        />
                    ))}
                </RadioGroup>
            </form>
        </div>
    );
});

export default ComparingDecimalsPractice;
