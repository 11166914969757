export default function HalvesPractice({ inputs }) {
  // If inputs is a function, call it to get the number
  const { number } = typeof inputs === 'function' ? inputs() : inputs;
  
  // For odd numbers, provide both formats as valid answers
  const answer = number % 2 === 0 
    ? (number / 2).toString()
    : number === 1
      ? ["1/2", "0.5"]  // Special case for half of 1
      : [
          Math.floor(number / 2) + " 1/2",  // fractional format
          (number / 2).toString()           // decimal format
        ];

  return {
    problem: {
      question: `Half of ${number} is _`,
      answer: answer
    },
    validateAnswer: input => {
      if (!input) return false;
      input = input.trim();
      
      // For even numbers
      if (number % 2 === 0) {
        return /^\d+$/.test(input);
      }
      
      // Special case for half of 1
      if (number === 1) {
        return /^1\/2$/.test(input) || /^0\.5$/.test(input);
      }
      
      // For other odd numbers, accept either "n 1/2" or "n.5" format
      return /^\d+\s1\/2$/.test(input) || /^\d*\.5$/.test(input);
    }
  };
}
