import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { AddSubtractRow, MultiplyRow, ResultRow, FinalRow } from './equivalent-expression/InputRows';
import { generateProblem, getEmptyAnswers } from './equivalent-expression/Generator';
import { validateAnswers, calculateCorrectSteps } from './equivalent-expression/Validator';
import EquivalentExpressionDisplay from './equivalent-expression/Display';
import './equivalent-expression/styles.css';

const EquivalentExpressionPractice = ({ inputs: inputsFn, onAnswer, onKeyDown }) => {
  const [answers, setAnswers] = useState(getEmptyAnswers());
  const [problem, setProblem] = useState(null);
  const firstInputRef = useRef(null);

  const generateNewProblem = () => {
    setProblem(generateProblem(inputsFn));
    setAnswers(getEmptyAnswers());
    firstInputRef.current?.focus();
  };

  useEffect(() => {
    if (!problem) {
      generateNewProblem();
    }
  }, [inputsFn]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (value, field) => {
    if (/^-?\d*$/.test(value)) {
      setAnswers(prev => ({ ...prev, [field]: value }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    const { number, secondNumber, operation } = problem;
    const isCorrect = validateAnswers(answers, problem);
    const expectedAnswer = operation === '+' 
      ? number + secondNumber 
      : operation === '-' 
        ? number - secondNumber 
        : number * secondNumber;

    // Always show feedback first
    if (isCorrect) {
      onAnswer('correct', expectedAnswer.toString(), null);
    } else {
      // Pass all user answers for feedback
      onAnswer('incorrect', expectedAnswer.toString(), {
        number,
        secondNumber,
        operation,
        answer: expectedAnswer.toString(),
        userSteps: answers,
        steps: calculateCorrectSteps(problem)
      });
    }

    // Always generate new problem after feedback
    generateNewProblem();
  };

  if (!problem) return null;

  const { number, operation, secondNumber, hints = [], isThreeDigit } = problem;
  const sizeClass = isThreeDigit ? 'three-digit' : 'two-digit';

  return (
    <div className="content-box">
      <Typography className="practice-title">
        Break down the {
          operation === '×' ? 'multiplication' : 
          operation === '+' ? 'addition' :
          'subtraction'
        } problem
      </Typography>
      <form onSubmit={handleSubmit} className="expression-form">
        <div className={`expression-container ${sizeClass}`}>
          {/* Line 1: Break down the problem */}
          <div className="expression-line">
            <div className="expression-row">
              <Typography variant="h5" component="span" className="expression-text-large">
                {`${number} ${operation} ${secondNumber} = `}
              </Typography>
              {operation === '+' || operation === '-' ? (
                <AddSubtractRow
                  operation={operation}
                  answers={answers}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  firstInputRef={firstInputRef}
                  hints={hints}
                  isThreeDigit={isThreeDigit}
                />
              ) : (
                <MultiplyRow
                  secondNumber={secondNumber}
                  answers={answers}
                  onInputChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  firstInputRef={firstInputRef}
                  hints={hints}
                  isThreeDigit={isThreeDigit}
                  steps={calculateCorrectSteps(problem)}
                />
              )}
            </div>
          </div>

          {/* Line 2: Show operation results */}
          <div className="expression-row">
            <ResultRow
              number={number}
              operation={operation}
              secondNumber={secondNumber}
              answers={answers}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              isThreeDigit={isThreeDigit}
              steps={calculateCorrectSteps(problem)}
            />
          </div>

          {/* Line 3: Final result */}
          <div className="expression-row">
            <FinalRow
              number={number}
              operation={operation}
              secondNumber={secondNumber}
              answers={answers}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export { EquivalentExpressionDisplay };
export default EquivalentExpressionPractice;
