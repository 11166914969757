import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TextField } from '@mui/material';

// Display-only component for feedback dialog
export const ImproperMixedNumberDisplay = ({ wholeNumber, numerator, denominator, mode, properNumerator, improperNumerator }) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '2rem'
    }}>
      {mode === 'toMixed' ? (
        <>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.25rem',
            fontSize: '2rem',
            color: 'var(--text-primary)'
          }}>
              <span>{numerator}</span>
            <Box sx={{ 
              width: '2rem',
              height: '2px',
              backgroundColor: 'var(--text-primary)',
              margin: '0.25rem 0'
            }} />
            <span>{denominator}</span>
          </Box>
          <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            fontSize: '2rem',
            color: 'var(--success-color)'
          }}>
            <span>{wholeNumber}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{numerator % denominator}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--success-color)',
                margin: '0.25rem 0'
              }} />
              <span>{denominator}</span>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            fontSize: '2rem',
            color: 'var(--text-primary)'
          }}>
            <span>{wholeNumber}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{numerator}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--text-primary)',
                margin: '0.25rem 0'
              }} />
              <span>{denominator}</span>
            </Box>
          </Box>
          <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '0.25rem',
            fontSize: '2rem',
            color: 'var(--success-color)'
          }}>
            <span>{improperNumerator}</span>
            <Box sx={{ 
              width: '2rem',
              height: '2px',
              backgroundColor: 'var(--success-color)',
              margin: '0.25rem 0'
            }} />
            <span>{denominator}</span>
          </Box>
        </>
      )}
    </Box>
  );
};

const ImproperMixedNumberPractice = ({ level, onAnswer, inputs: inputsFn, onKeyDown }) => {
  const [wholeNumber, setWholeNumber] = useState('');
  const [numerator, setNumerator] = useState('');
  const [denominator, setDenominator] = useState('');
  const [problem, setProblem] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const firstInputRef = useRef(null);

  // Initial setup and reset when level changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const newProblem = inputsFn();
    setProblem(newProblem);
    resetInputs();
    setShouldFocus(true);
  }, [level, inputsFn]);

  // Focus first input after reset
  useEffect(() => {
    if (shouldFocus && firstInputRef.current) {
      firstInputRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const resetInputs = () => {
    setWholeNumber('');
    setNumerator('');
    setDenominator('');
  };

  const handleNumberChange = (e, setter) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setter(value);
  };

  const validateAnswer = () => {
    if (problem.mode === 'toMixed') {
      // Convert improper fraction to mixed number
      const expectedWhole = Math.floor(problem.numerator / problem.denominator);
      const expectedRemainder = problem.numerator % problem.denominator;
      
      const userWhole = parseInt(wholeNumber || '0', 10);
      const userNumerator = parseInt(numerator || '0', 10);
      
      return userWhole === expectedWhole && 
             userNumerator === expectedRemainder && 
             parseInt(denominator) === problem.denominator;
    } else {
      // Convert mixed number to improper fraction
      const expectedNumerator = problem.wholeNumber * problem.denominator + problem.properNumerator;
      
      const userNumerator = parseInt(numerator || '0', 10);
      
      return userNumerator === expectedNumerator && 
             parseInt(denominator) === problem.denominator;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isCorrect = validateAnswer();

    if (problem.mode === 'toMixed') {
      const expectedWhole = Math.floor(problem.numerator / problem.denominator);
      const expectedRemainder = problem.numerator % problem.denominator;
      
      const expectedAnswer = `${expectedWhole} ${expectedRemainder}/${problem.denominator}`;
      const userAnswer = `${wholeNumber} ${numerator}/${denominator}`;

      onAnswer(isCorrect ? 'correct' : userAnswer, expectedAnswer, {
        wholeNumber: expectedWhole,
        numerator: problem.numerator,
        denominator: problem.denominator,
        mode: 'toMixed',
        properNumerator: expectedRemainder
      });
    } else {
      const expectedNumerator = problem.wholeNumber * problem.denominator + problem.properNumerator;
      
      const expectedAnswer = `${expectedNumerator}/${problem.denominator}`;
      const userAnswer = `${numerator}/${denominator}`;

      onAnswer(isCorrect ? 'correct' : userAnswer, expectedAnswer, {
        wholeNumber: problem.wholeNumber,
        numerator: problem.properNumerator,
        denominator: problem.denominator,
        mode: 'toImproper',
        improperNumerator: expectedNumerator
      });
    }

    // Reset for next problem
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetInputs();
      setShouldFocus(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  if (!problem) return null;

  return (
    <div className="content-box">
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        padding: '2rem',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto'
      }}>
        {problem.mode === 'toMixed' ? (
          <>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem',
              fontSize: '2rem',
              color: 'var(--text-primary)'
            }}>
              <span>{problem.numerator}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--text-primary)',
                margin: '0.25rem 0'
              }} />
              <span>{problem.denominator}</span>
            </Box>
            <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
          </>
        ) : (
          <>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              fontSize: '2rem',
              color: 'var(--text-primary)'
            }}>
              <span>{problem.wholeNumber}</span>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.25rem'
              }}>
                <span>{problem.properNumerator}</span>
                <Box sx={{ 
                  width: '2rem',
                  height: '2px',
                  backgroundColor: 'var(--text-primary)',
                  margin: '0.25rem 0'
                }} />
                <span>{problem.denominator}</span>
              </Box>
            </Box>
            <Typography variant="h4" sx={{ color: 'var(--text-primary)' }}>=</Typography>
          </>
        )}
        <form onSubmit={handleSubmit} style={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem'
        }}>
        {problem.mode === 'toMixed' && (
          <TextField
            className="number-input"
            value={wholeNumber}
            onChange={(e) => handleNumberChange(e, setWholeNumber)}
            onKeyDown={handleKeyDown}
            inputRef={firstInputRef}
            placeholder=""
            inputProps={{
              style: { textAlign: 'center' }
            }}
          />
        )}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
          <TextField
            className="number-input"
            value={numerator}
            onChange={(e) => handleNumberChange(e, setNumerator)}
            onKeyDown={handleKeyDown}
            inputRef={problem.mode === 'toImproper' ? firstInputRef : null}
            placeholder=""
            inputProps={{
              style: { textAlign: 'center' }
            }}
          />
          <Box sx={{ 
            width: '100%',
            height: '2px',
            backgroundColor: 'var(--text-primary)'
          }} />
          <TextField
            className="number-input"
            value={denominator}
            onChange={(e) => handleNumberChange(e, setDenominator)}
            onKeyDown={handleKeyDown}
            placeholder=""
            inputProps={{
              style: { textAlign: 'center' }
            }}
          />
        </Box>
        </form>
      </Box>
    </div>
  );
};

export default ImproperMixedNumberPractice;
