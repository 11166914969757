import { Paper, Typography, LinearProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSkillProgress from '../../../hooks/useSkillProgress';
import '../../../styles/grades/gradelanding.css';

const skillCategories = {
  placeValue: {
    title: 'Place Value',
    type: 'PlaceValue'
  },
  addSubtract: {
    title: 'Add/Subtract',
    type: 'AddSubtract'
  },
  multiplyDivide: {
    title: 'Multiply/Divide',
    type: 'MultiplyDivide'
  },
  factors: {
    title: 'Factors',
    type: 'Factors'
  },
  fractions: {
    title: 'Fractions',
    type: 'Fractions'
  }
};

export default function SkillPractice() {
  const navigate = useNavigate();

  const getSkillUrl = (type) => type.toLowerCase();

  const handleSkillClick = (category) => {
    navigate(`/skills/${getSkillUrl(category.type)}`);
  };

  // Call useSkillProgress for each skill type at the top level
  const placeValueProgress = useSkillProgress('PlaceValue');
  const addSubtractProgress = useSkillProgress('AddSubtract');
  const multiplyDivideProgress = useSkillProgress('MultiplyDivide');
  const factorsProgress = useSkillProgress('Factors');
  const fractionsProgress = useSkillProgress('Fractions');

  const getProgress = (category) => {
    switch(category.title) {
      case 'Place Value':
        return placeValueProgress.completionPercentage;
      case 'Add/Subtract':
        return addSubtractProgress.completionPercentage;
      case 'Multiply/Divide':
        return multiplyDivideProgress.completionPercentage;
      case 'Factors':
        return factorsProgress.completionPercentage;
      case 'Fractions':
        return fractionsProgress.completionPercentage;
      default:
        return 0;
    }
  };

  return (
    <Paper className="dashboard-card grade-progress-card">
      <Typography variant="h6" className="card-title">
        Skill Progress
      </Typography>
      <Box className="grade-progress-container grade-progress-grid">
        {Object.entries(skillCategories).map(([key, category]) => (
          <Box 
            key={key}
            className="grade-progress-item"
            onClick={() => handleSkillClick(category)}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleSkillClick(category);
              }
            }}
          >
            <Box className="grade-progress-header">
              <Typography variant="subtitle1" className="grade-name">
                {category.title}
              </Typography>
              <Typography variant="body2" className="grade-percentage">
              {getProgress(category)}%
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={getProgress(category)} 
              className="grade-progress-bar"
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
}
