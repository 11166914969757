import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography } from '@mui/material';

const DivisionWithRemainderPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn, onKeyDown }) => {
  const [quotient, setQuotient] = useState('');
  const [remainder, setRemainder] = useState('');
  const [problem, setProblem] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const quotientRef = useRef(null);
  const remainderRef = useRef(null);

  // Initial setup and reset when level changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const newProblem = inputsFn();
    setProblem(newProblem);
    setQuotient('');
    setRemainder('');
    setShouldFocus(true);
  }, [level, inputsFn]); // Only depend on level changes, not currentProgress

  // Focus quotient input after reset
  useEffect(() => {
    if (shouldFocus && quotientRef.current) {
      quotientRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const handleQuotientChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setQuotient(value);
  };

  const handleRemainderChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setRemainder(value);
  };

  const validateAnswer = () => {
    const q = parseInt(quotient || '0', 10);
    const r = parseInt(remainder || '0', 10);
    const { num1, num2 } = problem;

    // Check if quotient and remainder give the correct result
    // num1 = (quotient × num2) + remainder
    return (q * num2 + r === num1) && (r < num2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { num1, num2 } = problem;
    const expectedQuotient = Math.floor(num1 / num2);
    const expectedRemainder = num1 % num2;
    const expectedAnswer = `${expectedQuotient} R ${expectedRemainder}`;
    const userAnswer = `${quotient} R ${remainder}`;
    const isCorrect = validateAnswer();

    onAnswer(isCorrect ? 'correct' : userAnswer, expectedAnswer, {
      problem: {
        question: `${num1} ÷ ${num2} =`
      }
    });

    // Reset for next problem regardless of correctness
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      setQuotient('');
      setRemainder('');
      setShouldFocus(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  if (!problem) return null;

  return (
    <div className="content-box">
      <Typography variant="h4" className="practice-title">
        {problem.num1} ÷ {problem.num2}
      </Typography>
      <form onSubmit={handleSubmit} style={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        margin: '2rem 0'
      }}>
        <TextField
          className="number-input"
          value={quotient}
          onChange={handleQuotientChange}
          onKeyDown={handleKeyDown}
          inputRef={quotientRef}
          autoFocus
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
        />
        <Typography variant="h5" style={{ color: 'var(--text-primary)' }}>R</Typography>
        <TextField
          className="number-input"
          value={remainder}
          onChange={handleRemainderChange}
          onKeyDown={handleKeyDown}
          inputRef={remainderRef}
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
        />
      </form>
    </div>
  );
};

export default DivisionWithRemainderPractice;
