// Helper function to extract practices from grade content
export const getPractices = (gradeContent, skillType) => {
  const practices = [];
  Object.entries(gradeContent).forEach(([sectionName, section]) => {
    section.levels?.forEach(level => {
      const sectionNameLower = sectionName.toLowerCase();
      
      // Check content area names based on skill type
      if (skillType === 'PlaceValue' && (sectionNameLower.includes('place value'))) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'AddSubtract' && (
        sectionNameLower.includes('addition') || 
        sectionNameLower.includes('subtract') ||
        sectionNameLower.includes('add')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'MultiplyDivide' && (
        sectionNameLower.includes('multiply') || 
        sectionNameLower.includes('divide') ||
        sectionNameLower.includes('multiplication') || 
        sectionNameLower.includes('division')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'Factors' && (
        sectionNameLower.includes('even') ||
        sectionNameLower.includes('doubles') ||
        sectionNameLower.includes('halves') ||
        sectionNameLower.includes('multiple') ||
        sectionNameLower.includes('factor')
      )) {
        practices.push({ ...level, sectionName });
      }
      else if (skillType === 'Fractions' && (
        sectionNameLower.includes('fraction')
      )) {
        practices.push({ ...level, sectionName });
      }
      // For other skills, check exercise types
      else if (skillType !== 'PlaceValue' && skillType !== 'AddSubtract' && 
               skillType !== 'MultiplyDivide' && skillType !== 'Factors' && 
               skillType !== 'Fractions') {
        const exerciseTypes = Array.isArray(skillType) ? skillType : [skillType];
        const matchesType = exerciseTypes.some(type => level.exerciseType === `${type}Practice`);
        if (matchesType) {
          practices.push({ ...level, sectionName });
        }
      }
    });
  });
  return practices;
};
