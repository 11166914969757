import { calculateCarries, calculateIntermediates, getResultLength } from './Generator';

export const validateAnswers = (answers, problem) => {
  const { num1, num2, operation } = problem;

  // For multi-digit multiplication
  if (operation === '*' && num2.toString().length > 1) {
    // Get expected intermediate products
    const expectedIntermediates = calculateIntermediates(num1, num2);

    // Check intermediate rows
    let intermediatesCorrect = true;
    
    // First row
    const firstRowProduct = (num1 * parseInt(num2.toString()[1])).toString();
    const userFirstRow = answers.intermediates[0].join('').trim();
    if (userFirstRow !== firstRowProduct) {
      intermediatesCorrect = false;
    }
    
    // Second row
    const secondRowProduct = (num1 * parseInt(num2.toString()[0])).toString();
    const userSecondRow = answers.intermediates[1].slice(0, -1).join('').trim();
    if (userSecondRow !== secondRowProduct) {
      intermediatesCorrect = false;
    }
    
    // Total row
    const totalProduct = (num1 * num2).toString();
    const userTotal = answers.total.join('').trim();
    if (userTotal !== totalProduct) {
      intermediatesCorrect = false;
    }

    // Format answer strings for feedback
    const expectedAnswer = [
      totalProduct,
      ...expectedIntermediates.map(row => row.join(''))
    ].join(',');

    const userAnswer = [
      userTotal,
      ...answers.intermediates.map(row => row.join(''))
    ].join(',');

    const isCorrect = intermediatesCorrect;

    return {
      isCorrect,
      userAnswer,
      expectedAnswer,
      feedback: isCorrect ? null : {
        num1,
        num2,
        operation,
        carries: calculateCarries(num1, num2, operation),
        intermediates: expectedIntermediates
      }
    };
  }

  // For single-digit operations
  const expectedResult = operation === '+' 
    ? num1 + num2 
    : operation === '-' 
      ? num1 - num2 
      : num1 * num2;

  const userTotal = parseInt(answers.total.join('') || '0', 10);
  const isCorrect = userTotal === expectedResult;

  return {
    isCorrect,
    userAnswer: answers.total.join(''),
    expectedAnswer: expectedResult.toString(),
    feedback: isCorrect ? null : {
      num1,
      num2,
      operation,
      carries: calculateCarries(num1, num2, operation)
    }
  };
};

export const createEmptyAnswers = (num1, num2, operation) => {
  const resultLength = getResultLength(num1, num2, operation);
  const maxLength = Math.max(num1.toString().length, num2.toString().length);

  // For multiplication, we need multiple intermediate rows
  const intermediateRows = operation === '*' && num2.toString().length > 1 
    ? num2.toString().length 
    : 1;

  // Add extra carry slot for subtraction's ones place
  const carryLength = maxLength - 1 + (operation === '-' ? 1 : 0);

  return {
    carries: Array(carryLength).fill(''),
    intermediates: Array(intermediateRows).fill(null).map(() => 
      Array(resultLength).fill('')
    ),
    total: Array(resultLength).fill('')
  };
};
