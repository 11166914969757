import { roll } from './utils';

export const decimals = {
  "Place Value (Thousandths)": {
    levels: [
      { id: "d5n1m2p1", description: "Convert Between Thousandths", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['thousandth', 'hundredth', 'tenth'];
        const fromIndex = roll(0, 2);
        let toIndex;
        do {
          toIndex = roll(0, 2);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'thousandth') {
          value = roll(1, 9) * (toUnit === 'hundredth' ? 10 : 100);
        } else if (fromUnit === 'hundredth') {
          value = roll(1, 9) * (toUnit === 'thousandth' ? 1 : 10);
        } else { // tenth
          value = roll(1, 9);
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'decimal'
        };
      }},
      { 
        id: "d5plc1t1", 
        description: "Identify Place Value to Thousandths", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 9999);
          const decimal = roll(0, 999);
          const placeValues = [0.001, 0.01, 100, 1000];
          const places = [placeValues[roll(0, 3)]];
          return {
            number: whole + decimal/1000,
            places
          };
        }
      },
      { 
        id: "d5zzc1t1", 
        description: "Identify Decimal Place Value to Thousandths", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 99);
          const decimal = roll(0, 999);
          const placeValues = [0.001, 0.01, 0.1];
          const places = [placeValues[roll(0, 2)]];
          return {
            number: whole + decimal/1000,
            places
          };
        }
      },
      { id: "d5cnt1t1", description: "Count by Thousandths", exerciseType: "CountingPractice", timeLimit: 45, inputs: { 
        start: roll(0,9) + roll(0,99)/100 + roll(1,9)/1000,
        increment: 0.001,
        allowedFormats: ['decimal']
      }},
      { id: "d5cmp1t1", description: "Compare Decimals (Thousandths)", exerciseType: "ComparingDecimalsPractice", timeLimit: 90, inputs: () => {
        const whole1 = roll(0, 9);
        const whole2 = roll(0, 9);
        const thousandth1 = roll(0, 999);
        let thousandth2;
        do {
          thousandth2 = roll(0, 999);
        } while (thousandth2 === thousandth1 && whole1 === whole2);
        
        return {
          decimal1: whole1 + thousandth1/1000,
          decimal2: whole2 + thousandth2/1000
        };
      }}
    ]
  }
};
