import { roll } from './utils';

export const negatives = {
  "Add/Subtract Negatives": {
    levels: [
      {
        id: "g6neg1",
        description: "Add Positive and Negative",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num1 = roll(-10, -1);
          const num2 = roll(1, 10);
          return {
            num1,
            num2,
            operation: '+',
            answer: num1 + num2
          };
        }
      },
      {
        id: "g6neg2",
        description: "Add Two Negatives",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num1 = roll(-10, -1);
          const num2 = roll(-10, -1);
          return {
            num1,
            num2,
            operation: '+',
            answer: num1 + num2
          };
        }
      },
      {
        id: "g6neg3",
        description: "Subtract Positive from Positive",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num2 = roll(1, 10);
          const num1 = roll(num2 + 1, 15); // Ensure num1 > num2
          return {
            num1,
            num2,
            operation: '-',
            answer: num1 - num2
          };
        }
      },
      {
        id: "g6neg4",
        description: "Subtract Negative from Positive",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num2 = roll(-10, -1);
          const num1 = roll(1, 10);
          return {
            num1,
            num2,
            operation: '-',
            answer: num1 - num2
          };
        }
      },
      {
        id: "g6neg5",
        description: "Subtract Positive from Negative",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num1 = roll(-10, -1);
          const num2 = roll(1, 10);
          return {
            num1,
            num2,
            operation: '-',
            answer: num1 - num2
          };
        }
      },
      {
        id: "g6neg6",
        description: "Subtract Negative from Negative",
        exerciseType: "OperationsPractice",
        timeLimit: 60,
        inputs: () => {
          const num1 = roll(-10, -1);
          const num2 = roll(-10, -1);
          return {
            num1,
            num2,
            operation: '-',
            answer: num1 - num2
          };
        }
      }
    ]
  }
};
