import React, { useState, useEffect, useRef } from 'react';
import { createRefs, handleArrowKey } from './vertical-operations/InputRows';
import InputRows from './vertical-operations/InputRows';
import Display from './vertical-operations/Display';
import { validateAnswers, createEmptyAnswers } from './vertical-operations/Validator';

export { Display as VerticalOperationDisplay };

const VerticalOperationsPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn }) => {
  const [problem, setProblem] = useState(null);
  const [answers, setAnswers] = useState({
    carries: [],
    intermediates: [],
    total: []
  });
  
  const inputRefs = useRef({
    carries: [],
    intermediates: [],
    total: []
  });

  useEffect(() => {
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetAnswers(newProblem);
    }
  }, [level, currentProgress, inputsFn]);

  const resetAnswers = (newProblem) => {
    const { num1, num2, operation } = newProblem;
    setAnswers(createEmptyAnswers(num1, num2, operation));
    inputRefs.current = createRefs(num1, num2, operation);
  };

  const handleDigitChange = (value, type, rowIndex, digitIndex) => {
    setAnswers(prev => {
      const newAnswers = { ...prev };
      
      if (type === 'carry') {
        const newCarries = [...prev.carries];
        newCarries[digitIndex] = value;
        newAnswers.carries = newCarries;
      } else if (type === 'intermediate') {
        const newIntermediates = prev.intermediates.map(row => [...row]);
        newIntermediates[rowIndex][digitIndex] = value;
        newAnswers.intermediates = newIntermediates;
      } else if (type === 'total') {
        const newTotal = [...prev.total];
        newTotal[digitIndex] = value;
        newAnswers.total = newTotal;
      }
      
      return newAnswers;
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      const validation = validateAnswers(answers, problem);

      onAnswer(
        validation.isCorrect ? 'correct' : validation.userAnswer,
        validation.expectedAnswer,
        validation.feedback
      );

      // Generate new problem regardless of whether answer was correct
      if (typeof inputsFn === 'function') {
        const newProblem = inputsFn();
        setProblem(newProblem);
        resetAnswers(newProblem);
        // Focus first input
        if (inputRefs.current.carries[0]?.current) {
          inputRefs.current.carries[0].current.focus();
        } else if (inputRefs.current.intermediates[0]?.[0]?.current) {
          inputRefs.current.intermediates[0][0].current.focus();
        }
      }
    }
  };

  const handleArrowNavigation = (key, type, rowIndex, digitIndex) => {
    const nextRef = handleArrowKey(key, type, rowIndex, digitIndex, inputRefs.current);
    if (nextRef?.current) {
      nextRef.current.focus();
    }
  };

  if (!problem) return null;

  return (
    <InputRows
      num1={problem.num1}
      num2={problem.num2}
      operation={problem.operation}
      answers={answers}
      inputRefs={inputRefs}
      handleDigitChange={handleDigitChange}
      handleKeyDown={handleKeyDown}
      handleArrowKey={handleArrowNavigation}
    />
  );
};

export default VerticalOperationsPractice;
