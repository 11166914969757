import { roll } from './utils';

export const impropermixed = {
  "Improper/Mixed Fractions": {
    levels: [
      { id: "f9n0u1t4", description: "Name Improper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 120, inputs: () => {
        const denominator = roll(3, 5);
        return {
          numerator: roll(denominator + 1, denominator * 4),
          denominator,
          shape: ['circle', 'rectangle'][roll(0,1)]
        };
      }},
      { id: "f9nllat4", description: "Name Improper Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 150, inputs: () => {
        const denominator = roll(3, 5);
        return {
          numerator: roll(denominator + 1, denominator * 4),
          denominator,
          shape: 'numberline'
        };
      }},
      { id: "f9afsnl", description: "Name Mixed Numbers", exerciseType: "NamingFractionsPractice", timeLimit: 150, inputs: () => {
        const denominator = roll(3, 6);
        const wholeNumber = roll(1, 3);
        const properNumerator = roll(1, denominator - 1);
        return {
          numerator: wholeNumber * denominator + properNumerator,
          denominator,
          shape: ['circle', 'rectangle'][roll(0,1)],
          isMixedNumber: true
        };
      }},
      { id: "f9nmxnl", description: "Name Mixed Numbers on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 180, inputs: () => {
        const denominator = roll(2, 6);
        const wholeNumber = roll(1, 3);
        const properNumerator = roll(1, denominator - 1);
        return {
          numerator: wholeNumber * denominator + properNumerator,
          denominator,
          shape: 'numberline',
          isMixedNumber: true
        };
      }},
      { id: "f9imprmx", description: "Convert Improper Fractions to Mixed Numbers (2 to 5)", exerciseType: "ImproperMixedNumberPractice", timeLimit: 180, inputs: () => {
        const denominator = roll(2, 5);
        const wholeNumber = roll(1, 5);
        const properNumerator = roll(1, denominator - 1);
        // Generate improper fraction from mixed number
        const improperNumerator = wholeNumber * denominator + properNumerator;
        return {
          numerator: improperNumerator,
          denominator,
          mode: 'toMixed'
        };
      }},
      { id: "f9impadx", description: "Convert Improper Fractions to Mixed Numbers (6 to 9)", exerciseType: "ImproperMixedNumberPractice", timeLimit: 180, inputs: () => {
        const denominator = roll(6, 9);
        const wholeNumber = roll(1, 9);
        const properNumerator = roll(1, denominator - 1);
        // Generate improper fraction from mixed number
        const improperNumerator = wholeNumber * denominator + properNumerator;
        return {
          numerator: improperNumerator,
          denominator,
          mode: 'toMixed'
        };
      }},
      { id: "f9mximpr", description: "Convert Mixed Numbers to Improper Fractions", exerciseType: "ImproperMixedNumberPractice", timeLimit: 180, inputs: () => {
        const denominator = roll(3, 9);
        const wholeNumber = roll(3, 9);
        const properNumerator = roll(1, denominator - 1);
        return {
          wholeNumber,
          properNumerator,
          denominator,
          mode: 'toImproper'
        };
      }},
      { id: "f9addmx", description: "Add Mixed Numbers", exerciseType: "MixedNumberOperationsPractice", timeLimit: 120, inputs: () => {
        // Generate first mixed number
        const denominator1 = roll(3, 10);
        const wholeNumber1 = roll(6, 9);
        const properNumerator1 = roll(1, denominator1 - 2);
        
        // Generate second mixed number
        const denominator2 = denominator1; // Use same denominator for 4th grade
        const wholeNumber2 = roll(6, 9);
        const properNumerator2 = roll(1, denominator1 - properNumerator1 - 1);
        
        // Calculate answer
        const improperNum1 = wholeNumber1 * denominator1 + properNumerator1;
        const improperNum2 = wholeNumber2 * denominator2 + properNumerator2;
        const answerNumerator = improperNum1 + improperNum2;
        
        return {
          fraction1: {
            numerator: improperNum1,
            denominator: denominator1
          },
          fraction2: {
            numerator: improperNum2,
            denominator: denominator2
          },
          operation: '+',
          answer: {
            numerator: answerNumerator,
            denominator: denominator1
          }
        };
      }},
      { id: "f9submx", description: "Subtract Mixed Numbers", exerciseType: "MixedNumberOperationsPractice", timeLimit: 120, inputs: () => {
        // Generate first mixed number (larger one)
        const denominator1 = roll(4, 10);
        const wholeNumber1 = roll(6, 9);
        const properNumerator1 = roll(2, denominator1 - 1);
        
        // Generate second mixed number (smaller one to avoid negative results)
        const denominator2 = denominator1; // Use same denominator for 4th grade
        const wholeNumber2 = roll(4, wholeNumber1 - 1); // Ensure whole number is smaller
        const properNumerator2 = roll(1, properNumerator1 - 1);
        
        // Calculate answer
        const improperNum1 = wholeNumber1 * denominator1 + properNumerator1;
        const improperNum2 = wholeNumber2 * denominator2 + properNumerator2;
        const answerNumerator = improperNum1 - improperNum2;
        
        return {
          fraction1: {
            numerator: improperNum1,
            denominator: denominator1
          },
          fraction2: {
            numerator: improperNum2,
            denominator: denominator2
          },
          operation: '−',
          answer: {
            numerator: answerNumerator,
            denominator: denominator1
          }
        };
      }},
      { id: "f9addmxr", description: "Add Mixed Numbers with Regrouping", exerciseType: "MixedNumberOperationsPractice", timeLimit: 300, inputs: () => {
        // Generate first mixed number
        const denominator1 = roll(3, 8);
        const wholeNumber1 = roll(6, 9);
        const properNumerator1 = roll(1, denominator1 - 1);
        
        // Generate second mixed number that will require regrouping
        const denominator2 = denominator1;
        const wholeNumber2 = roll(6, 9);
        const properNumerator2 = roll(denominator1 - properNumerator1, denominator1 - 1); // Ensures sum > denominator
        
        // Calculate answer
        const improperNum1 = wholeNumber1 * denominator1 + properNumerator1;
        const improperNum2 = wholeNumber2 * denominator2 + properNumerator2;
        const answerNumerator = improperNum1 + improperNum2;
        
        return {
          fraction1: {
            numerator: improperNum1,
            denominator: denominator1
          },
          fraction2: {
            numerator: improperNum2,
            denominator: denominator2
          },
          operation: '+',
          answer: {
            numerator: answerNumerator,
            denominator: denominator1
          }
        };
      }},
      { id: "f9submxr", description: "Subtract Mixed Numbers with Regrouping", exerciseType: "MixedNumberOperationsPractice", timeLimit: 300, inputs: () => {
        // Generate first mixed number
        const denominator1 = roll(3, 8);
        const wholeNumber1 = roll(6, 9);
        const properNumerator1 = roll(1, denominator1 - 2); // Small numerator to force borrowing
        
        // Generate second mixed number that will require borrowing
        const denominator2 = denominator1;
        const wholeNumber2 = roll(4, wholeNumber1 - 1);
        const properNumerator2 = roll(properNumerator1 + 1, denominator1 - 1); // Larger than first numerator
        
        // Calculate answer
        const improperNum1 = wholeNumber1 * denominator1 + properNumerator1;
        const improperNum2 = wholeNumber2 * denominator2 + properNumerator2;
        const answerNumerator = improperNum1 - improperNum2;
        
        return {
          fraction1: {
            numerator: improperNum1,
            denominator: denominator1
          },
          fraction2: {
            numerator: improperNum2,
            denominator: denominator2
          },
          operation: '−',
          answer: {
            numerator: answerNumerator,
            denominator: denominator1
          }
        };
      }}
    ]
  }
};
