import { roll } from './utils';

export const doublesHalves = {
  "Even and Odd": {
    levels: [
      { id: "b9c2n7k4", description: "Count Up by 2", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 2, increment: 2 } },
      { id: "m5x8v3q6", description: "Count down by 2", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 20, increment: -2 } },
      { id: "k6w3r8t2", description: "Doubles up to 5", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num = roll(1, 5);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "j1w4r7t2", description: "Count Up by 2 from 1", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 1, increment: 2 } },
      { id: "g6y9h3f5", description: "Count Down by 2 from 19", exerciseType: "CountingPractice", timeLimit: 60, inputs: { start: 19, increment: -2 } },
      { id: "d3b7c1n5", description: "Identify Even and Odd Numbers to 10", exerciseType: "MultipleChoicePractice", timeLimit: 60, inputs: () => {
        const number = roll(1, 10);
        return {
          question: `Is ${number} even or odd?`,
          choices: ["Even", "Odd"],
          correctAnswer: number % 2 === 0 ? "Even" : "Odd"
        };
      }},
      { id: "t1w5r9h3", description: "Half of Even Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 60, inputs: () => ({
        number: roll(1, 5) * 2
      })},
      { id: "k6w3r8a2", description: "Doubles up to 5 Tens", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num = roll(1, 5) * 10;
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "t1w5r8ha", description: "Half of Even Tens", exerciseType: "HalvesPractice", timeLimit: 60, inputs: () => ({
        number: roll(1, 5) * 2 * 10
      })},      
      { id: "h9y4f2l7", description: "Doubles up to 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const num = roll(6, 10);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "z8x2v6q4", description: "Identify Even and Odd Numbers to 20", exerciseType: "MultipleChoicePractice", timeLimit: 45, inputs: () => {
        const number = roll(11, 20);
        return {
          question: `Is ${number} even or odd?`,
          choices: ["Even", "Odd"],
          correctAnswer: number % 2 === 0 ? "Even" : "Odd"
        };
      }},
      { id: "g4y8f3l6", description: "Half of Even Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 45, inputs: () => ({
        number: roll(5, 10) * 2
      })},
      { id: "h9y4f2l6", description: "Doubles of Two Digit Numbers", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const num = roll(1,4)*10 + roll(1,4);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "h3y7f50z", description: "Half of Two Digit Even Numbers with Even Tens", exerciseType: "HalvesPractice", timeLimit: 45, inputs: () => ({
        number: roll(1,4) * 20 + roll(1,4)*2
      })}
    ]
  }
};
