import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import {
  TextField,
  Button
} from '@mui/material';
import AuthForm from './AuthForm';
import '../../styles/authform.css';

const PasswordForm = ({ onSubmit, loading, formData, setFormData }) => (
  <form onSubmit={onSubmit} className="auth-form">
    {['new', 'confirm'].map((type) => (
      <TextField
        key={type}
        label={`${type.charAt(0).toUpperCase() + type.slice(1)} Password`}
        type="password"
        value={formData[`${type}Password`]}
        onChange={(e) => setFormData({
          ...formData,
          [`${type}Password`]: e.target.value
        })}
        required
      />
    ))}
    
    <Button
      type="submit"
      className="auth-submit-button"
      disabled={loading}
    >
      Update Password
    </Button>
  </form>
);

export default function Profile() {
  const { updatePassword } = useAuth();
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  async function handlePasswordUpdate(e) {
    e.preventDefault();
    
    setError('');
    setSuccess('');

    const { newPassword, confirmPassword } = formData;

    if (!newPassword || !confirmPassword) {
      setError('Please fill in all password fields');
      return;
    }

    if (newPassword.length < 6) {
      setError('New password must be at least 6 characters');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      setLoading(true);
      await updatePassword(newPassword);
      setSuccess('Password updated successfully');
      setFormData({
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      setError(`Failed to update password: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <AuthForm 
      title="Profile Settings"
      error={error}
      success={success}
    >

      <PasswordForm 
        onSubmit={handlePasswordUpdate}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
      />
    </AuthForm>
  );
}
