import React from 'react';

const DigitInput = ({ value, onChange, onKeyDown, inputRef, autoFocus, onArrowKey }) => (
  <input
    type="text"
    value={value}
    onChange={(e) => {
      const val = e.target.value.replace(/\D/g, '');
      if (val.length <= 1) onChange(val);
    }}
    onKeyDown={(e) => {
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
        onArrowKey(e.key);
      } else if (e.key === 'Enter' && onKeyDown) {
        onKeyDown(e);
      }
    }}
    ref={inputRef}
    autoFocus={autoFocus}
    style={{
      width: '32px',
      height: '32px',
      textAlign: 'center',
      fontSize: '1.2rem',
      fontFamily: 'monospace',
      color: 'var(--text-primary)',
      backgroundColor: 'var(--bg-primary)',
      border: '1px solid var(--border-color)',
      borderRadius: '4px',
      padding: '0',
      margin: '0 2px'
    }}
  />
);

export default DigitInput;
