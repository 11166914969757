const validateAnswers = (answers, problem) => {
  const { number, secondNumber, operation, isThreeDigit } = problem;
  const num1Str = number.toString().padStart(isThreeDigit ? 3 : 2, '0');
  
  // Helper function to validate step1 boxes
  const validateStep1 = () => {
    // Get the correct steps that include regrouping
    const correctSteps = calculateCorrectSteps(problem);

    if (operation === '+' || operation === '-') {
      if (isThreeDigit) {
        return answers.step1First === correctSteps.step1First &&
               answers.step1Second === correctSteps.step1Second &&
               answers.step1Third === correctSteps.step1Third &&
               answers.step1Fourth === correctSteps.step1Fourth &&
               answers.step1Fifth === correctSteps.step1Fifth &&
               answers.step1Sixth === correctSteps.step1Sixth;
      } else {
        return answers.step1First === correctSteps.step1First &&
               answers.step1Second === correctSteps.step1Second &&
               answers.step1Third === correctSteps.step1Third &&
               answers.step1Fourth === correctSteps.step1Fourth;
      }
    } else { // multiplication
      if (problem.format === 'expanded') {
        const { split } = problem;
        // First validate the expressions
        const validExpressions = 
          answers.step1First === split.firstPart.toString() &&
          answers.step1Second === split.firstPart.toString() &&
          answers.step1Third === split.secondPart.toString() &&
          answers.step1Fourth === split.secondPart.toString();

        // Then validate the partial products
        const product1 = split.firstPart * split.secondNumberParts.tens;
        const product2 = split.firstPart * split.secondNumberParts.ones;
        const product3 = split.secondPart * split.secondNumberParts.tens;
        const product4 = split.secondPart * split.secondNumberParts.ones;

        const validProducts = 
          parseInt(answers.step2First || '0', 10) === product1 &&
          parseInt(answers.step2Second || '0', 10) === product2 &&
          parseInt(answers.step2Third || '0', 10) === product3 &&
          parseInt(answers.step2Fourth || '0', 10) === product4;

        // Then validate the intermediate sums
        const validSums =
          parseInt(answers.step2Sum1 || '0', 10) === product1 + product2 &&
          parseInt(answers.step2Sum2 || '0', 10) === product3 + product4;

        return validExpressions && validProducts && validSums;
      } else if (isThreeDigit) {
        return answers.step1First === (num1Str[0] + '00') &&
               answers.step1Second === (num1Str[1] + '0') &&
               answers.step1Third === num1Str[2];
      } else {
        return answers.step1First === (num1Str[0] + '0') &&
               answers.step1Second === num1Str[1];
      }
    }
  };

  // Helper function to validate step2 boxes
  const validateStep2 = () => {
    const expectedAnswer = operation === '+' 
      ? number + secondNumber 
      : operation === '-' 
        ? number - secondNumber 
        : number * secondNumber;

    if (problem.format === 'expanded') {
      const step2Sum = parseInt(answers.step2First || '0', 10) + 
                      parseInt(answers.step2Second || '0', 10) + 
                      parseInt(answers.step2Third || '0', 10) + 
                      parseInt(answers.step2Fourth || '0', 10);
      return step2Sum === expectedAnswer;
    } else if (isThreeDigit) {
      const step2Sum = parseInt(answers.step2First || '0', 10) + 
                      parseInt(answers.step2Second || '0', 10) + 
                      parseInt(answers.step2Third || '0', 10);
      return step2Sum === expectedAnswer;
    } else {
      const step2Sum = parseInt(answers.step2First || '0', 10) + 
                      parseInt(answers.step2Second || '0', 10);
      return step2Sum === expectedAnswer;
    }
  };

  // Helper function to validate final answer
  const validateFinal = () => {
    const finalAnswer = parseInt(answers.step3 || '0', 10);
    const expectedAnswer = operation === '+' 
      ? number + secondNumber 
      : operation === '-' 
        ? number - secondNumber 
        : number * secondNumber;
    return finalAnswer === expectedAnswer;
  };

  // All steps must be correct
  return validateStep1() && validateStep2() && validateFinal();
};

const calculateCorrectSteps = (problem) => {
  const { number, secondNumber, operation } = problem;
  const expectedAnswer = operation === '+' 
    ? number + secondNumber 
    : operation === '-' 
      ? number - secondNumber 
      : number * secondNumber;

  const isThreeDigit = number.toString().length === 3 || secondNumber.toString().length === 3;
  const num1Str = number.toString().padStart(isThreeDigit ? 3 : 2, '0');
  const num2Str = secondNumber.toString().padStart(isThreeDigit ? 3 : 2, '0');

  if (operation === '+') {
    if (isThreeDigit) {
      return {
        step1First: num1Str[0] + '00',  // hundreds of first number
        step1Second: num2Str[0] + '00', // hundreds of second number
        step1Third: num1Str[1] + '0',   // tens of first number
        step1Fourth: num2Str[1] + '0',  // tens of second number
        step1Fifth: num1Str[2],         // ones of first number
        step1Sixth: num2Str[2],         // ones of second number
        step2First: (parseInt(num1Str[0] + '00') + parseInt(num2Str[0] + '00')).toString(),
        step2Second: (parseInt(num1Str[1] + '0') + parseInt(num2Str[1] + '0')).toString(),
        step2Third: (parseInt(num1Str[2]) + parseInt(num2Str[2])).toString(),
        step3: expectedAnswer.toString()
      };
    } else {
      return {
        step1First: num1Str[0] + '0',   // tens of first number
        step1Second: num2Str[0] + '0',  // tens of second number
        step1Third: num1Str[1],         // ones of first number
        step1Fourth: num2Str[1],        // ones of second number
        step2First: (parseInt(num1Str[0] + '0') + parseInt(num2Str[0] + '0')).toString(),
        step2Second: (parseInt(num1Str[1]) + parseInt(num2Str[1])).toString(),
        step3: expectedAnswer.toString()
      };
    }
  } else if (operation === '-') {
    // Handle borrowing for subtraction
    const needToBorrow = (pos) => parseInt(num1Str[pos]) < parseInt(num2Str[pos]);
    const modifiedDigits = [...num1Str].map(d => parseInt(d));

    if (isThreeDigit) {
      // Handle borrowing for three digits
      const modifiedTens = parseInt(num1Str[1]);
      const modifiedHundreds = parseInt(num1Str[0]);
      
      // Check if ones place needs borrowing
      if (needToBorrow(2)) {
        modifiedDigits[1] = modifiedTens - 1; // Decrease tens
      }
      
      // Check if tens place needs borrowing
      if (needToBorrow(1)) {
        modifiedDigits[0] = modifiedHundreds - 1; // Decrease hundreds
        modifiedDigits[1] = modifiedDigits[1] + 10; // Increase tens by 100 (10 tens)
      }

      return {
        step1First: modifiedDigits[0] + '00',  // hundreds of first number
        step1Second: num2Str[0] + '00',        // hundreds of second number
        step1Third: modifiedDigits[1] + '0',   // tens of first number (possibly increased by 100)
        step1Fourth: num2Str[1] + '0',         // tens of second number
        step1Fifth: needToBorrow(2) ? (parseInt(num1Str[2]) + 10).toString() : num1Str[2], // ones of first number (possibly +10)
        step1Sixth: num2Str[2],               // ones of second number
        step2First: (modifiedDigits[0] * 100 - parseInt(num2Str[0] + '00')).toString(),
        step2Second: (modifiedDigits[1] * 10 - parseInt(num2Str[1] + '0')).toString(),
        step2Third: (needToBorrow(2) ? parseInt(num1Str[2]) + 10 : parseInt(num1Str[2])) - parseInt(num2Str[2]),
        step3: expectedAnswer.toString()
      };
    } else {
      // Check if ones place needs borrowing
      if (needToBorrow(1)) {
        modifiedDigits[0] = modifiedDigits[0] - 1; // Decrease tens
      }

      return {
        step1First: modifiedDigits[0] + '0',  // tens of first number (possibly decreased)
        step1Second: num2Str[0] + '0',        // tens of second number
        step1Third: needToBorrow(1) ? (parseInt(num1Str[1]) + 10).toString() : num1Str[1], // ones of first number (possibly +10)
        step1Fourth: num2Str[1],              // ones of second number
        step2First: (modifiedDigits[0] * 10 - parseInt(num2Str[0] + '0')).toString(),
        step2Second: ((needToBorrow(1) ? parseInt(num1Str[1]) + 10 : parseInt(num1Str[1])) - parseInt(num2Str[1])).toString(),
        step3: expectedAnswer.toString()
      };
    }
  } else { // multiplication
    if (problem.format === 'expanded') {
      const { split } = problem;
      const { firstPart, secondPart, secondNumberParts } = split;
      const { tens, ones } = secondNumberParts;
      
      // Calculate all products
      const product1 = firstPart * tens;
      const product2 = firstPart * ones;
      const product3 = secondPart * tens;
      const product4 = secondPart * ones;

      // Calculate intermediate sums
      const sum1 = product1 + product2;
      const sum2 = product3 + product4;
      
      return {
        format: 'expanded',
        split: problem.split,
        step1First: firstPart.toString(),
        step1Second: firstPart.toString(),
        step1Third: secondPart.toString(),
        step1Fourth: secondPart.toString(),
        step2First: product1.toString(),
        step2Second: product2.toString(),
        step2Third: product3.toString(),
        step2Fourth: product4.toString(),
        step2Sum1: sum1.toString(),
        step2Sum2: sum2.toString(),
        step3: expectedAnswer.toString()
      };
    } else if (isThreeDigit) {
      const hundreds = parseInt(num1Str[0] + '00') * secondNumber;
      const tens = parseInt(num1Str[1] + '0') * secondNumber;
      const ones = parseInt(num1Str[2]) * secondNumber;
      return {
        step1First: num1Str[0] + '00',
        step1Second: num1Str[1] + '0',
        step1Third: num1Str[2],
        step2First: hundreds.toString(),
        step2Second: tens.toString(),
        step2Third: ones.toString(),
        step3: expectedAnswer.toString()
      };
    } else {
      const tens = parseInt(num1Str[0] + '0') * secondNumber;
      const ones = parseInt(num1Str[1]) * secondNumber;
      return {
        step1First: num1Str[0] + '0',
        step1Second: num1Str[1],
        step2First: tens.toString(),
        step2Second: ones.toString(),
        step3: expectedAnswer.toString()
      };
    }
  }
};

export { validateAnswers, calculateCorrectSteps };
