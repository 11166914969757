import React from 'react';
import { Box, Typography } from '@mui/material';
import DigitInput from './DigitInput';
import './styles.css';

// Create refs for all input fields
export const createRefs = (dividendLength, quotientLength) => ({
  quotient: Array(dividendLength).fill(null).map(() => React.createRef()),
  subtracted: Array(quotientLength).fill(null).map(() => 
    Array(dividendLength).fill(null).map(() => React.createRef())
  ),
  remainder: Array(quotientLength).fill(null).map(() => 
    Array(dividendLength).fill(null).map(() => React.createRef())
  ),
  finalRemainder: React.createRef()
});

// Handle arrow key navigation between inputs
export const handleArrowKey = (key, type, stepIndex, digitIndex, refs) => {
  if (!refs) return null;

  let nextRef = null;

  if (type === 'quotient') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.quotient[digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < refs.quotient.length - 1) {
      nextRef = refs.quotient[digitIndex + 1];
    } else if (key === 'ArrowRight' && digitIndex === refs.quotient.length - 1) {
      nextRef = refs.finalRemainder;
    } else if (key === 'ArrowDown' && refs.subtracted?.[0]?.[digitIndex]) {
      nextRef = refs.subtracted[0][digitIndex];
    }
  } else if (type === 'finalRemainder') {
    if (key === 'ArrowLeft') {
      nextRef = refs.quotient[refs.quotient.length - 1];
    } else if (key === 'ArrowDown' && refs.subtracted?.[0]?.[0]) {
      nextRef = refs.subtracted[0][0];
    }
  } else if (type === 'subtracted') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.subtracted[stepIndex][digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < refs.subtracted[stepIndex].length - 1) {
      nextRef = refs.subtracted[stepIndex][digitIndex + 1];
    } else if (key === 'ArrowUp' && stepIndex === 0) {
      nextRef = digitIndex === 0 ? refs.finalRemainder : refs.quotient[digitIndex];
    } else if (key === 'ArrowUp' && stepIndex > 0 && refs.remainder[stepIndex - 1]?.[digitIndex]) {
      nextRef = refs.remainder[stepIndex - 1][digitIndex];
    } else if (key === 'ArrowDown' && refs.remainder[stepIndex]?.[digitIndex]) {
      nextRef = refs.remainder[stepIndex][digitIndex];
    }
  } else if (type === 'remainder') {
    if (key === 'ArrowLeft' && digitIndex > 0) {
      nextRef = refs.remainder[stepIndex][digitIndex - 1];
    } else if (key === 'ArrowRight' && digitIndex < refs.remainder[stepIndex].length - 1) {
      nextRef = refs.remainder[stepIndex][digitIndex + 1];
    } else if (key === 'ArrowUp' && refs.subtracted[stepIndex]?.[digitIndex]) {
      nextRef = refs.subtracted[stepIndex][digitIndex];
    } else if (key === 'ArrowDown' && refs.subtracted[stepIndex + 1]?.[digitIndex]) {
      nextRef = refs.subtracted[stepIndex + 1][digitIndex];
    }
  }

  return nextRef;
};

const InputRows = ({ 
  num1, 
  num2,
  answers,
  inputRefs,
  handleDigitChange,
  handleKeyDown,
  handleArrowKey
}) => {
  const num1Str = num1.toString();
  const dividendLength = num1Str.length;
  const expectedQuotient = Math.floor(num1 / num2);
  const quotientLength = expectedQuotient.toString().length;

  return (
    <div className="content-box">
      <Box sx={{ 
        display: 'flex',
        alignItems: 'flex-start',
        gap: '1rem',
        padding: '2rem',
        fontFamily: 'monospace',
        position: 'relative'
      }}>
        {/* Division symbol and divisor */}
        <Typography variant="h4" sx={{ 
          color: 'var(--text-primary)',
          position: 'absolute',
          left: '2.5rem',
          top: '5.5rem',
          fontFamily: 'inherit',
          fontSize: '1.5rem'
        }}>
          {num2}
        </Typography>
        
        {/* Main division structure */}
        <Box sx={{ 
          position: 'relative',
          marginLeft: '4rem'
        }}>
          {/* Quotient row with remainder */}
          <Box sx={{ 
            position: 'relative',
            marginBottom: '0.5rem',
            marginLeft: '1.5rem',
          }}>
            {/* Quotient */}
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '0.5rem',
              position: 'relative'
            }}>
              {Array(dividendLength).fill(0).map((_, i) => (
                <DigitInput
                  key={`quotient-${i}`}
                  value={answers.quotient[i] || ''}
                  onChange={(value) => handleDigitChange(value, 'quotient', i)}
                  onKeyDown={handleKeyDown}
                  inputRef={inputRefs.current.quotient?.[i]}
                  autoFocus={i === 0}
                  onArrowKey={(key) => handleArrowKey(key, 'quotient', 0, i)}
                />
              ))}
              {/* Division line under quotient */}
              <Box sx={{
                position: 'absolute',
                bottom: '-0.5rem',
                left: 0,
                right: 0,
                borderBottom: '2px solid var(--text-primary)'
              }} />
            </Box>
            
            {/* Remainder */}
            <Box sx={{ 
              position: 'absolute',
              left: '100%',
              top: 0,
              display: 'flex',
              alignItems: 'center',
              marginLeft: '1rem'
            }}>
              <Typography sx={{ 
                color: 'var(--text-primary)',
                marginRight: '0.5rem',
                fontSize: '1.2rem'
              }}>
                R
              </Typography>
              <DigitInput
                value={answers.finalRemainder}
                onChange={(value) => handleDigitChange(value, 'finalRemainder')}
                onKeyDown={handleKeyDown}
                inputRef={inputRefs.current.finalRemainder}
                onArrowKey={(key) => handleArrowKey(key, 'finalRemainder')}
              />
            </Box>
          </Box>

          {/* Division bracket */}
          <Box sx={{ 
            position: 'absolute',
            left: 0,
            top: '2.5rem',
            width: '1.5rem',
            height: '3rem',
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              borderTop: '2px solid var(--text-primary)'
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              borderLeft: '2px solid var(--text-primary)'
            }
          }} />

          {/* Dividend and steps */}
          <Box sx={{ 
            marginLeft: '1.5rem',
            position: 'relative',
            paddingTop: '0.5rem'
          }}>
            {/* Dividend */}
            <Box sx={{ 
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1rem'
            }}>
              {num1Str.split('').map((digit, i) => (
                <Box
                  key={`dividend-${i}`}
                  sx={{
                    width: '32px',
                    height: '32px',
                    margin: '0 2px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.2rem',
                    color: 'var(--text-primary)'
                  }}
                >
                  {digit}
                </Box>
              ))}
            </Box>

            {/* Subtraction steps */}
            {Array(quotientLength).fill(0).map((_, stepIndex) => (
              <Box key={`step-${stepIndex}`} sx={{ marginBottom: '1.5rem' }}>
                {/* Subtracted value with minus sign */}
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  position: 'relative'
                }}>
                  {/* Minus sign */}
                  <Typography sx={{
                    position: 'absolute',
                    left: '-1.5rem',
                    fontSize: '1.2rem',
                    color: 'var(--text-primary)'
                  }}>
                    −
                  </Typography>
                  {Array(dividendLength).fill(0).map((_, digitIndex) => (
                    <DigitInput
                      key={`subtracted-${stepIndex}-${digitIndex}`}
                      value={answers.subtractedValues[stepIndex]?.[digitIndex] || ''}
                      onChange={(value) => handleDigitChange(value, 'subtracted', stepIndex, digitIndex)}
                      onKeyDown={handleKeyDown}
                      inputRef={inputRefs.current.subtracted?.[stepIndex]?.[digitIndex]}
                      onArrowKey={(key) => handleArrowKey(key, 'subtracted', stepIndex, digitIndex)}
                    />
                  ))}
                </Box>

                {/* Line */}
                <Box sx={{ 
                  borderTop: '2px solid var(--text-primary)',
                  margin: '0.5rem 0',
                  width: '100%'
                }} />

                {/* Remainder */}
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                  {Array(dividendLength).fill(0).map((_, digitIndex) => (
                    <DigitInput
                      key={`remainder-${stepIndex}-${digitIndex}`}
                      value={answers.remainders[stepIndex]?.[digitIndex] || ''}
                      onChange={(value) => handleDigitChange(value, 'remainder', stepIndex, digitIndex)}
                      onKeyDown={handleKeyDown}
                      inputRef={inputRefs.current.remainder?.[stepIndex]?.[digitIndex]}
                      onArrowKey={(key) => handleArrowKey(key, 'remainder', stepIndex, digitIndex)}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default InputRows;
