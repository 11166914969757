import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useParams } from 'react-router-dom';

const ANSWERS_TO_LEVEL_UP = 10;

export default function usePracticeProgress(topic, levelId, timeLimit) {
  const { currentUser, recordActivity } = useAuth();
  const { grade } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(1);
  const [key, setKey] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [levelComplete, setLevelComplete] = useState(false);
  const [bestTime, setBestTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(timeLimit);

  // Set initial start time and time left
  useEffect(() => {
    if (!levelComplete) {
      setStartTime(Date.now());
      setTimeLeft(timeLimit);
    }
  }, [levelComplete, timeLimit]);

  // Timer effect
  useEffect(() => {
    if (!startTime || levelComplete) return;

    const interval = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const remaining = timeLimit - elapsed;

      if (remaining <= 0) {
        clearInterval(interval);
        setTimeLeft(0);
        setCompleted(1);
        setStartTime(Date.now());
        setError('Time\'s up! Progress reset.');
        setTimeout(() => setError(''), 2000);
        setKey(prev => prev + 1);
      } else {
        setTimeLeft(remaining);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [startTime, timeLimit, levelComplete]);

  // Load initial progress
  useEffect(() => {
    async function fetchProgress() {
      if (!currentUser || !levelId) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Get best time for this level
          const currentBestTime = userData.levelBestTimes?.[levelId];
          setBestTime(currentBestTime);
          setCompleted(1);
        }
      } catch (error) {
        console.error('Error fetching progress:', error);
        setError('Failed to load progress');
      } finally {
        setLoading(false);
      }
    }

    fetchProgress();
  }, [topic, currentUser, levelId, grade]);

  const markLevelComplete = async () => {
    if (!currentUser || !levelId) return;

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const completionTime = Math.floor((Date.now() - startTime) / 1000);
      
      // Only update if it's a new best time or first completion
      const userDoc = await getDoc(userRef);
      const currentBestTime = userDoc.data()?.levelBestTimes?.[levelId];
      
      if (!currentBestTime || completionTime < currentBestTime) {
        await updateDoc(userRef, {
          [`levelBestTimes.${levelId}`]: completionTime
        });
        setBestTime(completionTime);
      }

      setLevelComplete(true);
      setCompleted(ANSWERS_TO_LEVEL_UP);
    } catch (error) {
      console.error('Error marking level complete:', error);
      setError('Failed to save progress');
    }
  };

  const handleAnswer = async (selectedAnswer, correctAnswer) => {
    if (levelComplete) return; // Don't process answers if level is complete
    
    await recordActivity();

    // Check for exact match between selected and correct answer
    const isCorrect = selectedAnswer === 'correct' || 
                     selectedAnswer === correctAnswer ||
                     selectedAnswer.toString() === correctAnswer.toString();

    if (isCorrect) {
      const newCompleted = completed + 1;

      if (newCompleted === ANSWERS_TO_LEVEL_UP) {
        // Mark the level as completed
        await markLevelComplete();
      } else {
        setCompleted(newCompleted);
        if (topic !== 'counting') {
          setKey(prev => prev + 1);
        }
      }
    } else {
      // For incorrect answers, let PracticeExercise handle the state reset
      setCompleted(1);
      setStartTime(Date.now());
      if (topic !== 'counting') {
        setKey(prev => prev + 1);
      }
    }
  };

  const resetProgress = () => {
    setLevelComplete(false);
    setCompleted(1);
    setStartTime(Date.now());
    setTimeLeft(timeLimit);
    setKey(prev => prev + 1);
  };

  return {
    loading,
    error,
    progress: { completed },
    key,
    handleAnswer,
    ANSWERS_TO_LEVEL_UP,
    levelComplete,
    bestTime,
    startTime,
    timeLeft,
    resetProgress
  };
}
