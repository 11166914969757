// Generates steps for long division using standard algorithm
export const calculateSteps = (num1, num2, quotient) => {
  if (!num1 || !num2 || !quotient) return [];

  const steps = [];
  const num1Str = num1.toString();
  const digits = num1Str.split('').map(Number);
  
  let startPos = 0;
  let currentNumber = digits[0].toString();
  let currentValue = parseInt(currentNumber);
  
  // Keep taking digits until we can divide
  while (currentValue < num2 && startPos < digits.length - 1) {
    startPos++;
    currentNumber += digits[startPos].toString();
    currentValue = parseInt(currentNumber);
  }
  
  // Process each step
  while (startPos < digits.length) {
    // Calculate division for current number
    let quotientDigit = Math.floor(currentValue / num2);
    let subtracted = quotientDigit * num2;
    let remainder = currentValue - subtracted;
    
    // Add step with alignment info
    steps.push({
      subtracted: subtracted.toString(),
      remainder: remainder.toString(),
      quotientDigit: quotientDigit.toString(),
      startPos: startPos, // Position in the dividend we're working with
      length: 1, // We're working with one digit at a time
      nextDigit: digits[startPos + 1]?.toString() // Next digit to bring down
    });
    
    // Set up next step - join remainder with next digit
    startPos++;
    if (startPos < digits.length) {
      currentNumber = remainder.toString() + digits[startPos].toString();
      currentValue = parseInt(currentNumber);
    }
  }
  
  return steps;
};
