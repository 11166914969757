import { roll } from './utils';

export const addSubtract20 = {
  "Add/Subtract up to 20": {
    levels: [
      { id: "g2y6f1l5", description: "Adding numbers up to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num1 = roll(1, 9);
        const num2 = roll(1, 10 - num1);
        return { num1, num2, operation: '+' };
      }},
      { id: "m1x8v2q7", description: "Subtracting numbers up to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const num1 = roll(3, 9);
        const num2 = roll(2, num1 - 1);
        return { num1, num2, operation: '-' };
      }},
      { id: "h8n4q7w2", description: "Addition Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "w4y7h1n6", description: "Subtract Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 60, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const whole = part1 + part2;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: whole, 
          num2: part1, 
          operation: '-',
          missingPosition 
        };
      }},
      { id: "h8n4q7p9", description: "Complements of 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = 10 - part1;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { 
        id: "m5n9p2q3", 
        description: "Change 9 into 10", 
        exerciseType: "BalancedEquationPractice", 
        timeLimit: 45, 
        inputs: () => {
          const num1 = 9;
          const num2 = roll(11-num1, 9);
          const leftSide = 10;
          return {
            num1,
            num2,
            leftSide
          };
        }
      },
      { 
        id: "m5naabq3", 
        description: "Change 8 into 10", 
        exerciseType: "BalancedEquationPractice", 
        timeLimit: 45, 
        inputs: () => {
          const num1 = 8;
          const num2 = roll(11-num1, 9);
          const leftSide = 10;
          return {
            num1,
            num2,
            leftSide
          };
        }
      },
      { 
        id: "m5n9p2f3", 
        description: "Change 6 or 7 into 10", 
        exerciseType: "BalancedEquationPractice", 
        timeLimit: 45, 
        inputs: () => {
          const num1 = roll(6,7);
          const num2 = roll(11-num1, 9);
          const leftSide = 10;
          return {
            num1,
            num2,
            leftSide
          };
        }
      },
      { 
        id: "m5n9p2q7", 
        description: "Change 6, 7, 8, or 9 into 10", 
        exerciseType: "BalancedEquationPractice", 
        timeLimit: 45, 
        inputs: () => {
          const num1 = roll(7,9);
          const num2 = roll(11-num1, 9);
          const leftSide = 10;
          return {
            num1,
            num2,
            leftSide
          };
        }
      },
      { id: "g2y6r1a5", description: "Adding numbers up to 20", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(5,9);
        return { 
          num1: roll(11 - part1, 9), 
          num2: part1, 
          operation: '+'
        }
      }},
      { id: "f3k9p2m4", description: "Adding up from 8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(8, 9);
        const part2 = roll(11 - part1, 9);
        const positions = ['num1', 'num2'];
        const missingPosition = positions[roll(0,1)];
        return { 
          num1: missingPosition === 'num1' ? part2 : part1, 
          num2: missingPosition === 'num2' ? part2 : part1, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "f3k9p2m3", description: "Adding up from 6 or 7", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(6,7);
        const part2 = roll(11 - part1, 9);
        const positions = ['num1', 'num2'];
        const missingPosition = positions[roll(0,1)];
        return { 
          num1: missingPosition === 'num1' ? part2 : part1, 
          num2: missingPosition === 'num2' ? part2 : part1, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "f3k9p2m0", description: "Adding up from 3, 4, or 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(3,5);
        const part2 = roll(11 - part1, 9);
        const positions = ['num1', 'num2'];
        const missingPosition = positions[roll(0,1)];
        return { 
          num1: missingPosition === 'num1' ? part2 : part1, 
          num2: missingPosition === 'num2' ? part2 : part1, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "d6b2c823", description: "Subtract through 10 by 8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(8,9);
        return { 
          num1: roll(11, 9 + part1), 
          num2: part1, 
          operation: '-'
        }
      }},
      { id: "d6b2c864", description: "Subtract through 10 by 6 or 7", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(6,7);
        return { 
          num1: roll(11, 9 + part1), 
          num2: part1, 
          operation: '-'
        }
      }},
      { id: "d6b2c112", description: "Subtract through 10 by 3, 4, or 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(3,5);
        return { 
          num1: roll(11, 9 + part1), 
          num2: part1, 
          operation: '-'
        }
      }},
      { id: "d6b2c812", description: "Subtract through 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const part1 = roll(3,9);
        return { 
          num1: roll(11, 9 + part1), 
          num2: part1, 
          operation: '-'
        }
      }} 
    ]
  }
};
