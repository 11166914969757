import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box } from '@mui/material';

export const NamingFractionDisplay = ({ numerator, denominator, isMixedNumber }) => {
    
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      gap: '1rem',
      padding: '1rem',
      fontFamily: 'monospace'
    }}>
      <Typography sx={{ fontSize: '1.2rem', color: 'var(--text-primary)' }}>
        The correct answer is:
      </Typography>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderColor: 'var(--success-color)',
        gap: '0.25rem'
      }}>
        {isMixedNumber ? (
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            color: 'var(--success-color)',
            fontSize: '2rem'
          }}>
            <span>{Math.floor(numerator / denominator)}</span>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem'
            }}>
              <span>{numerator % denominator}</span>
              <Box sx={{ 
                width: '2rem',
                height: '2px',
                backgroundColor: 'var(--success-color)',
                margin: '0.25rem 0'
              }} />
              <span>{denominator}</span>
            </Box>
          </Box>
        ) : (
          <>
            <Typography sx={{ fontSize: '2rem', color: 'var(--success-color)' }}>
              {numerator}
            </Typography>
            <Box sx={{ 
              width: '2rem',
              height: '2px',
              backgroundColor: 'var(--success-color)',
              margin: '0.25rem 0'
            }} />
            <Typography sx={{ fontSize: '2rem', color: 'var(--success-color)' }}>
              {denominator}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const NamingFractionsPractice = ({ inputs: inputsFn, onAnswer, onKeyDown }) => {
    const [userWholeNumber, setUserWholeNumber] = useState('');
    const [userNumerator, setUserNumerator] = useState('');
    const [userDenominator, setUserDenominator] = useState('');
    const [problem, setProblem] = useState(null);
    const [prevProblem, setPrevProblem] = useState(null);
    const numeratorInputRef = useRef(null);

    const getNewProblem = () => {
        if (!inputsFn) return null;
        let newProblem;
        let attempts = 0;
        const maxAttempts = 1000;

        do {
            newProblem = inputsFn();
            attempts++;

            // If we've tried too many times, just use the last generated problem
            if (attempts >= maxAttempts) {
                break;
            }

        } while (
            prevProblem && 
            attempts < maxAttempts && (
                newProblem.shape === prevProblem.shape ||
                (newProblem.numerator === prevProblem.numerator && 
                 newProblem.denominator === prevProblem.denominator)
            )
        );

        return newProblem;
    };

    useEffect(() => {
        if (!problem) {
            const newProblem = getNewProblem();
            setProblem(newProblem);
        }
        // eslint-disable-next-line 
    }, []);

    const handleInputChange = (value, setter) => {
        if (/^\d*$/.test(value)) {
            setter(value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (problem.isMixedNumber) {
            const whole = parseInt(userWholeNumber || '0', 10);
            const num = parseInt(userNumerator || '0', 10);
            const den = parseInt(userDenominator || '0', 10);
            
            const userImproperNum = whole * den + num;
            const isCorrect = userImproperNum === problem.numerator && den === problem.denominator;
            
            const userAnswer = `${whole} ${num}/${den}`;
            const expectedWhole = Math.floor(problem.numerator / problem.denominator);
            const expectedRemainder = problem.numerator % problem.denominator;
            const expectedAnswer = `${expectedWhole} ${expectedRemainder}/${problem.denominator}`;

            if (isCorrect) {
                onAnswer('correct', expectedAnswer);
            } else {
                onAnswer(userAnswer, expectedAnswer, {
                    numerator: problem.numerator,
                    denominator: problem.denominator,
                    isMixedNumber: true
                });
            }
        } else {
            const num = userNumerator || '0';
            const den = userDenominator || '0';
            
            const userAnswer = `${num}/${den}`;
            const expectedAnswer = `${problem.numerator}/${problem.denominator}`;
            const isCorrect = parseInt(num) === problem.numerator && parseInt(den) === problem.denominator;

            if (isCorrect) {
                onAnswer('correct', expectedAnswer);
            } else {
                onAnswer(userAnswer, expectedAnswer, {
                    numerator: problem.numerator,
                    denominator: problem.denominator
                });
            }
        }

        // Store current problem as previous before generating new one
        setPrevProblem(problem);
        const newProblem = getNewProblem();
        setProblem(newProblem);
        
        setUserWholeNumber('');
        setUserNumerator('');
        setUserDenominator('');
        numeratorInputRef.current?.focus();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const renderCircle = (index, filled) => {
        const segments = [];
        const radius = 50;
        const centerX = 60;
        const centerY = 60;

        for (let i = 0; i < problem.denominator; i++) {
            const startAngle = (i * 360) / problem.denominator - 90;
            const endAngle = ((i + 1) * 360) / problem.denominator - 90;
            const startRad = (startAngle * Math.PI) / 180;
            const endRad = (endAngle * Math.PI) / 180;

            const x1 = centerX + radius * Math.cos(startRad);
            const y1 = centerY + radius * Math.sin(startRad);
            const x2 = centerX + radius * Math.cos(endRad);
            const y2 = centerY + radius * Math.sin(endRad);

            const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

            const pathData = [
                `M ${centerX} ${centerY}`,
                `L ${x1} ${y1}`,
                `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`,
                'Z'
            ].join(' ');

            segments.push(
                <path
                    key={`${index}-${i}`}
                    d={pathData}
                    fill={i < (filled ? problem.denominator : 0) ? "var(--primary-light)" : "var(--bg-primary)"}
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                />
            );
        }

        return (
            <svg key={index} width="120" height="120" viewBox="0 0 120 120">
                {segments}
            </svg>
        );
    };

    const renderRectangle = (index, filled) => {
        const segments = [];
        const width = 100;
        const height = 50;
        const segmentWidth = width / problem.denominator;

        for (let i = 0; i < problem.denominator; i++) {
            segments.push(
                <rect
                    key={`${index}-${i}`}
                    x={10 + i * segmentWidth}
                    y={10}
                    width={segmentWidth}
                    height={height}
                    fill={i < (filled ? problem.denominator : 0) ? "var(--primary-light)" : "var(--bg-primary)"}
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                />
            );
        }

        return (
            <svg key={index} width="120" height="70" viewBox="0 0 120 70">
                {segments}
            </svg>
        );
    };

    const renderNumberLine = () => {
        const width = 360;
        const height = 100;
        const lineY = height / 2;
        const wholeNumbers = Math.ceil(problem.numerator / problem.denominator);
        const elements = [];

        // Calculate segment width based on whole numbers
        const segmentWidth = (width - 40) / (wholeNumbers * problem.denominator);

        // Main line
        elements.push(
            <line
                key="mainLine"
                x1="20"
                y1={lineY}
                x2={width - 20}
                y2={lineY}
                stroke="var(--text-primary)"
                strokeWidth="2"
            />
        );

        // Tick marks and numbers for whole numbers and fractions
        for (let i = 0; i <= wholeNumbers * problem.denominator; i++) {
            const x = 20 + (i * segmentWidth);
            const isWholeNumber = i % problem.denominator === 0;

            // Tick mark
            elements.push(
                <line
                    key={`tick-${i}`}
                    x1={x}
                    y1={lineY - (isWholeNumber ? 15 : 10)}
                    x2={x}
                    y2={lineY + (isWholeNumber ? 15 : 10)}
                    stroke="var(--text-primary)"
                    strokeWidth={isWholeNumber ? "2" : "1"}
                />
            );

            // Only add numbers for whole numbers
            if (isWholeNumber) {
                elements.push(
                    <text
                        key={`text-${i}`}
                        x={x}
                        y={lineY + 30}
                        textAnchor="middle"
                        fill="var(--text-primary)"
                        fontSize="14"
                    >
                        {i / problem.denominator}
                    </text>
                );
            }
        }

        // Highlight segments
        for (let i = 0; i < problem.numerator; i++) {
            elements.push(
                <line
                    key={`highlight-${i}`}
                    x1={20 + (i * segmentWidth)}
                    y1={lineY}
                    x2={20 + ((i + 1) * segmentWidth)}
                    y2={lineY}
                    stroke="var(--primary-light)"
                    strokeWidth="4"
                />
            );
        }

        return (
            <svg width={width} height={height}>
                {elements}
            </svg>
        );
    };

    const renderShapes = () => {
        if (!problem) return null;

        if (problem.shape === 'numberline') {
            return renderNumberLine();
        }

        const wholeShapes = Math.floor(problem.numerator / problem.denominator);
        const remainder = problem.numerator % problem.denominator;
        const shapes = [];

        // Add whole shapes
        for (let i = 0; i < wholeShapes; i++) {
            shapes.push(
                problem.shape === 'circle' 
                    ? renderCircle(i, true)
                    : renderRectangle(i, true)
            );
        }

        // Add partial shape if there's a remainder
        if (remainder > 0) {
            const partialShape = problem.shape === 'circle'
                ? renderCircle(wholeShapes, false)
                : renderRectangle(wholeShapes, false);

            const segments = partialShape.props.children;
            const filledSegments = segments.map((segment, i) => ({
                ...segment,
                props: {
                    ...segment.props,
                    fill: i < remainder ? "var(--primary-light)" : "var(--bg-primary)"
                }
            }));

            shapes.push(React.cloneElement(partialShape, {}, filledSegments));
        }

        return (
            <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '10px', 
                justifyContent: 'center',
                maxWidth: '400px',
                margin: '0 auto'
            }}>
                {shapes}
            </div>
        );
    };

    if (!problem) return null;

    return (
        <div className="content-box">
            <Typography className="practice-title">
                What fraction is shown below?
            </Typography>
            <div className="visual-container">
                {renderShapes()}
            </div>
            <form onSubmit={handleSubmit} className="fraction-form">
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center' }}>
                    {problem.isMixedNumber && (
                        <div className="fraction-input">
                            <input
                                type="text"
                                value={userWholeNumber}
                                onChange={(e) => handleInputChange(e.target.value, setUserWholeNumber)}
                                onKeyDown={handleKeyDown}
                                autoFocus
                                ref={numeratorInputRef}
                            />
                        </div>
                    )}
                    <div className="fraction-input">
                        <input
                            type="text"
                            value={userNumerator}
                            onChange={(e) => handleInputChange(e.target.value, setUserNumerator)}
                            onKeyDown={handleKeyDown}
                            autoFocus={!problem.isMixedNumber}
                            ref={!problem.isMixedNumber ? numeratorInputRef : null}
                        />
                        <hr/>
                        <input
                            type="text"
                            value={userDenominator}
                            onChange={(e) => handleInputChange(e.target.value, setUserDenominator)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NamingFractionsPractice;
