import { TextField, Typography } from '@mui/material';
import { formatNumericInput, handleEnterKey } from '../../utils/inputUtils';

export default function NumericInput({
  question,
  value,
  onChange,
  onSubmit,
  showAnswerDialog,
  handleCloseDialog,
  autoFocus = true,
  className = '',
  style = {}
}) {
  const handleKeyDown = (e) => {
    handleEnterKey(e, showAnswerDialog, handleCloseDialog, onSubmit, value);
  };

  const handleChange = (e) => {
    const formattedValue = formatNumericInput(e.target.value);
    onChange(formattedValue);
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      onSubmit(null, value?.trim());
    }} className="content-box">
      <Typography className="practice-title">
        {question}
      </Typography>
      <TextField
        variant="outlined"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        className={`number-input ${className}`}
        style={style}
      />
    </form>
  );
}
