import React from 'react';
import { Typography } from '@mui/material';
import './styles.css';

const EquivalentExpressionDisplay = ({ number, secondNumber, operation, answer, steps }) => {
  const isThreeDigit = number.toString().length === 3 || secondNumber.toString().length === 3;

  return (
    <div className="expression-display">
      {/* Original expression */}
      <Typography className="expression-text">
        {`${number} ${operation} ${secondNumber} = ${answer}`}
      </Typography>

      {/* Broken down expression */}
      <div className="broken-expression">
        {operation === '+' || operation === '-' ? (
          <>
            {operation === '-' && '('}
            <Typography className="expression-text">
              {steps.step1First}
            </Typography>
            <Typography className="expression-text">
              {` ${operation} `}
            </Typography>
            <Typography className="expression-text">
              {steps.step1Second}
            </Typography>
            {operation === '-' ? ') + (' : ' + '}
            <Typography className="expression-text">
              {steps.step1Third}
            </Typography>
            <Typography className="expression-text">
              {` ${operation} `}
            </Typography>
            <Typography className="expression-text">
              {steps.step1Fourth}
            </Typography>
            {isThreeDigit && (
              <>
                {operation === '-' ? ') + (' : ' + '}
                <Typography className="expression-text">
                  {steps.step1Fifth}
                </Typography>
                <Typography className="expression-text">
                  {` ${operation} `}
                </Typography>
                <Typography className="expression-text">
                  {steps.step1Sixth}
                </Typography>
              </>
            )}
            {operation === '-' && ')'}
          </>
        ) : steps?.format === 'expanded' ? (
          <>
            <Typography className="expression-text">
              {`(${steps.split.firstPart} × ${steps.split.secondNumberParts.tens})`}
            </Typography>
            <Typography className="expression-text">
              {` + (${steps.split.firstPart} × ${steps.split.secondNumberParts.ones})`}
            </Typography>
            <Typography className="expression-text">
              {` + (${steps.split.secondPart} × ${steps.split.secondNumberParts.tens})`}
            </Typography>
            <Typography className="expression-text">
              {` + (${steps.split.secondPart} × ${steps.split.secondNumberParts.ones})`}
            </Typography>
          </>
        ) : (
          <>
            <Typography className="expression-text">
              {`(${steps.step1First} × ${secondNumber})`}
            </Typography>
            <Typography className="expression-text">
              {` + (${steps.step1Second} × ${secondNumber})`}
            </Typography>
            {isThreeDigit && (
              <Typography className="expression-text">
                {` + (${steps.step1Third} × ${secondNumber})`}
              </Typography>
            )}
          </>
        )}
      </div>

      {/* Intermediate steps */}
      {steps?.format === 'expanded' ? (
        <>
          <Typography className="intermediate-steps">
            {`= ${steps.step2First} + ${steps.step2Second} + ${steps.step2Third} + ${steps.step2Fourth}`}
          </Typography>
          <Typography className="intermediate-steps">
            {`= ${parseInt(steps.step2First) + parseInt(steps.step2Second)} + ${parseInt(steps.step2Third) + parseInt(steps.step2Fourth)}`}
          </Typography>
        </>
      ) : (
        <Typography className="intermediate-steps">
          {`= ${steps.step2First} + ${steps.step2Second}${isThreeDigit ? ` + ${steps.step2Third}` : ''}`}
        </Typography>
      )}

      {/* Final result */}
      <Typography className="final-result">
        {`= ${answer}`}
      </Typography>
    </div>
  );
};

export default EquivalentExpressionDisplay;
