import React, { useState, useEffect, useRef } from 'react';
import { validateAnswers, createEmptyAnswers } from './long-division/Validator';
import { createRefs, handleArrowKey } from './long-division/InputRows';
import InputRows from './long-division/InputRows';
import Display from './long-division/Display';

export { Display as LongDivisionDisplay };

const LongDivisionPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn }) => {
  const [problem, setProblem] = useState(null);
  const [answers, setAnswers] = useState({
    quotient: [],
    subtractedValues: [],
    remainders: [],
    finalRemainder: ''
  });
  
  const inputRefs = useRef(createRefs(1, 1));

  useEffect(() => {
    if (typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      resetAnswers(newProblem);
    }
  }, [level, currentProgress, inputsFn]);

  useEffect(() => {
    if (problem) {
      const dividendLength = Math.floor(Math.log10(problem.num1)) + 1;
      const quotientLength = Math.floor(Math.log10(Math.floor(problem.num1 / problem.num2))) + 1;
      inputRefs.current = createRefs(dividendLength, quotientLength);
    }
  }, [problem]);

  const resetAnswers = (newProblem) => {
    const dividendLength = Math.floor(Math.log10(newProblem.num1)) + 1;
    const quotientLength = Math.floor(Math.log10(Math.floor(newProblem.num1 / newProblem.num2))) + 1;
    setAnswers(createEmptyAnswers(dividendLength, quotientLength));
  };

  const handleDigitChange = (value, type, stepIndex, digitIndex) => {
    setAnswers(prev => {
      const newAnswers = { ...prev };
      
      if (type === 'quotient') {
        const newQuotient = [...prev.quotient];
        newQuotient[stepIndex] = value;
        newAnswers.quotient = newQuotient;
      } else if (type === 'subtracted') {
        const newSubtracted = prev.subtractedValues.map(row => [...row]);
        newSubtracted[stepIndex][digitIndex] = value;
        newAnswers.subtractedValues = newSubtracted;
      } else if (type === 'remainder') {
        const newRemainders = prev.remainders.map(row => [...row]);
        newRemainders[stepIndex][digitIndex] = value;
        newAnswers.remainders = newRemainders;
      } else if (type === 'finalRemainder') {
        newAnswers.finalRemainder = value;
      }
      
      return newAnswers;
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      const validation = validateAnswers(answers, problem);
      const { num1, num2 } = problem;
      const expectedQuotient = Math.floor(num1 / num2);
      const expectedRemainder = num1 % num2;
      const expectedSteps = validation.expectedSteps;

      // Format expected answer to include subtracted values
      const expectedAnswer = [
        expectedQuotient.toString(),
        expectedRemainder || '',  // Allow blank for zero remainder
        ...expectedSteps.map(step => step.subtracted).filter(s => s !== '')
      ].join(',');

      // Format user answer to match expected format
      const userAnswer = [
        ...validation.nonZeroInputs.quotient,
        validation.nonZeroInputs.finalRemainder || '',  // Allow blank for zero remainder
        ...validation.nonZeroInputs.subtractedValues.flat().filter(x => x !== '')
      ].join(',');

      onAnswer(
        validation.isCorrect ? 'correct' : userAnswer, 
        expectedAnswer,
        validation.isCorrect ? null : {
          num1,
          num2,
          quotient: expectedQuotient,
          remainder: expectedRemainder,
          steps: validation.expectedSteps
        }
      );

      // Generate new problem regardless of whether answer was correct
      if (typeof inputsFn === 'function') {
        const newProblem = inputsFn();
        setProblem(newProblem);
        resetAnswers(newProblem);
      }
    }
  };

  const handleArrowNavigation = (key, type, stepIndex, digitIndex) => {
    const nextRef = handleArrowKey(key, type, stepIndex, digitIndex, inputRefs.current);
    if (nextRef?.current) {
      nextRef.current.focus();
    }
  };

  if (!problem) return null;

  return (
    <InputRows
      num1={problem.num1}
      num2={problem.num2}
      answers={answers}
      inputRefs={inputRefs}
      handleDigitChange={handleDigitChange}
      handleKeyDown={handleKeyDown}
      handleArrowKey={handleArrowNavigation}
    />
  );
};

export default LongDivisionPractice;
