export default function RoundingPractice({ inputs }) {
  // If inputs is a function, call it to get the actual inputs object
  const actualInputs = typeof inputs === 'function' ? inputs() : inputs;
  let { number, roundTo } = actualInputs;

  const getPlaceName = (place) => {
    // Handle decimal places
    if (place < 1) {
      switch(place) {
        case 0.1: return "tenth";
        case 0.01: return "hundredth";
        case 0.001: return "thousandth";
        case 0.0001: return "ten thousandth";
        case 0.00001: return "hundred thousandth";
        case 0.000001: return "millionth";
        default: return `${1/place}th`;
      }
    }
    // Handle whole number places
    switch(place) {
      case 1: return "ones";
      case 10: return "ten";
      case 100: return "hundred";
      case 1000: return "thousand";
      case 10000: return "ten thousand";
      case 100000: return "hundred thousand";
      case 1000000: return "million";
      case 10000000: return "ten million";
      case 100000000: return "hundred million";
      case 1000000000: return "billion";
      default: return `${place}`;
    }
  };

  // Round the number to the specified place value
  const roundToPlace = (num, place) => {
    if (place >= 1) {
      // For whole numbers, divide by place value, round, then multiply back
      return Math.round(num / place) * place;
    } else {
      // For decimals, multiply to shift decimal point right, round, then shift back
      const shift = 1 / place;
      return Math.round(num * shift) / shift;
    }
  };

  // Calculate the rounded answer
  const numericAnswer = roundToPlace(number, roundTo);
  const placeName = getPlaceName(roundTo);

  return {
    problem: {
      question: `Round ${number.toLocaleString()} to the nearest ${placeName}.`,
      answer: numericAnswer
    },
  };
}
