import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

const FactorPractice = ({ level, onAnswer, currentProgress, inputs: inputsFn, onKeyDown }) => {
  const [factors, setFactors] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [number, setNumber] = useState(null);
  const [shouldFocus, setShouldFocus] = useState(false);
  const inputRef = useRef(null);

  // Reset state and set initial number when level or progress changes
  useEffect(() => {
    if (typeof inputsFn !== 'function') return;
    const { number } = inputsFn();
    setNumber(number);
    calculateFactors(number);
    setShouldFocus(true); // Set initial focus
  }, [level, currentProgress, inputsFn]);

  // Focus input after Enter is pressed or initially
  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus]);

  const calculateFactors = (num) => {
    const factorArray = [];
    for (let i = 1; i <= num; i++) {
      if (num % i === 0) {
        factorArray.push(i);
      }
    }
    setFactors(factorArray);
    setUserInput('');
  };

  const handleInputChange = (value) => {
    // Allow numbers, commas, and spaces
    const sanitizedValue = value.replace(/[^0-9,\s]/g, '');
    // Remove duplicate separators
    const cleanedValue = sanitizedValue.replace(/[,\s]+/g, ' ');
    setUserInput(cleanedValue);
  };

  const validateFactors = (input) => {
    // Convert input string to array of numbers
    const userFactors = input
      .trim()
      .split(/[,\s]+/)
      .map(num => parseInt(num, 10))
      .filter(num => !isNaN(num))
      .sort((a, b) => a - b);

    // Check if arrays are equal
    return (
      userFactors.length === factors.length &&
      userFactors.every((factor, index) => factor === factors[index])
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const isCorrect = validateFactors(userInput);
    onAnswer(
      isCorrect ? 'correct' : userInput.trim(),
      factors.join(',')
    );

    if (isCorrect) {
      // Reset for next problem
      if (typeof inputsFn === 'function') {
        const { number: newNumber } = inputsFn();
        setNumber(newNumber);
        calculateFactors(newNumber);
        setShouldFocus(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  if (!number) return null;

  return (
    <div className="content-box">
      <h2 style={{ color: 'var(--text-primary)', marginBottom: '1rem' }}>
        Find all factors of {number}
      </h2>
      <form onSubmit={handleSubmit} style={{ 
        display: 'flex', 
        justifyContent: 'center',
        margin: '2rem 0'
      }}>
        <TextField
          className="number-input"
          value={userInput}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          autoFocus
          placeholder=""
          inputProps={{
            style: { textAlign: 'center' }
          }}
          sx={{ minWidth: '300px' }}
        />
      </form>
    </div>
  );
};

export default FactorPractice;
