import { roll } from './utils';

const gcd = (a, b) => {
  while (b) {
    [a, b] = [b, a % b];
  }
  return a;
};

const simplifyFraction = (num, den) => {
  const divisor = gcd(Math.abs(num), Math.abs(den));
  return [num / divisor, den / divisor];
};

const multiplyFractions = (n1, d1, n2, d2) => {
  return simplifyFraction(n1 * n2, d1 * d2);
};

const divideFractions = (n1, d1, n2, d2) => {
  return simplifyFraction(n1 * d2, d1 * n2);
};

export const fractionsmultidiv = {
  "Multiply/Divide Fractions": {
    levels: [
      {
        id: "g5fmult1",
        description: "Multiply Fraction by Whole Number",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 120,
        inputs: () => {
          const den1 = roll(2, 8);
          const num1 = roll(1, den1 - 1);
          const num2 = roll(2, 6); // whole number
          const [ansNum, ansDen] = multiplyFractions(num1, den1, num2, 1);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: 1 },
            operation: '×',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmult2",
        description: "Multiply Fractions",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 120,
        inputs: () => {
          const den1 = roll(2, 8);
          const den2 = roll(2, 8);
          const num1 = roll(1, den1 - 1);
          const num2 = roll(1, den2 - 1);
          const [ansNum, ansDen] = multiplyFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '×',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fdiv1",
        description: "Divide Fraction by Whole Number",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 120,
        inputs: () => {
          const den1 = roll(2, 8);
          const num1 = roll(1, den1 - 1);
          const num2 = roll(2, 6); // whole number
          const [ansNum, ansDen] = divideFractions(num1, den1, num2, 1);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: 1 },
            operation: '÷',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fdiv2",
        description: "Divide Fractions",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 180,
        inputs: () => {
          const den1 = roll(2, 8);
          const den2 = roll(2, 8);
          const num1 = roll(1, den1 - 1);
          const num2 = roll(1, den2 - 1);
          const [ansNum, ansDen] = divideFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '÷',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmixmult1",
        description: "Multiply Mixed Number by Whole Number",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 180,
        inputs: () => {
          const den1 = roll(2, 8);
          const whole1 = roll(1, 3);
          const num1 = whole1 * den1 + roll(1, den1 - 1);
          const num2 = roll(2, 4); // whole number
          const [ansNum, ansDen] = multiplyFractions(num1, den1, num2, 1);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: 1 },
            operation: '×',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmixmult2",
        description: "Multiply Mixed Numbers",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 180,
        inputs: () => {
          const den1 = roll(3, 6);
          const den2 = roll(3, 6);
          const whole1 = roll(1, 3);
          const whole2 = roll(1, 3);
          const num1 = whole1 * den1 + roll(2, den1 - 1);
          const num2 = whole2 * den2 + roll(2, den2 - 1);
          const [ansNum, ansDen] = multiplyFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '×',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmixdiv1",
        description: "Divide Mixed Number by Whole Number",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 180,
        inputs: () => {
          const den1 = roll(3, 8);
          const whole1 = roll(1, 3);
          const num1 = whole1 * den1 + roll(2, den1 - 1);
          const num2 = roll(2, 4); // whole number
          const [ansNum, ansDen] = divideFractions(num1, den1, num2, 1);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: 1 },
            operation: '÷',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmixdiv2",
        description: "Divide Mixed Numbers",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 180,
        inputs: () => {
          const den1 = roll(3, 6);
          const den2 = roll(3, 6);
          const whole1 = roll(1, 3);
          const whole2 = roll(1, 3);
          const num1 = whole1 * den1 + roll(2, den1 - 1);
          const num2 = whole2 * den2 + roll(2, den2 - 1);
          const [ansNum, ansDen] = divideFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '÷',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      }
    ]
  }
};
