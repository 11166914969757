import { roll } from './utils';

export const fractions = {
    "Proper Fractions" : {
      levels : [
        { id: "f9n0u1t2", description: "Name Unit Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: 1,
            denominator,
            shape: ['circle', 'rectangle'][roll(0,1)]
          };
        }},
        { id: "f9nll1t2", description: "Name Unit Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: 1,
            denominator,
            shape: 'numberline'
          };
        }},
        { id: "f9c0m1t1", description: "Compare Unit Fractions", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 90, inputs: () => {
          const den1 = roll(2, 10);
          let den2;
          do {
            den2 = roll(2, 10);
          } while (den2 === den1);

          const num1 = 1;
          const num2 = 1;
          
          const val1 = num1 / den1;
          const val2 = num2 / den2;
          
          let correctAnswer;
          if (val1 < val2) correctAnswer = '<';
          else if (val1 > val2) correctAnswer = '>';
          else correctAnswer = '=';

          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            correctAnswer
          };
        }},
        { id: "f9n0u1t3", description: "Name Proper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(3, 8);
          return {
            numerator: roll(2, denominator - 1),
            denominator,
            shape: ['circle', 'rectangle'][roll(0,1)]
          };
        }},
        { id: "f9nllu1t3", description: "Name Proper Fractions on Number Line", exerciseType: "NamingFractionsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(3, 8);
          return {
            numerator: roll(2, denominator - 1),
            denominator,
            shape:  'numberline'
          };
        }},
        { id: "f9c0m1t3", description: "Compare Fractions with Same Denominator", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 90, inputs: () => {
          const denominator = roll(3, 10);
          
          const num1 = roll(1, denominator - 1);
          let num2;
          do {
            num2 = roll(1, denominator - 1);
          } while (num2 === num1);
          
          const val1 = num1 / denominator;
          const val2 = num2 / denominator;
          
          let correctAnswer;
          if (val1 < val2) correctAnswer = '<';
          else if (val1 > val2) correctAnswer = '>';
          else correctAnswer = '=';

          return {
            fraction1: { numerator: num1, denominator },
            fraction2: { numerator: num2, denominator },
            correctAnswer
          };
        }},
        { id: "f9c0m1t2", description: "Compare Fractions with Same Numerator", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 90, inputs: () => {
          const den1 = roll(3, 10);
          let den2;
          do {
            den2 = roll(3, 10);
          } while (den2 === den1);
          
          let num1;
          if (den1 > den2) {
            num1 = roll(2, Math.floor(den1 / den2));
          } else {
            num1 = roll(2, den1 - 1);
          }
          
          const val1 = num1 / den1;
          const val2 = num1 / den2;
          
          let correctAnswer;
          if (val1 < val2) correctAnswer = '<';
          else if (val1 > val2) correctAnswer = '>';
          else correctAnswer = '=';

          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num1, denominator: den2 },
            correctAnswer
          };
        }},
        { id: "f9add1", description: "Add Fractions with Same Denominator", exerciseType: "FractionOperationsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 8);
          // First number should be less than denominator
          const num1 = roll(1, denominator - 1);
          // Second number should be such that sum is less than denominator
          const num2 = roll(1, denominator - num1 - 1);
          
          return {
            fraction1: { numerator: num1, denominator },
            fraction2: { numerator: num2, denominator },
            operation: '+',
            answer: { 
              numerator: num1 + num2,
              denominator
            }
          };
        }},
        { id: "f9sub1", description: "Subtract Fractions with Same Denominator", exerciseType: "FractionOperationsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 8);
          const minuend = roll(2, denominator - 1);
          const subtrahend = roll(1, minuend);
          const result = minuend - subtrahend;
          
          return {
            fraction1: { numerator: minuend, denominator },
            fraction2: { numerator: subtrahend, denominator },
            operation: '−',
            answer: { 
              numerator: result,
              denominator
            }
          };
        }},
        { id: "f9add2", description: "Add Fractions to Make 1", exerciseType: "FractionOperationsPractice", timeLimit: 90, inputs: () => {
          const denominator = roll(2, 8);
          // First number should be less than denominator
          const num1 = roll(1, denominator - 1);
          // Calculate the complement fraction that adds to 1
          const num2 = denominator - num1;
          
          return {
            fraction1: { numerator: num1, denominator },
            fraction2: { numerator: '?', denominator: '?' }, // Hide the second fraction
            operation: '+',
            answer: { 
              numerator: num2,
              denominator
            }
          };
        }},
        { id: "f9arezt1", description: "Compare to One Half", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 90, inputs: () => {
            const den1 = roll(3, 10)
    
            const num1 = roll(2, den1 - 1);
            
            const val1 = num1 / den1;
            const val2 = 1 / 2;
            
            let correctAnswer;
            if (val1 < val2) correctAnswer = '<';
            else if (val1 > val2) correctAnswer = '>';
            else correctAnswer = '=';
    
            return {
              fraction1: { numerator: num1, denominator: den1 },
              fraction2: { numerator: 1, denominator: 2 },
              correctAnswer
            };
          }},
          { id: "fdaaea1", description: "Compare Missing Pieces", exerciseType: "ComparingFractionsMultipleChoice", timeLimit: 90, inputs: () => {
            const den1 = roll(3, 10);
            let den2;
            do {
              den2 = roll(3, 10);
            } while (den1 === den2);
            
            const val1 = (den1 - 1) / den1;
            const val2 = (den2 - 1) / den2;
            
            let correctAnswer;
            if (val1 < val2) correctAnswer = '<';
            else if (val1 > val2) correctAnswer = '>';
            else correctAnswer = '=';
    
            return {
              fraction1: { numerator: den1 - 1, denominator: den1 },
              fraction2: { numerator: den2 - 1, denominator: den2 },
              correctAnswer
            };
          }},
      ]
    }
};
