import { Typography } from '@mui/material';
import { useState } from 'react';
import { exerciseComponents, customUIComponents, inlineInputComponents } from '../config/practiceComponents';
import TestCountingPractice from '../practices/TestCountingPractice';
import NumericInput from './inputs/NumericInput';
import MultipleChoiceInput from './inputs/MultipleChoiceInput';
import InlineInput from './inputs/InlineInput';
import FeedbackDialog from './feedback/FeedbackDialog';
import { validateAnswer } from '../utils/inputUtils';

// Create a modified version of exerciseComponents for the test
const testExerciseComponents = {
  ...exerciseComponents,
  CountingPractice: TestCountingPractice
};

export default function PracticeExercise({
  levelData, 
  currentProblem, 
  inputValue, 
  setInputValue, 
  handleAnswer,
  level,
  progress,
  lastProblemRef,
  isTest = false
}) {
  const [showAnswerDialog, setShowAnswerDialog] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(null);
  const [pendingAnswer, setPendingAnswer] = useState(null);
  const [resetKey, setResetKey] = useState(0);
  const [storedWrongAnswer, setStoredWrongAnswer] = useState(null);
  const [storedProblem, setStoredProblem] = useState(null);

  const handleCloseDialog = () => {
    if (showAnswerDialog) {
      setShowAnswerDialog(false);
      if (pendingAnswer) {
        handleAnswer(pendingAnswer.input, pendingAnswer.answer);
        setPendingAnswer(null);
        setResetKey(prev => prev + 1);
        if (wrongAnswer) {}
      }
    }
  };

  const handleAnswerWithPopup = (selectedAnswer, correctAnswer, problemData) => {
    const isCorrect = validateAnswer(selectedAnswer, correctAnswer);
    
    // Skip feedback for quizzes
    if (isTest) {
      handleAnswer(isCorrect ? 'correct' : selectedAnswer, correctAnswer);
      return;
    }
    
    if (!isCorrect) {
      setWrongAnswer(correctAnswer);
      setStoredWrongAnswer(correctAnswer);
      setShowAnswerDialog(true);
      setPendingAnswer({ input: selectedAnswer, answer: correctAnswer });
      if (problemData) {
        setStoredProblem(problemData);
      }
    } else {
      handleAnswer('correct', correctAnswer);
    }
  };

  const handleSubmit = (e, customInput = null) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    
    const correctAnswer = currentProblem.problem.answer;
    const input = customInput || inputValue.trim();
    
    let isCorrect = false;
    if (Array.isArray(correctAnswer)) {
      isCorrect = correctAnswer.includes(input);
      // Skip feedback for quizzes
      if (isTest) {
        handleAnswer(isCorrect ? 'correct' : input, correctAnswer[0]);
      } else if (!isCorrect) {
        setWrongAnswer(correctAnswer[0]);
        setStoredWrongAnswer(correctAnswer[0]);
        setShowAnswerDialog(true);
        setPendingAnswer({ input, answer: correctAnswer[0] });
      } else {
        handleAnswer('correct', correctAnswer[0]);
      }
    } else {
      isCorrect = validateAnswer(input, correctAnswer);
      // Skip feedback for quizzes
      if (isTest) {
        handleAnswer(isCorrect ? 'correct' : input, correctAnswer);
      } else if (!isCorrect) {
        setWrongAnswer(correctAnswer);
        setStoredWrongAnswer(correctAnswer);
        setShowAnswerDialog(true);
        setPendingAnswer({ input, answer: correctAnswer });
      } else {
        handleAnswer('correct', correctAnswer);
      }
    }
    
    setInputValue('');
  };

  if (!levelData) {
    return <Typography>Invalid level</Typography>;
  }

  const components = isTest ? testExerciseComponents : exerciseComponents;
  const ExerciseComponent = components[levelData.exerciseType];
  if (!ExerciseComponent) {
    return <Typography>Invalid exercise type</Typography>;
  }

  if (customUIComponents.includes(levelData.exerciseType)) {
    const inputs = levelData.exerciseType === 'VerticalOperationsPractice' && levelData.inputs.generateProblem
      ? levelData.inputs.generateProblem
      : levelData.inputs;
      
    return (
      <>
        <ExerciseComponent
          level={parseInt(level, 10)}
          onAnswer={handleAnswerWithPopup}
          currentProgress={progress.completed}
          inputs={inputs}
          lastProblem={lastProblemRef.current}
          resetKey={resetKey}
        />
        
        <FeedbackDialog
          open={showAnswerDialog}
          onClose={handleCloseDialog}
          exerciseType={levelData.exerciseType}
          wrongAnswer={storedWrongAnswer}
          currentProblem={storedProblem}
          inlineInputComponents={inlineInputComponents}
        />
      </>
    );
  }

  if (!currentProblem) return null;

  const renderInput = () => {
    const sharedProps = {
      value: inputValue,
      onChange: setInputValue,
      onSubmit: handleSubmit,
      showAnswerDialog,
      handleCloseDialog
    };

    if (levelData.exerciseType === 'MultipleChoicePractice' && currentProblem.problem.choices) {
      return (
        <MultipleChoiceInput
          question={currentProblem.problem.question}
          choices={currentProblem.problem.choices}
          {...sharedProps}
        />
      );
    }

    if (inlineInputComponents.includes(levelData.exerciseType)) {
      return (
        <InlineInput
          question={currentProblem.problem.question}
          {...sharedProps}
        />
      );
    }

    return (
      <NumericInput
        question={currentProblem.problem.question}
        {...sharedProps}
      />
    );
  };

  return (
    <>
      {renderInput()}
      <FeedbackDialog
        open={showAnswerDialog}
        onClose={handleCloseDialog}
        exerciseType={levelData.exerciseType}
        currentProblem={currentProblem}
        wrongAnswer={storedWrongAnswer}
        inlineInputComponents={inlineInputComponents}
      />
    </>
  );
}
