import { roll, generateTwoDigitPlusOneDigit, generateTwoDigitMinusOneDigit } from './utils';

export const twoDigitOps = {
  "Add/Subtract Two Digits": {
    levels: [
      { id: "g6y2f9l4", description: "Tens Plus Ones", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: () => ({ 
        num1: roll(1, 9) * 10, 
        num2: roll(1, 9), 
        operation: '+' 
      })}},
      { id: "g6y2f9m8", description: "Add 10", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: () => ({ 
        num1: roll(11, 89), 
        num2: 10, 
        operation: '+' 
      })}},
      { id: "g6y2f9l5", description: "Subtract 10", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: () => ({ 
        num1: roll(11, 89), 
        num2: 10, 
        operation: '-' 
      })}},
      { id: "g6y2f9l9", description: "Add 10s", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: () => ({ 
        num1: roll(11, 49), 
        num2: roll(2,5)*10, 
        operation: '+' 
      })}},
      { id: "g6y2f3l9", description: "Subtract 10s", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: () => ({ 
        num1: roll(49, 99), 
        num2: roll(2,4)*10, 
        operation: '-' 
      })}},
      { id: "v7n3m2k9", description: "Two-Digit Plus One-Digit (No Regrouping)", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: generateTwoDigitPlusOneDigit }},
      { id: "l5k2j8h4", description: "Two-Digit Minus One-Digit (No Regrouping)", exerciseType: "VerticalOperationsPractice", timeLimit: 60, inputs: { generateProblem: generateTwoDigitMinusOneDigit }},
      { 
        id: "v2d9k4m7", 
        description: "Two Digits Plus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num2ones = roll(0, 9);
          const num1ones = roll(0, 9 - num2ones);
          const num2tens = roll(1, 9);
          const num1tens = roll(1, 9 - num2tens);
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "p8f3h6l2", 
        description: "Two Digits Minus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num2ones = roll(0, 8);
          const num1ones = roll(num2ones + 1, 9);
          const num2tens = roll(1, 8);
          const num1tens = roll(num2tens + 1, 9);
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      {
        id: "ee2p1n1",
        description: "Break Down Two-Digit Addition",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 300,
        inputs: () => {
          // First number (e.g., 58)
          const num1tens = roll(2, 7);
          const num1ones = roll(5, 9);
          const number = num1tens * 10 + num1ones;
          
          // Second number to split (e.g., 29)
          const num2tens = roll(1, 8 - num1tens);
          const num2ones = roll(5, 9);
          const secondNumber = num2tens * 10 + num2ones;
          
          return {
            number,
            secondNumber,
            operation: '+',
            split: {
              firstPart: num2tens * 10,  // tens part (e.g., 20)
              secondPart: num2ones       // ones part (e.g., 9)
            },
            hints: ['tens', 'ones']
          };
        }
      },
      { 
        id: "m4x7n9q2", 
        description: "Two Digits Plus Two Digits (With Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 240,
        inputs: () => {
          const num1ones = roll(0, 9);
          const num2ones = roll(9 - num1ones + 1, 9); // Ensures ones sum > 9
          const num1tens = roll(1, 7);
          const num2tens = roll(1, 8 - num1tens); // Prevents sum > 99
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      {
        id: "ee2p1n2",
        description: "Break Down Two-Digit Subtraction",
        exerciseType: "EquivalentExpressionPractice",
        timeLimit: 300,
        inputs: () => {
          // Generate numbers that will require regrouping
          // For example: 82 - 29 = (70 - 20) + (12 - 9)
          
          // First, generate the ones digits to ensure regrouping is needed
          const num2ones = roll(5, 9); // Second number ones (e.g., 9)
          const num1ones = roll(0, num2ones - 1); // First number ones less than second (e.g., 2)
          
          // Then generate tens, ensuring first number has enough to borrow from
          const num2tens = roll(2, 5); // Second number tens (e.g., 2)
          const num1tens = roll(num2tens + 2, 9); // First number tens (e.g., 8)
          
          const number = num1tens * 10 + num1ones;
          const secondNumber = num2tens * 10 + num2ones;
          
          return {
            number,
            secondNumber,
            operation: '-',
            split: {
              firstPart: num2tens * 10,  // tens part (e.g., 20)
              secondPart: num2ones       // ones part (e.g., 9)
            },
            hints: ['tens', 'ones']
          };
        }
      },
      { 
        id: "k6y2w8t5", 
        description: "Two Digits Minus Two Digits (With Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 240,
        inputs: () => {
          const num1ones = roll(0, 8);
          const num2ones = roll(num1ones + 1, 9); // Forces borrowing
          const num1tens = roll(2, 9);
          const num2tens = roll(1, num1tens - 1);
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      }
    ]
  }
};
