import { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import '../../../styles/practice.css';

export default function MoneyPractice({ level, onAnswer, currentProgress, inputs, onKeyDown }) {
  const [answer, setAnswer] = useState('');

  const calculateExpectedAnswer = () => {
    if (inputs.coin) {
      const coinValues = {
        'penny': 1,
        'nickel': 5,
        'dime': 10,
        'quarter': 25,
        'half dollar': 50,
        'dollar': 100
      };
      return coinValues[inputs.coin];
    } else {
      return Object.entries(inputs.coins).reduce((total, [coin, count]) => {
        const coinValues = {
          'penny': 1,
          'nickel': 5,
          'dime': 10,
          'quarter': 25,
          'half dollar': 50,
          'dollar': 100
        };
        return total + (coinValues[coin] * count);
      }, 0);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!answer) return;

    const expectedAnswer = calculateExpectedAnswer();
    const userAnswer = parseInt(answer, 10);
    const isCorrect = userAnswer === expectedAnswer;

    onAnswer(isCorrect ? 'correct' : answer, expectedAnswer.toString());
    setAnswer('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const renderQuestion = () => {
    if (inputs.coin) {
      return `How many cents is a ${inputs.coin}?`;
    } else {
      const coinDisplay = Object.entries(inputs.coins)
        .filter(([_, count]) => count > 0)
        .map(([coin, count]) => `${count} ${coin}${count > 1 ? 's' : ''}`)
        .join(' and ');
      return `How many cents is ${coinDisplay}?`;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Typography className="practice-title">
        {renderQuestion()}
      </Typography>
      <TextField
        variant="outlined"
        value={answer}
        onChange={(e) => setAnswer(e.target.value.replace(/\D/g, ''))}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        autoFocus
        className="number-input"
        size="small"
      />
    </form>
  );
}
