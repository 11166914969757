// Check if two problems are equal
export const areProblemsEqual = (problem1, problem2) => {
  if (!problem1 || !problem2) return false;
  return JSON.stringify(problem1.problem) === JSON.stringify(problem2.problem);
};

// Map grade numbers to content
export const gradeContentMap = {
  "1": require('../../grades/content/grade1Content').default,
  "2": require('../../grades/content/grade2Content').default,
  "3": require('../../grades/content/grade3Content').default,
  "4": require('../../grades/content/grade4Content').default,
  "5": require('../../grades/content/grade5Content').default,
  "6": require('../../grades/content/grade6Content').default,
  "7": require('../../grades/content/grade7Content').default,
  "8": require('../../grades/content/grade8Content').default
};

// Find level data by ID
export const findLevelById = (levelId) => {
  for (const grade in gradeContentMap) {
    const gradeContent = gradeContentMap[grade];
    for (const topic in gradeContent) {
      const level = gradeContent[topic].levels.find(level => level.id === levelId);
      if (level) {
        return {
          grade,
          topic,
          levelData: level
        };
      }
    }
  }
  return null;
};

// Get level data from grade content
export const getLevelData = (levelId) => {
  const levelInfo = findLevelById(levelId);
  return levelInfo?.levelData;
};

// Handle keyboard events for multiple choice
export const handleMultipleChoiceKeyPress = (event, choices, setInputValue) => {
  const num = parseInt(event.key, 10);
  if (num >= 1 && num <= choices.length) {
    const choice = choices[num - 1];
    setInputValue(choice);
  }
};

// Generate new problem for exercise
export const generateNewProblem = (ExerciseComponent, levelData, functionInputComponents) => {
  // Only use function-based inputs for specific components
  const inputs = functionInputComponents.includes(levelData.exerciseType) && typeof levelData.inputs === 'function'
    ? levelData.inputs()
    : levelData.inputs;
  return ExerciseComponent({ inputs });
};
