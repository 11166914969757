import React, { useState, useEffect } from 'react';
import { Typography, TextField, Grid } from '@mui/material';
import '../../../styles/practice.css';

export default function TestCountingPractice({ level, onAnswer, inputs, onKeyDown }) {
  const [answers, setAnswers] = useState(Array(9).fill(''));
  const [currentBox, setCurrentBox] = useState(0); // Index 0 is second box
  
  const startNumber = inputs.start;
  const countBy = inputs.increment;

  // Focus second box on mount and level change
  useEffect(() => {
    setAnswers(Array(9).fill(''));
    setCurrentBox(0);
    setTimeout(() => {
      const secondBox = document.querySelector('input[name="answer-0"]');
      if (secondBox) {
        secondBox.focus();
      }
    }, 0);
  }, [level]);

  const validateAnswer = (index, value) => {
    if (value === '') return false;
    const expectedNumber = startNumber + (countBy * (index + 1));
    return Number(value) === expectedNumber;
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value !== '' && !/^-?\d*$/.test(value)) return;
    if (index !== currentBox) return;

    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleKeyDown = (e, index) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();

    const value = answers[index];
    if (!value) return;

    if (onKeyDown) {
      // Collect all non-empty values up to the current index
      const allValues = answers.slice(0, index + 1).filter(x => x !== '').join(',');
      
      // Create a synthetic event with the combined values
      onKeyDown({
        key: 'Enter',
        target: { value: allValues }
      });
    }

    // If this was the last box and all previous boxes are correct
    if (index === 8) {
      const allCorrect = answers.every((answer, idx) => validateAnswer(idx, answer));
      if (allCorrect) {
        onAnswer('correct', 'correct');
      }
      return;
    }

    // Move to next box if answer was correct
    if (validateAnswer(index, value)) {
      setCurrentBox(index + 1);
      setTimeout(() => {
        const nextBox = document.querySelector(`input[name="answer-${index + 1}"]`);
        if (nextBox) {
          nextBox.focus();
        }
      }, 0);
    }
  };

  const inputProps = {
    style: {
      color: 'white',
      WebkitTextFillColor: 'white',
      opacity: 1
    }
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        {countBy < 0 
          ? `Count backwards by ${Math.abs(countBy)} starting at ${startNumber}`
          : `Count by ${countBy} starting at ${startNumber}`}
      </Typography>
      <br/>

      <Grid container className="grid-container">
        <Grid item>
          <TextField
            disabled
            value={startNumber}
            variant="outlined"
            size="small"
            className="number-input"
            autoComplete="off"
            inputProps={inputProps}
          />
        </Grid>
        {answers.slice(0, 4).map((answer, index) => (
          <Grid item key={index}>
            <TextField
              name={`answer-${index}`}
              value={answer}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={index !== currentBox}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container className="grid-container">
        {answers.slice(4, 9).map((answer, index) => (
          <Grid item key={index + 4}>
            <TextField
              name={`answer-${index + 4}`}
              value={answer}
              onChange={(e) => handleChange(e, index + 4)}
              onKeyDown={(e) => handleKeyDown(e, index + 4)}
              disabled={index + 4 !== currentBox}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
