import { roll } from './utils';

const gcd = (a, b) => {
  while (b) {
    [a, b] = [b, a % b];
  }
  return a;
};

const lcm = (a, b) => (a * b) / gcd(a, b);

const simplifyFraction = (num, den) => {
  const divisor = gcd(Math.abs(num), Math.abs(den));
  return [num / divisor, den / divisor];
};

const addFractions = (n1, d1, n2, d2) => {
  const lcmDen = lcm(d1, d2);
  const newNum = n1 * (lcmDen / d1) + n2 * (lcmDen / d2);
  return simplifyFraction(newNum, lcmDen);
};

const subtractFractions = (n1, d1, n2, d2) => {
  const lcmDen = lcm(d1, d2);
  const newNum = n1 * (lcmDen / d1) - n2 * (lcmDen / d2);
  return simplifyFraction(newNum, lcmDen);
};

// Helper to ensure denominators are different
const rollDifferentDenominators = () => {
  let den1 = roll(2, 10);
  let den2;
  do {
    den2 = roll(2, 10);
  } while (den2 === den1);
  return [den1, den2];
};

export const fractionsaddsub = {
  "Add/Subtract Fractions": {
    levels: [
      {
        id: "g5fadd1",
        description: "Add Fractions",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 240,
        inputs: () => {
          const [den1, den2] = rollDifferentDenominators();
          const num1 = roll(1, den1 - 1);
          const num2 = roll(1, den2 - 1);
          const [ansNum, ansDen] = addFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '+',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fsub1",
        description: "Subtract Fractions",
        exerciseType: "FractionOperationsPractice",
        timeLimit: 240,
        inputs: () => {
          const [den1, den2] = rollDifferentDenominators();
          const num2 = roll(1, den2 - 1);
          // Calculate minimum num1 needed for positive result
          const lcmDen = lcm(den1, den2);
          const minNum1 = Math.ceil((num2 * lcmDen / den2) / (lcmDen / den1));
          const num1 = roll(minNum1, den1 - 1);
          const [ansNum, ansDen] = subtractFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '-',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmix1",
        description: "Add Mixed Numbers",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 240,
        inputs: () => {
          const [den1, den2] = rollDifferentDenominators();
          const whole1 = roll(1, 4);
          const whole2 = roll(1, 4);
          const num1 = whole1 * den1 + roll(1, den1 - 1);
          const num2 = whole2 * den2 + roll(1, den2 - 1);
          const [ansNum, ansDen] = addFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '+',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      },
      {
        id: "g5fmix2",
        description: "Subtract Mixed Numbers",
        exerciseType: "MixedNumberOperationsPractice",
        timeLimit: 240,
        inputs: () => {
          const [den1, den2] = rollDifferentDenominators();
          const whole2 = roll(1, 3);
          const whole1 = roll(whole2 + 1, 5); // Ensure first number is larger
          const num1 = whole1 * den1 + roll(1, den1 - 1);
          const num2 = whole2 * den2 + roll(1, den2 - 1);
          const [ansNum, ansDen] = subtractFractions(num1, den1, num2, den2);
          return {
            fraction1: { numerator: num1, denominator: den1 },
            fraction2: { numerator: num2, denominator: den2 },
            operation: '-',
            answer: { numerator: ansNum, denominator: ansDen }
          };
        }
      }
    ]
  }
};
