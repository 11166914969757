import React from 'react';
import { Box, Typography } from '@mui/material';
import { calculateSteps } from './Generator';
import './styles.css';

const Display = ({ num1, num2, quotient, remainder }) => {
  const num1Str = num1.toString();
  const quotientStr = quotient.toString();
  const steps = calculateSteps(num1, num2, quotient);

  // Calculate padding needed to align quotient with dividend
  const padding = num1Str.length - quotientStr.length;

  return (
    <Box className="feedback-long-division">
      {/* Main structure */}
      <Box sx={{ position: 'relative', paddingLeft: '2rem' }}>
        {/* Quotient row and line */}
        <Box sx={{ position: 'relative', marginBottom: '0.5rem' }}>
          <Box sx={{ display: 'flex', marginLeft: `${32 * padding}px` }}>
            {quotientStr.split('').map((digit, i) => (
              <Box key={`quotient-${i}`} className="number quotient-number">
                {digit}
              </Box>
            ))}
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem' }}>
              <Typography>R</Typography>
              <Box className="number remainder-number">{remainder}</Box>
            </Box>
          </Box>
          <Box sx={{ 
            position: 'absolute',
            bottom: '-4px',
            left: 0,
            right: 0,
            borderBottom: '2px solid currentColor'
          }} />
        </Box>

        {/* Divisor and bracket */}
        <Box sx={{ position: 'absolute', left: 0, top: '2rem', display: 'flex', alignItems: 'center' }}>
          <Typography>{num2}</Typography>
          <Box sx={{ 
            borderLeft: '2px solid currentColor',
            borderTop: '2px solid currentColor',
            width: '0.75rem',
            height: '1.5rem',
            marginLeft: '0.25rem'
          }} />
        </Box>

        {/* Division content */}
        <Box>
          {/* Dividend */}
          <Box sx={{ display: 'flex', marginBottom: '0.5rem' }}>
            {num1Str.split('').map((digit, i) => (
              <Box key={`dividend-${i}`} className="number">
                {digit}
              </Box>
            ))}
          </Box>

          {/* Steps */}
          {steps.map((step, stepIndex) => (
            <Box key={`step-${stepIndex}`} className="step">
              {/* Subtracted value */}
              <Box sx={{ display: 'flex', position: 'relative', marginLeft: `${32 * (step.startPos - step.subtracted.length + 1)}px` }}>
                <Typography sx={{ position: 'absolute', left: '-1rem' }}>−</Typography>
                {step.subtracted.split('').map((digit, i) => (
                  <Box key={`subtracted-${stepIndex}-${i}`} className="number subtracted-number">
                    {digit === ' ' ? '' : digit}
                  </Box>
                ))}
              </Box>
              <Box className="step-line" />
              {/* Step remainder */}
              <Box sx={{ display: 'flex', position: 'relative', marginLeft: `${32 * step.startPos}px` }}>
                {(step.remainder + (step.nextDigit || '')).split('').map((digit, i) => (
                  <Box key={`remainder-${stepIndex}-${i}`} className="number remainder-number">
                    {digit === ' ' ? '' : digit}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Display;
