import { calculateSteps } from './Generator.js';

// Validate user's answers
export const validateAnswers = (answers, problem) => {
  const { num1, num2 } = problem;
  const expectedQuotient = Math.floor(num1 / num2);
  const expectedRemainder = num1 % num2;

  // Get user's quotient digits and final remainder
  const quotientDigits = answers.quotient.filter(x => x !== '');
  const userQuotient = parseInt(quotientDigits.join('') || '0', 10);
  const userFinalRemainder = parseInt(answers.finalRemainder || '0', 10);

  // Get expected steps from the algorithm
  const expectedSteps = calculateSteps(num1, num2, expectedQuotient);

  // Get user's steps
  const userSteps = answers.subtractedValues.map((subtracted, i) => {
    const stepSubtracted = subtracted.filter(x => x !== '').join('');
    const stepRemainder = answers.remainders[i].filter(x => x !== '').join('');
    const stepQuotient = quotientDigits[i] || '';

    // Calculate actual values for this step
    const subtractedValue = parseInt(stepSubtracted || '0', 10);
    const remainderValue = parseInt(stepRemainder || '0', 10);
    const quotientValue = parseInt(stepQuotient || '0', 10);

    return {
      subtracted: stepSubtracted,
      remainder: stepRemainder,
      quotientDigit: stepQuotient,
      // Add computed values for validation
      subtractedValue,
      remainderValue,
      quotientValue
    };
  });

  // Validate each step
  let allStepsCorrect = true;
  for (let i = 0; i < expectedSteps.length; i++) {
    const expected = expectedSteps[i];
    const user = userSteps[i] || { 
      subtracted: '', remainder: '', quotientDigit: '',
      subtractedValue: 0, remainderValue: 0, quotientValue: 0 
    };

    // Check if this step's values are correct
    if (
      // Check if quotient digit is correct
      expected.quotientDigit !== user.quotientDigit ||
      // For subtracted value: if expected is 0, allow blank or "0", otherwise must match
      (expected.subtracted !== '' && 
       user.subtractedValue !== (user.quotientValue * num2)) ||
      // For remainder: if expected is 0, allow blank or "0", otherwise must match
      (expected.remainder !== '' && expected.remainder !== user.remainder)
    ) {
      allStepsCorrect = false;
      break;
    }
  }

  // Final answer must be correct and all steps must be correct
  const isCorrect = userQuotient === expectedQuotient && 
                   userFinalRemainder === expectedRemainder && 
                   allStepsCorrect;

  return {
    isCorrect,
    nonZeroInputs: {
      quotient: quotientDigits,
      subtractedValues: userSteps.map(s => s.subtracted.split('')),
      remainders: userSteps.map(s => s.remainder.split('')),
      finalRemainder: answers.finalRemainder
    },
    steps: userSteps,
    expectedSteps // Include expected steps for feedback
  };
};

// Initialize empty answers
export const createEmptyAnswers = (dividendLength, quotientLength) => {
  const subtracted = Array(quotientLength).fill(null).map(() => 
    Array(dividendLength).fill('')
  );
  const remainders = Array(quotientLength).fill(null).map(() => 
    Array(dividendLength).fill('')
  );
  
  return {
    quotient: Array(dividendLength).fill(''),
    subtractedValues: subtracted,
    remainders: remainders,
    finalRemainder: ''
  };
};
