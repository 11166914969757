export default function OperationsPractice({ inputs }) {
  const { num1, num2, operation, missingPosition, hasRemainder } = inputs;
  
  let answer;
  let question;

  // Convert symbols for display
  const getDisplayOperation = (op) => {
    switch (op) {
      case '*': return 'X';
      case '÷': return '÷';
      case '^': return ''; // No operator shown for exponents
      default: return op;
    }
  };

  const displayOperation = getDisplayOperation(operation);

  // Convert number to superscript using Unicode characters
  const toSuperscript = (num) => {
    const superscriptMap = {
      '0': '⁰',
      '1': '¹',
      '2': '²',
      '3': '³',
      '4': '⁴',
      '5': '⁵',
      '6': '⁶',
      '7': '⁷',
      '8': '⁸',
      '9': '⁹'
    };
    return num.toString().split('').map(digit => superscriptMap[digit]).join('');
  };

  // Format number with Unicode superscript for exponents
  const formatWithSuperscript = (num1, num2, operation) => {
    if (operation === '^') {
      return `${num1}${toSuperscript(num2)}`;
    }
    return `${num1} ${displayOperation} ${num2}`;
  };

  // Helper function to handle decimal arithmetic
  const calculate = (a, b, op) => {
    // Convert to strings
    const aStr = a.toString();
    const bStr = b.toString();
    
    // Get decimal places
    const aDecimals = aStr.includes('.') ? aStr.split('.')[1].length : 0;
    const bDecimals = bStr.includes('.') ? bStr.split('.')[1].length : 0;
    
    // Convert to integers
    const aInt = parseInt(aStr.replace('.', ''));
    const bInt = parseInt(bStr.replace('.', ''));
    
    // Handle different operations
    switch(op) {
      case '+':
      case '-': {
        // Scale factor for addition/subtraction
        const scale = Math.pow(10, Math.max(aDecimals, bDecimals));
        // Calculate
        const result = op === '+' ? 
          (aInt * Math.pow(10, Math.max(0, bDecimals - aDecimals)) + 
           bInt * Math.pow(10, Math.max(0, aDecimals - bDecimals))) :
          (aInt * Math.pow(10, Math.max(0, bDecimals - aDecimals)) - 
           bInt * Math.pow(10, Math.max(0, aDecimals - bDecimals)));
        return result / scale;
      }
      case '*': {
        // For multiplication, decimal places add
        const resultDecimals = aDecimals + bDecimals;
        const result = (aInt * bInt) / Math.pow(10, resultDecimals);
        // Remove trailing zeros by converting to string and back
        return Number(result.toString());
      }
      case '/': {
        // For division, decimal places subtract
        const resultDecimals = Math.max(0, aDecimals - bDecimals);
        const result = (aInt / bInt) / Math.pow(10, resultDecimals);
        // Remove trailing zeros by converting to string and back
        return Number(result.toString());
      }
      default:
        return 0;
    }
  };

  switch (operation) {
    case '+':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${calculate(num1, num2, '+')}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${calculate(num1, num2, '+')}`;
      } else {
        answer = calculate(num1, num2, '+');
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '-':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${calculate(num1, num2, '-')}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${calculate(num1, num2, '-')}`;
      } else {
        answer = calculate(num1, num2, '-');
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '*':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${calculate(num1, num2, '*')}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${calculate(num1, num2, '*')}`;
      } else {
        answer = calculate(num1, num2, '*');
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '÷':
      if (hasRemainder) {
        const quotient = Math.floor(num1 / num2);
        const remainder = num1 % num2;
        if (missingPosition === 'num1') {
          answer = `${num1}`;
          question = `_ ${displayOperation} ${num2} = ${quotient} R${remainder}`;
        } else if (missingPosition === 'num2') {
          answer = `${num2}`;
          question = `${num1} ${displayOperation} _ = ${quotient} R${remainder}`;
        } else {
          answer = `${quotient},${remainder}`;
          question = `${num1} ${displayOperation} ${num2} = _ R_`;
        }
      } else {
        if (missingPosition === 'num1') {
          answer = num1;
          question = `_ ${displayOperation} ${num2} = ${calculate(num1, num2, '/')}`;
        } else if (missingPosition === 'num2') {
          answer = num2;
          question = `${num1} ${displayOperation} _ = ${calculate(num1, num2, '/')}`;
        } else {
          answer = calculate(num1, num2, '/');
          question = `${num1} ${displayOperation} ${num2} = _`;
        }
      }
      break;
    case '^':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_${toSuperscript(num2)} = ${Math.pow(num1, num2)}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1}_ = ${Math.pow(num1, num2)}`;
      } else {
        answer = Math.pow(num1, num2);
        question = `${formatWithSuperscript(num1, num2, operation)} = _`;
      }
      break;
    default:
      console.log('Hit default case with operation:', operation);
      answer = 0;
      question = '0 = 0';
  }

  return {
    problem: {
      question,
      answer: answer.toString()
    },
    validateAnswer: (input) => {
      if (operation === '÷' && hasRemainder && !missingPosition) {
        // For division with remainder, expect format "quotient,remainder"
        const parts = input.split(',');
        if (parts.length !== 2) return false;
        const [quotient, remainder] = parts;
        return quotient.match(/^\d+$/) && remainder.match(/^\d+$/);
      }
      return input.match(/^-?\d*\.?\d+$/);
    }
  };
}
