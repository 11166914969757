import React, { useState } from 'react';
import MultipleChoiceInput from '../components/inputs/MultipleChoiceInput';

const FractionDisplay = ({ numerator, denominator }) => (
  <div style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center', width: '60px' }}>
    <div>{numerator}</div>
    <div style={{ width: '100%', height: '2px', backgroundColor: 'white', margin: '8px 0' }} />
    <div>{denominator}</div>
  </div>
);

const ComparingFractionsMultipleChoice = ({ inputs: inputsFn, onAnswer }) => {
  const [value, setValue] = useState('>');
  const [problem, setProblem] = useState(() => {
    const { fraction1, fraction2 } = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
    const val1 = fraction1.numerator / fraction1.denominator;
    const val2 = fraction2.numerator / fraction2.denominator;
    
    let correctAnswer;
    if (val1 < val2) correctAnswer = '<';
    else if (val1 > val2) correctAnswer = '>';
    else correctAnswer = '=';

    return {
      fraction1,
      fraction2,
      correctAnswer
    };
  });

  const handleSubmit = (_, value) => {
    if (!value) return;

    const isCorrect = value === problem.correctAnswer;
    onAnswer(isCorrect ? 'correct' : value, problem.correctAnswer);

    if (isCorrect && typeof inputsFn === 'function') {
      const { fraction1, fraction2 } = inputsFn();
      const val1 = fraction1.numerator / fraction1.denominator;
      const val2 = fraction2.numerator / fraction2.denominator;
      
      let correctAnswer;
      if (val1 < val2) correctAnswer = '<';
      else if (val1 > val2) correctAnswer = '>';
      else correctAnswer = '=';

      setProblem({
        fraction1,
        fraction2,
        correctAnswer
      });
      setValue('>');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
        <FractionDisplay 
          numerator={problem.fraction1.numerator} 
          denominator={problem.fraction1.denominator} 
        />
        <div style={{ fontSize: '2rem', fontWeight: 'bold', minWidth: '3rem', textAlign: 'center' }}>
          ?
        </div>
        <FractionDisplay 
          numerator={problem.fraction2.numerator} 
          denominator={problem.fraction2.denominator} 
        />
      </div>

      <MultipleChoiceInput
        question=""
        choices={['>', '<', '=']}
        value={value}
        onChange={setValue}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ComparingFractionsMultipleChoice;
