import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import '../../../../styles/practice.css';
import { VerticalOperationDisplay } from '../../practices/VerticalOperationsPractice';
import { LongDivisionDisplay } from '../../practices/LongDivisionPractice';
import { EquivalentExpressionDisplay } from '../../practices/EquivalentExpressionPractice';
import { NamingFractionDisplay } from '../../practices/NamingFractionsPractice';
import { ImproperMixedNumberDisplay } from '../../practices/ImproperMixedNumberPractice';

const EquivalentFractionDisplay = ({ problem, answer }) => {
  const fractionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px'
  };

  const lineStyle = {
    width: '100%',
    height: '2px',
    backgroundColor: 'var(--text-primary)',
    margin: '4px 0'
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'center',
    fontSize: '1.5rem',
    color: 'var(--text-primary)'
  };

  if (problem.requireLCD) {
    return (
      <div style={containerStyle}>
        <div style={fractionStyle}>
          <span>{problem.num1}</span>
          <div style={lineStyle} />
          <span>{problem.den1}</span>
        </div>
        <span>=</span>
        <div style={fractionStyle}>
          <span style={{ color: 'var(--success-color)' }}>{answer.fraction1.numerator}</span>
          <div style={lineStyle} />
          <span style={{ color: 'var(--success-color)' }}>{answer.fraction1.denominator}</span>
        </div>
        <span style={{ margin: '0 1rem' }}>and</span>
        <div style={fractionStyle}>
          <span>{problem.num2}</span>
          <div style={lineStyle} />
          <span>{problem.den2}</span>
        </div>
        <span>=</span>
        <div style={fractionStyle}>
          <span style={{ color: 'var(--success-color)' }}>{answer.fraction2.numerator}</span>
          <div style={lineStyle} />
          <span style={{ color: 'var(--success-color)' }}>{answer.fraction2.denominator}</span>
        </div>
      </div>
    );
  }

  // For non-LCD problems (single fraction equivalence or reduction)
  return (
    <div style={containerStyle}>
      <div style={fractionStyle}>
        <span>{problem.num1}</span>
        <div style={lineStyle} />
        <span>{problem.den1}</span>
      </div>
      <span>=</span>
      <div style={fractionStyle}>
        <span style={{ color: 'var(--success-color)' }}>{answer.numerator}</span>
        <div style={lineStyle} />
        <span style={{ color: 'var(--success-color)' }}>{answer.denominator}</span>
      </div>
    </div>
  );
};

export default function FeedbackDialog({
  open,
  onClose,
  exerciseType,
  currentProblem,
  wrongAnswer,
  inlineInputComponents
}) {
  const buttonRef = useRef(null);
  const [storedAnswer, setStoredAnswer] = useState(wrongAnswer);
  const [storedProblem, setStoredProblem] = useState(currentProblem);

  useEffect(() => {
    if (open) {
      setStoredAnswer(wrongAnswer);
      setStoredProblem(currentProblem);
    }
  }, [open, wrongAnswer, currentProblem]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      buttonRef.current?.click();
    }
  };

  const handleButtonClick = () => {
    onClose();
  };

  const renderContent = () => {
    if (exerciseType === 'VerticalOperationsPractice' && storedProblem?.num1 !== undefined) {
      return (
        <VerticalOperationDisplay
          num1={storedProblem.num1}
          num2={storedProblem.num2}
          operation={storedProblem.operation}
          answer={storedAnswer}
          carries={storedProblem.carries}
          intermediates={storedProblem.intermediates}
        />
      );
    }

    if (exerciseType === 'EquivalentExpressionPractice' && storedProblem?.number !== undefined) {
      return (
        <EquivalentExpressionDisplay
          number={storedProblem.number}
          secondNumber={storedProblem.secondNumber}
          operation={storedProblem.operation}
          answer={storedAnswer}
          steps={storedProblem.steps}
        />
      );
    }

    if (exerciseType === 'NamingFractionsPractice' && storedProblem?.numerator !== undefined) {
      return (
        <NamingFractionDisplay
          numerator={storedProblem.numerator}
          denominator={storedProblem.denominator}
          answer={storedAnswer}
          isMixedNumber={storedProblem.isMixedNumber}
        />
      );
    }

    if (exerciseType === 'EquivalentFractionsPractice' && storedProblem?.num1 !== undefined) {
      return (
        <EquivalentFractionDisplay
          problem={storedProblem}
          answer={storedAnswer}
        />
      );
    }

    if (exerciseType === 'ImproperMixedNumberPractice' && storedProblem?.mode !== undefined) {
      return (
        <ImproperMixedNumberDisplay
          wholeNumber={storedProblem.wholeNumber}
          numerator={storedProblem.numerator}
          denominator={storedProblem.denominator}
          mode={storedProblem.mode}
        />
      );
    }

    if (exerciseType === 'LongDivisionPractice' && storedProblem?.num1 !== undefined) {
      return (
        <LongDivisionDisplay
          num1={storedProblem.num1}
          num2={storedProblem.num2}
          quotient={storedProblem.quotient}
          remainder={storedProblem.remainder}
        />
      );
    }

    if (!storedProblem?.problem && !storedProblem?.num1) {
      return (
        <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '24px', color: 'var(--text-primary)' }}>
          The correct answer is:
          <span style={{ color: 'var(--success-color)', fontWeight: 'bold', display: 'block', marginTop: '16px' }}>
            {storedAnswer}
          </span>
        </Typography>
      );
    }

    if (inlineInputComponents.includes(exerciseType)) {
      const parts = storedProblem.problem.question.split('_');
      return (
        <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'bold', color: 'var(--text-primary)' }}>
          {parts[0]}
          <span style={{ color: 'var(--success-color)' }}>{storedAnswer}</span>
          {parts[1]}
        </Typography>
      );
    }

    if (exerciseType === 'MultipleChoicePractice' && storedProblem.problem.choices) {
      return (
        <>
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'bold', color: 'var(--text-primary)' }}>
            {storedProblem.problem.question}
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {storedProblem.problem.choices.map((choice, index) => (
              <Typography
                key={index}
                style={{
                  textAlign: 'center',
                  padding: '8px',
                  backgroundColor: choice === storedAnswer ? 'var(--success-color)' : 'transparent',
                  color: choice === storedAnswer ? 'var(--text-primary)' : 'var(--text-primary)',
                  borderRadius: '4px'
                }}
              >
                {`${index + 1}. ${choice}`}
              </Typography>
            ))}
          </div>
        </>
      );
    }

    return (
      <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '24px', color: 'var(--text-primary)' }}>
        {storedProblem.problem.question}
        <br />
        <span style={{ color: 'var(--success-color)', fontWeight: 'bold', display: 'block', marginTop: '16px' }}>
          {storedAnswer}
        </span>
      </Typography>
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      onKeyDown={handleKeyDown}
      PaperProps={{
        style: {
          borderRadius: '12px',
          padding: '16px',
          maxWidth: '600px',
          minWidth: '400px',
          backgroundColor: 'var(--bg-secondary)',
          color: 'var(--text-primary)'
        }
      }}
    >
      <DialogContent>
        {renderContent()}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '16px' }}>
        <Button 
          ref={buttonRef}
          onClick={handleButtonClick}
          variant="contained"
          style={{
            backgroundColor: 'var(--primary-color)',
            color: 'var(--text-primary)',
            borderRadius: '20px',
            padding: '8px 24px'
          }}
        >
          Try Again
        </Button>
      </DialogActions>
    </Dialog>
  );
}
