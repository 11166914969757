import React, { useState, useRef } from 'react';

// Helper function to find Greatest Common Divisor
const gcd = (a, b) => {
  a = Math.abs(a);
  b = Math.abs(b);
  while (b) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return a;
};

// Helper function to check if a fraction is in simplest form
const isSimplestForm = (num, den) => {
  return gcd(num, den) === 1;
};

const EquivalentFractionsPractice = React.memo(({ inputs: inputsFn, onAnswer, onKeyDown }) => {
  const [inputValue, setInputValue] = useState('');
  const [numeratorValue, setNumeratorValue] = useState('');
  const [denominatorValue, setDenominatorValue] = useState('');
  const [num2Value, setNum2Value] = useState('');
  const [den2Value, setDen2Value] = useState('');
  const numeratorRef = useRef(null);
  const [problem, setProblem] = useState(() => {
    const inputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
    // Check if this is a common denominator problem
    if (inputs.requireLCD) {
      const { num1, den1, num2, den2, lcd, blank } = inputs;
      return { num1, den1, num2, den2, lcd, blank, requireLCD: true };
    } else {
      const { num1, den1, given, blank } = inputs;
      return { num1, den1, given, blank };
    }
  });

  // Handle number input validation
  const handleInputChange = (e, setter) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const validateAnswers = () => {
    if (problem.requireLCD) {
      // Parse all input values for LCD problems
      const num2Int = parseInt(num2Value || '0', 10);
      const den2Int = parseInt(den2Value || '0', 10);
      const num1Value = parseInt(numeratorValue || '0', 10);
      const den1Value = parseInt(denominatorValue || '0', 10);

      // Check if all inputs are filled
      if (!num1Value || !den1Value || !num2Int || !den2Int) return false;

      // Check if denominators are equal to LCD
      if (den1Value !== problem.lcd || den2Int !== problem.lcd) return false;

      // Check if fractions are equivalent to original fractions
      const originalFraction1 = problem.num1 / problem.den1;
      const originalFraction2 = problem.num2 / problem.den2;
      const newFraction1 = num1Value / den1Value;
      const newFraction2 = num2Int / den2Int;

      return Math.abs(originalFraction1 - newFraction1) < 0.0001 &&
             Math.abs(originalFraction2 - newFraction2) < 0.0001;
    } else {
      if (problem.blank === 'both') {
        // Check if both inputs are filled
        if (!numeratorValue || !denominatorValue) return false;

        const num = parseInt(numeratorValue, 10);
        const den = parseInt(denominatorValue, 10);

        // Check if fraction is in simplest form
        if (!isSimplestForm(num, den)) return false;

        const originalValue = problem.num1 / problem.den1;
        const newValue = num / den;

        return Math.abs(originalValue - newValue) < 0.0001;
      } else {
        // Single input validation
        const value = parseInt(inputValue, 10);
        if (!value) return false;

        if (problem.blank === 'numerator') {
          return value * problem.den1 === problem.num1 * problem.given;
        } else {
          return problem.given * problem.den1 === problem.num1 * value;
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isCorrect = validateAnswers();

    if (problem.requireLCD) {
      // For LCD problems, structure the answer to show both equivalent fractions
      const factor1 = problem.lcd / problem.den1;
      const factor2 = problem.lcd / problem.den2;
      const num1 = parseInt(numeratorValue || '0', 10);
      const den1 = parseInt(denominatorValue || '0', 10);
      const num2 = parseInt(num2Value || '0', 10);
      const den2 = parseInt(den2Value || '0', 10);
      
      const expectedNum1 = problem.num1 * factor1;
      const expectedNum2 = problem.num2 * factor2;
      
      onAnswer(
        isCorrect ? 'correct' : `${num1}/${den1} and ${num2}/${den2}`,
        `${expectedNum1}/${problem.lcd} and ${expectedNum2}/${problem.lcd}`
      );
    } else if (problem.blank === 'both') {
      // For reducing fractions
      const gcdValue = gcd(problem.num1, problem.den1);
      const simplifiedNum = problem.num1 / gcdValue;
      const simplifiedDen = problem.den1 / gcdValue;
      const num = parseInt(numeratorValue || '0', 10);
      const den = parseInt(denominatorValue || '0', 10);
      
      onAnswer(
        isCorrect ? 'correct' : `${num}/${den}`,
        `${simplifiedNum}/${simplifiedDen}`
      );
    } else {
      // For single input problems
      const userAnswer = parseInt(inputValue || '0', 10);
      const expectedValue = problem.blank === 'numerator' 
        ? (problem.num1 * problem.given) / problem.den1
        : (problem.given * problem.den1) / problem.num1;
      onAnswer(isCorrect ? 'correct' : userAnswer, expectedValue);
    }

    if (isCorrect && typeof inputsFn === 'function') {
      const newProblem = inputsFn();
      setProblem(newProblem);
      setInputValue('');
      setNumeratorValue('');
      setDenominatorValue('');
      setNum2Value('');
      setDen2Value('');
      numeratorRef.current?.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const fractionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  const numberStyle = {
    fontSize: '2rem',
    minWidth: '40px',
    textAlign: 'center'
  };

  const lineStyle = {
    borderTop: '2px solid var(--text-primary)',
    width: '100%',
    minWidth: '40px',
    textAlign: 'center',
    paddingTop: '3px'
  };

  return (
    <form className="content-box" onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '2rem', justifyContent: 'center' }}>
        {problem.requireLCD ? (
          <>
            {/* First fraction and its equivalent */}
            <div style={fractionStyle}>
              <div style={numberStyle}>{problem.num1}</div>
              <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.den1}</div>
            </div>
            <div style={{ fontSize: '2rem' }}>=</div>
            <div style={fractionStyle}>
              <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                <input
                  ref={numeratorRef}
                  type="text"
                  value={numeratorValue}
                  onChange={(e) => handleInputChange(e, setNumeratorValue)}
                  onKeyDown={handleKeyDown}
                  style={{ width: '40px', fontSize: '2rem' }}
                  autoFocus
                />
              </div>
              <div style={lineStyle}>
                <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                  <input
                    type="text"
                    value={denominatorValue}
                    onChange={(e) => handleInputChange(e, setDenominatorValue)}
                    onKeyDown={handleKeyDown}
                    style={{ width: '40px', fontSize: '2rem' }}
                  />
                </div>
              </div>
            </div>
                        
            {/* Second fraction and its equivalent */}
            <div style={fractionStyle}>
              <div style={numberStyle}>{problem.num2}</div>
              <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.den2}</div>
            </div>
            <div style={{ fontSize: '2rem' }}>=</div>
            <div style={fractionStyle}>
              <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                <input
                  type="text"
                  value={num2Value}
                  onChange={(e) => handleInputChange(e, setNum2Value)}
                  onKeyDown={handleKeyDown}
                  style={{ width: '40px', fontSize: '2rem' }}
                />
              </div>
              <div style={lineStyle}>
                <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                  <input
                    type="text"
                    value={den2Value}
                    onChange={(e) => handleInputChange(e, setDen2Value)}
                    onKeyDown={handleKeyDown}
                    style={{ width: '40px', fontSize: '2rem' }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={fractionStyle}>
              <div style={numberStyle}>{problem.num1}</div>
              <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.den1}</div>
            </div>
            <div style={{ fontSize: '2rem' }}>=</div>
            <div style={fractionStyle}>
              {problem.blank === 'both' ? (
            <>
              <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                <input
                  ref={numeratorRef}
                  type="text"
                  value={numeratorValue}
                  onChange={(e) => handleInputChange(e, setNumeratorValue)}
                  onKeyDown={handleKeyDown}
                  style={{ width: '40px', fontSize: '2rem' }}
                  autoFocus
                />
              </div>
              <div style={lineStyle}>
                <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                  <input
                    type="text"
                    value={denominatorValue}
                    onChange={(e) => handleInputChange(e, setDenominatorValue)}
                    onKeyDown={handleKeyDown}
                    style={{ width: '40px', fontSize: '2rem' }}
                  />
                </div>
              </div>
            </>
          ) : problem.blank === 'numerator' ? (
            <>
              <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => handleInputChange(e, setInputValue)}
                  onKeyDown={handleKeyDown}
                  style={{ width: '40px', fontSize: '2rem' }}
                  autoFocus
                />
              </div>
              <div style={{ ...lineStyle, fontSize: '2rem' }}>{problem.given}</div>
            </>
          ) : (
            <>
              <div style={numberStyle}>{problem.given}</div>
              <div style={lineStyle}>
                <div className="fraction-input" style={{ border: 'none', padding: 0 }}>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => handleInputChange(e, setInputValue)}
                    onKeyDown={handleKeyDown}
                    style={{ width: '40px', fontSize: '2rem' }}
                    autoFocus
                  />
                </div>
              </div>
            </>
          )}
            </div>
          </>
        )}
      </div>
    </form>
  );
});

export default EquivalentFractionsPractice;
