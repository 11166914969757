import { Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { handleMultipleChoiceKeyPress, handleEnterKey } from '../../utils/inputUtils';

export default function MultipleChoiceInput({
  question,
  choices,
  value,
  onChange,
  onSubmit,
  showAnswerDialog,
  handleCloseDialog
}) {
  const handleKeyDown = (e) => {
    handleMultipleChoiceKeyPress(e, choices, onChange);
    handleEnterKey(e, showAnswerDialog, handleCloseDialog, onSubmit, value);
  };

  const firstRadioRef = useRef(null);

  useEffect(() => {
    if (firstRadioRef.current) {
      firstRadioRef.current.focus();
    }
  }, []);

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(null, value?.trim());
      }} 
      className="content-box"
    >
      <Typography className="practice-title">
        {question}
      </Typography>
      <RadioGroup
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(newValue);
        }}
        className="radio-group"
        onKeyDown={handleKeyDown}
      >
        {choices.map((choice, index) => (
          <FormControlLabel
            key={index}
            value={choice}
            control={<Radio inputRef={index === 0 ? firstRadioRef : null} sx={{ '&.Mui-focused': { outline: 'none' } }} />}
            label={`${index + 1}. ${choice}`}
            className="radio-option"
          />
        ))}
      </RadioGroup>
    </form>
  );
}
